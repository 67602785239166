// components/modals/UserModal.js
import React, {useEffect, useState} from 'react';
import BaseModal from './../baseModal';
import {TextField, Button, Box, Grid} from '@mui/material';
import {inventoriesService, maintenanceMaterialsService, unitMeasuresService} from "../../../../services";
import {Form, Formik} from "formik";
import CustomTextField from "../../../common/CustomFormFields/CustomTextField";
import CustomButton from "../../../common/CustomButton";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import CustomApiDropdown from "../../../common/CustomFormFields/CustomApiDropdown";

const initialFormData = {
  inventoryID: '',
  description: '',
  inventoryTypeID: '',
  quantityUsed: '',
  unitOfMeasureID: '',
  unitPrice: '',
};

const validationSchema = Yup.object({
  // inventoryName: Yup.string().required('Name is required'),
  // description: Yup.string().required('Description is required'),
  // unitOfMeasure: Yup.string().required('Equipment name is required'),
});

export const InventoryModal = ({ open, onClose, maintenanceMaterialsID }) => {
  const isNew = !maintenanceMaterialsID;
  const [formData, setFormData] = useState(initialFormData);
  const [description, setDescription] = useState("")
  const [type, setType] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const {maintenanceProcedureID} = useParams();

  useEffect(() => {
    const fetchUserData = async () => {
      if (maintenanceMaterialsID && open) {
        setIsLoading(true);
        setError(null);
        try {
          const data = await maintenanceMaterialsService.getById(maintenanceMaterialsID)
          setFormData({
            inventoryID: data['Inventory-InventoryID'] || '',
            quantityUsed: data['Qty_Used'] || '',
            unitOfMeasureID: data['Unit_Of_Measure'] || 1,
            unitPrice: data['Unit_Price'] || '',
          });
          handleInventoryChange(maintenanceMaterialsID)

        } catch (err) {
          setError('Failed to fetch user data');
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      } else {
        setFormData(initialFormData);
      }
    };

    fetchUserData();
  }, [maintenanceMaterialsID, open]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (isNew) {
        await postFormData(values);
      } else {
        await putFormData(values);
      }
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const postFormData = async (values) => {
    const body = {
      "MaintenanceID": maintenanceProcedureID,
      "InventoryID": values.inventoryID,
      "Qty_Used": values.quantityUsed,
      "Unit_MeasureID": values.unitOfMeasureID,
      "Unit_Price": values.unitPrice
    };
    await maintenanceMaterialsService.post(body);
    navigate(0)

  };

  const putFormData = async (values) => {
    const body = {
      "InventoryID": values.inventoryID,
      "Qty_Used": values.quantityUsed,
      "Unit_MeasureID": values.unitOfMeasureID,
      "Unit_Price": values.unitPrice
    };
    await maintenanceMaterialsService.put(maintenanceMaterialsID, body);
    navigate(0)
  };

  const handleInventoryChange = async (value) => {
    try {
      const response = await inventoriesService.getById(value);
      const data = response.data
      setDescription(data.Inventory_Description)
      setType(data['Inventory_Type-Inventory_Type'])
    } catch (error) {
      console.error('Error fetching inventory details:', error);
    }
  };

  return (
    <BaseModal open={open} onClose={onClose} title={maintenanceMaterialsID ? 'Edit Product' : 'Add Product'}>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({isSubmitting, isValid, dirty}) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  name="quantityUsed"
                  label="Product Name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="quantityUsed"
                  label="SKU Number"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="unitPrice"
                  label="Production Date"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="quantityUsed"
                  label="Case Size"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="quantityUsed"
                  label="In Stock"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="quantityUsed"
                  label="Sub-Total Price"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="quantityUsed"
                  label="Qty Backordered"
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'end', mt: 2}}>
                  <Box>
                    <CustomButton
                      boldText
                      sx={{
                        mr: 2,
                      }}
                      onClick={onClose}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      boldText
                      type="submit"
                      disabled={!dirty || (isNew && !isValid)}
                    >
                      {isNew ? 'Create' : 'Update'}
                    </CustomButton>
                  </Box>
                </Box>
              </Grid>

            </Grid>
          </Form>
        )}
      </Formik>
    </BaseModal>
  );
};
