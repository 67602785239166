import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment'; // Import moment.js library

import CustomWideLayout from "../../../components/common/Layout/CustomWideLayout";
import CustomTable from "../../../components/common/CustomTable";
import { usePaginatedData, useTableModal } from "../../../hooks";
import { cleaningJobsService, equipmentService, productionLinesService, productionSchedulesService, productionStepsService } from "../../../services";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from 'react';
import { PRODUCTION_RUN_STATUS } from '../../../utils/productionJobData';
import exclamationIcon from "../../../assets/images/exclamationIcon.svg";
import skipIcon from "../../../assets/images/skipIcon.svg";
import checkIcon from "../../../assets/images/checkIcon.svg";
import { ProductionRunModal } from '../../../components/modals/TableModals/Production/productionRun/productionRunModal';
import { toast } from 'react-toastify';

const ProductionJob = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation();

  const { productionLineID } = useParams();
  const { Production_ScheduleID, productionID } = location.state || {};
  const [productionSchedule, setProductionSchedule] = useState(null);
  const [title, setTitle] = useState("Production Line Name");
  const [tableData, setTableData] = useState([]);
  const [productionRunID, setProductionRunID] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const { isOpen, isDelete, selectedId, openModal, closeModal } = useTableModal();


  const {
    data: data,
    loading,
    error,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
    testSearch,
    setData,
  } = usePaginatedData(() => productionStepsService.getByProductionLineId(productionLineID, {
    productionID: productionID,
  }));

  const handleEdit = (row) => {
    if (row["status"] === PRODUCTION_RUN_STATUS.COMPLETE) {
      const productionStepID = row.Production_StepID;
      setProductionRunID(row['Production_Run-Production_RunID']);
      setIsComplete(false);
      openModal(productionStepID);
    } else {
      toast.error("This step has not been completed yet. You can only edit completed steps.");
    }
  };

  const handleComplete = (row) => {
    if (row["status"] === PRODUCTION_RUN_STATUS.COMPLETE) {
      toast.error("This step has already been completed. You cannot complete it again.");
      return;
    } else if (row["status"] === PRODUCTION_RUN_STATUS.CANCELLED) {
      toast.error("This step has been cancelled. You cannot complete a cancelled step.");
      return;
    } else {
      const productionStepID = row.Production_StepID;
      setIsComplete(true);
      setProductionRunID(row['Production_Run-Production_RunID']);
      openModal(productionStepID);
    }
  }

  const handleSkip = (row) => {
    if (row["status"] === PRODUCTION_RUN_STATUS.COMPLETE) {
      toast.error("Cannot skip a completed or past production run. Skipping is only allowed for pending or ongoing production runs.");
      return;
    } else if (row["status"] === PRODUCTION_RUN_STATUS.CANCELLED) {
      toast.error("This step has already been cancelled.");
      return;
    } else {
      const productionStepID = row.Production_StepID;
      setProductionRunID(row['Production_Run-Production_RunID']);
      openModal(productionStepID, true);
    }
  }

  const dataConfig = [
    {
      key: 'Step_Process',
      header: 'Production Step',
      visible: true,
    },
    {
      key: 'Recipe_Name',
      header: 'Recipe Name',
      visible: true,
    },
    {
      key: 'Employee_Name',
      header: 'Employee Name',
      visible: true,
    },
    {
      key: 'Expected_Qty',
      header: 'Expected Qty',
      visible: true,
    },
    {
      key: 'Actual_Qty',
      header: 'Actual Qty',
      visible: true,
    },
    {
      key: 'Time',
      header: 'Time',
      visible: true,
    },
    {
      key: 'Type',
      header: 'Type',
      visible: true,
    },
  ];

  const renderConfig = {
    rowColorConfig: {
      key: 'status',
      colorMap: {
        [PRODUCTION_RUN_STATUS.COMPLETE]: theme.palette.primary.quaternary,     // light gray
        [PRODUCTION_RUN_STATUS.PROCESSING]: theme.palette.senary.secondary,     // light orange
        [PRODUCTION_RUN_STATUS.CANCELLED]: theme.palette.quaternary.secondary,  // light red
        [PRODUCTION_RUN_STATUS.TODO]: theme.palette.septenary.tertiary,  // primary ( blue)
      }
    }
  };

  useEffect(() => {
    if (Production_ScheduleID) {
      productionSchedulesService.getById(Production_ScheduleID)
        .then((response) => {
          setProductionSchedule(response.data.data);
        })
        .catch((error) => {
          console.error('Failed to get production schedule:', error);
        });
    }
  }, [Production_ScheduleID]);

  useEffect(() => {
    if (productionLineID) {
      productionLinesService.getById(productionLineID)
        .then((response) => {
          setTitle(response.data.Production_Line);
        })
        .catch((error) => {
          console.error('Failed to get production line:', error);
        }
        );
    }
  }, [productionLineID]);

  useEffect(() => {
    if (data && productionSchedule) {
      const flattenedData = data.filter((row) => row['Production_Run-ProductionID'] == null || row['Production_Run-ProductionID'] === productionID).map((row) => {
        const date = moment(productionSchedule.Production_Date);
        const today = moment().startOf('day');
        const status = row['Production_Run-Run_Status'] || PRODUCTION_RUN_STATUS.TODO;
        const statusData = date.isBefore(today) ? PRODUCTION_RUN_STATUS.COMPLETE : status;

        return {
          ...row,
          Step_Process: row.Step_Process,
          Recipe_Name: productionSchedule ? productionSchedule.Recipe_Name : "",
          Employee_Name: row['Production_Run-Employee-Full_Name'] || '',
          Expected_Qty: row['Production_Run-Expected_Qty'] || 0,
          Actual_Qty: row['Production_Run-Actual_Qty'] || 0,
          Time: row['Production_Run-Start_Time'] || "",
          Type: row['Equipment-Name'] || "",
          status: statusData,
        };

      });
      setTableData(flattenedData);

    } else {
      setTableData([]);
    }
  }, [data, productionSchedule]);

  return (
    <>
      <CustomWideLayout>
        <CustomTable
          useSpecialRendering={true}
          renderConfig={renderConfig}

          titleText={title}

          data={tableData}
          dataConfig={dataConfig}

          menuItems={[
            {
              icon: exclamationIcon,
              label: 'Edit',
              handler: handleEdit,
            },
            {
              icon: skipIcon,
              label: 'Skip',
              handler: handleSkip,
            },
            {
              icon: checkIcon,
              label: 'Complete',
              handler: handleComplete,
            },
          ]}

          totalItems={totalItems}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={changePage}

          // searchText={"Search Date"}
          showSearch={false}
          // onSearch={handleSearch}
          showPagination={false}

          backButton={true}
          backButtonText={"Back"}
        />

        {isOpen && productionSchedule && <ProductionRunModal
          open={isOpen}
          onClose={closeModal}
          isDelete={isDelete}
          isComplete={isComplete}
          date={moment(productionSchedule['Production-Production_Date']).format("YYYY-MM-DD")}
          productionStepID={selectedId}
          productionID={productionSchedule['Production-ProductionID']}
          productionRunID={productionRunID}
          setData={setData}
        />}
      </CustomWideLayout>
    </>
  );
};

export default ProductionJob;