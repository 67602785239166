import apiClient from "../api";
import { flattenObject } from "./utils";

const ENDPOINTS = {
  BASE: "/productionRecipes",
  SLUG: (slug) => `/productionRecipes/slug/${slug}`,
  ID: (id) => `/productionRecipes/id/${id}`,
  DETAIL: (id) => `/productionRecipes/${id}`,
  DATE: (date) => `/productionRecipes/date/${date}`,
};

export const productionRecipesService = {
  getGroupedAll: async (params = {}) => {
    const response = await apiClient.get(ENDPOINTS.BASE, { params });
    const responseData = response.data.data;
    const flattenedData = responseData.data.map((item) => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: {
        ...response.data.data,
        data: flattenedData,
      },
    };
    return modifiedResponse;
  },

  getAll: async (params = {}) => {
    const response = await apiClient.get(ENDPOINTS.BASE, { params });
    const responseData = response.data.data;
    const flattenedData = responseData.data.map((item) => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: {
        ...response.data.data,
        data: flattenedData,
      },
    };

    return modifiedResponse;
  },

  getBySlug: async (slug) => {
    if (!slug) {
      return Promise.resolve({
        data: [],
        success: true,
        message: "No slug provided, operation ignored",
      });
    }

    const response = await apiClient.get(ENDPOINTS.SLUG(slug));
    const responseData = response.data.data;
    const flattenedObject = responseData.map((item) => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: flattenedObject,
    };

    return modifiedResponse;
  },

  getById: async (id) => {
    if (!id) {
      return Promise.resolve({
        data: [],
        success: true,
        message: "No ID provided, operation ignored",
      });
    }

    const response = await apiClient.get(ENDPOINTS.ID(id));
    const responseData = response.data.data;
    const flattenedObject = flattenObject(responseData);

    const modifiedResponse = {
      ...response.data,
      data: flattenedObject,
    };

    return modifiedResponse;
  },

  getByInventoryName: async (inventory, params = {}) => {
    if (!inventory) {
      return [];
    }
    const searchParams = {
      ...params,
      Inventory_Name: inventory,
    };
    return productionRecipesService.getAll(searchParams);
  },

  post: async (params = {}) => {
    const response = await apiClient.post(ENDPOINTS.BASE, params);

    const responseData = response.data.data;

    if (response.data.success) {
      return flattenObject(responseData);
    } else {
      return false;
    }
  },

  putById: async (id, params = {}) => {
    const response = await apiClient.put(ENDPOINTS.ID(id), params);

    const responseData = response.data.data;

    if (response.data.success) {
      return flattenObject(responseData);
    } else {
      return false;
    }
  },

  putBySlug: async (slug, params = {}) => {
    const response = await apiClient.put(ENDPOINTS.SLUG(slug), params);
    return response.data;
  },

  deleteById: async (id) => {
    const response = await apiClient.delete(ENDPOINTS.ID(id));
    return response.data;
  },

  deleteBySlug: async (slug) => {
    const response = await apiClient.delete(ENDPOINTS.SLUG(slug));
    return response.data;
  },

  getByDate: async (date) => {
    if (!date) {
      return Promise.resolve({
        data: [],
        success: true,
        message: "No date provided, operation ignored",
      });
    }

    const response = await apiClient.get(ENDPOINTS.DATE(date));
    const responseData = response.data.data;
    const flattenedObject = responseData.map((item) => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: flattenedObject,
    };

    return modifiedResponse;
  },
};
