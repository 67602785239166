import apiClient from "../api";
import { flattenObject, normalizeParams } from "./utils";

const ENDPOINTS = {
  BASE: "/productionMaterials",
  DETAIL: (id) => `/productionMaterials/${id}`,
};

export const productionMaterialsService = {
  getAll: async (params = {}) => {
    const response = await apiClient.get(ENDPOINTS.BASE, { params });

    const responseData = response.data.data;
    const flattenedData = responseData.data.map((item) => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: {
        ...response.data.data,
        data: flattenedData,
      },
    };
    return modifiedResponse;
  },

  getById: async (id) => {
    const response = await apiClient.get(ENDPOINTS.DETAIL(id));
    const flattenedData = flattenObject(response.data.data);
    return flattenedData;
  },

  getByProductionStepId: async (productionStepID, params = {}) => {
    if (!productionStepID) {
      return;
    }

    const normalizedParams = normalizeParams({
      ...params,
      ProductionStepID: productionStepID,
    });

    return productionMaterialsService.getAll(normalizedParams);
  },

  getByEquipmentId: async (equipmentId, params = {}) => {
    const searchParams = {
      ...params,
      ProductionStepID: equipmentId,
    };
    return productionMaterialsService.getAll(searchParams);
  },

  delete: async (productionMaterialsID, params = {}) => {
    const response = await apiClient.delete(
      ENDPOINTS.DETAIL(productionMaterialsID)
    );
    return response.data;
  },

  post: async (params = {}) => {
    const response = await apiClient.post(ENDPOINTS.BASE, params);

    const responseData = response.data.data;

    if (response.data.success) {
      return flattenObject(responseData);
    } else {
      return false;
    }
  },

  put: async (productionMaterialsID, params = {}) => {
    const response = await apiClient.put(
      ENDPOINTS.DETAIL(productionMaterialsID),
      params
    );
    const responseData = response.data.data;

    if (response.data.success) {
      return flattenObject(responseData);
    } else {
      return false;
    }
  },
};
