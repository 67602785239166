import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import CustomWideLayout from "../../../components/common/Layout/CustomWideLayout";
import CustomTable from "../../../components/common/CustomTable";
import { usePaginatedData, useTableModal } from "../../../hooks";
import { productionLinesService, productionSchedulesService } from "../../../services";
import { maintenanceProceduresService } from "../../../services/maintenanceProcedures";
import { ProductionPlanningAddScheduleModal, ProductionPlanningModal } from "../../../components/modals/TableModals";
import dayjs from 'dayjs';
import { all } from 'axios';
import { toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';
import DeleteModal from '../../../components/common/CustomModals/deleteModal';

const ProductionPlanning = () => {
  const navigate = useNavigate()
  const [titleOptions, setTitleOptions] = useState([]);
  const [selectedProductionLineID, setSelectedProductionLineID] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  /**
   *  MODAL LOGIC
   */
  const { isOpen, selectedId, openModal, closeModal } = useTableModal();

  useEffect(() => {
    getProductionLines();
  }, []);

  const getProductionLines = async () => {
    try {
      const response = await productionLinesService.getAll({ all: true });
      const data = response.data.data.map((item) => {
        return {
          value: item.Production_LineID,
          label: item.Production_Line,
        };
      });
      setTitleOptions(data);
      if (data.length > 0) {
        setSelectedProductionLineID(data[0].value);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };

  const handleEdit = (row) => {
    if (selectedProductionLineID) {
      navigate(`/production/production-planning/${dayjs(row.Date).format('YYYY-MM-DD')}`, {
        state: {
          Production_LineID: selectedProductionLineID,
        },
      });
    } else {
      toast.error('Please select a production line');
    }
  };

  const handleDelete = async (row) => {
    setSelectedDate(row.Date);
    setIsDeleteOpen(true);
  };
  const handleAdd = (row) => {
    openModal();
    // navigate(`/production/equipment/maintenance-schedules/new`);
  }

  const onChangeProductionLine = async (e) => {
    setSelectedProductionLineID(e.target.value);

    try {
      const response = await productionSchedulesService.getAll({ Production_LineID: e.target.value });
      console.log("🚀🚀🚀 DATA", response);
      setData(response.data.data);
      setTotalItems(response.data.data.length);
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  }

  const closeDeleteModal = () => {
    setIsDeleteOpen(false);
  };

  const deleteProductionPlanning = async () => {
    try {
      setIsDeleteOpen(false);

      await productionSchedulesService.deleteByDate(dayjs(selectedDate).format('YYYY-MM-DD'));

      const response = await productionSchedulesService.getAll({ Production_LineID: selectedProductionLineID });
      console.log("🚀🚀🚀 DATA", response);
      setData(response.data.data);
      setTotalItems(response.data.data.length);

      toast.success('Production Planning is deleted successfully');
    } catch (error) {
      console.error('Failed to delete:', error);
      toast.error('Failed to delete Production Planning');
    }
  }

  const dataConfig = [
    {
      key: 'Date',
      header: 'Date',
      visible: true,
    },
    {
      key: 'Slot_1',
      header: 'Slot 1',
      visible: true,
    },
    {
      key: 'Slot_2',
      header: 'Slot 2',
      visible: true,
    },
    {
      key: 'Slot_3',
      header: 'Slot 3',
      visible: true,
    },
    {
      key: 'Slot_4',
      header: 'Slot 4',
      visible: true,
    },
    {
      key: 'Slot_5',
      header: 'Slot 5',
      visible: true,
    },
    {
      key: 'Slot_6',
      header: 'Slot 6',
      visible: true,
    },
    {
      key: 'Slot_7',
      header: 'Slot 7',
      visible: true,
    },
  ];


  /**
 *  TABLE LOGIC
 */
  const {
    data,
    setData,
    totalItems,
    setTotalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  } = usePaginatedData(productionSchedulesService.getAll, { Production_LineID: selectedProductionLineID });

  const tableData = data.map((row) => {
    return {
      ...row,
      Date: dayjs(row.Date).format('MM/DD/YYYY'),
      Slot_1: row.Slot_1,
      Slot_2: row.Slot_2,
      Slot_3: row.Slot_3,
      Slot_4: row.Slot_4,
      Slot_5: row.Slot_5,
      Slot_6: row.Slot_6,
      Slot_7: row.Slot_7,
    };
  }
  );
  return (
    <>
      <CustomWideLayout>
        <CustomTable
          titleText={"Production Planning"}
          data={tableData}
          dataConfig={dataConfig}

          dropdownOptions={titleOptions}
          dropdownValue={selectedProductionLineID}
          dropdownOnChange={onChangeProductionLine}

          onEdit={handleEdit}
          onDelete={handleDelete}
          onAddText="Add to Schedule"
          onAddClick={handleAdd}

          totalItems={totalItems}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={changePage}

          searchText={"Search Date"}
          onSearch={handleSearch}
        />
        <ProductionPlanningAddScheduleModal
          open={isOpen}
          onClose={closeModal}
          productionLineID={selectedProductionLineID}
        />
        {isDeleteOpen && selectedDate && (<DeleteModal
          open={isDeleteOpen}
          onClose={closeDeleteModal}
          onConfirm={deleteProductionPlanning}
          title="Delete Production Planning"
          message="Are you sure you want to delete this production planning?"
        />)}

      </CustomWideLayout>
    </>
  );
};

export default ProductionPlanning;