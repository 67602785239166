import React, { useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Barcode from "react-barcode";
import CustomButton from "../../common/CustomButton";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const BarcodePreviewModal = ({ name, barcode, open, setOpen }) => {
  const printRef = useRef();
  const [selectedPositions, setSelectedPositions] = useState(
    Array(8).fill(false)
  );

  const handleBoxClick = (index) => {
    const newSelectedPositions = [...selectedPositions];
    newSelectedPositions[index] = !newSelectedPositions[index];
    setSelectedPositions(newSelectedPositions);
  };

  const handleExportPdf = () => {
    const input = printRef.current;
    // Use html2canvas to capture the off-screen content.
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Create a new jsPDF instance for an A4 page.
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Get dimensions of A4 page in mm.
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Add the captured image to the PDF.
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("barcode-labels.pdf");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        ".MuiPaper-root": {
          maxWidth: "max-content",
        },
      }}
    >
      <DialogTitle>Below is a preview of the barcode</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            p: 2,
          }}
        >
          <Typography variant="body2" gutterBottom>
            {name}
          </Typography>
          <Barcode
            value={barcode || "No Barcode"}
            width={2}
            height={50}
            displayValue={true}
            marginBottom={10}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {selectedPositions.map((isSelected, index) => (
            <Box
              key={index}
              onClick={() => handleBoxClick(index)}
              sx={{
                flexBasis: "50%",
                height: 50,
                border: "1px solid",
                borderColor: isSelected ? "primary.main" : "grey.400",
                backgroundColor: isSelected ? "primary.light" : "transparent",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isSelected ? "✔" : ""}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "16px 24px",
          justifyContent: "space-between",
        }}
      >
        <CustomButton sx={{ flexBasis: "50%" }} onClick={() => setOpen(false)}>
          Close
        </CustomButton>
        <CustomButton sx={{ flexBasis: "50%" }} onClick={handleExportPdf}>
          Export PDF
        </CustomButton>
      </DialogActions>

      {/* Off-screen content for PDF export */}
      <div style={{ position: "absolute", top: "-10000px", left: "-10000px" }}>
        <div ref={printRef} style={{ padding: 20 }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(4, auto)",
              gap: "16px",
              boxSizing: "border-box",
            }}
          >
            {selectedPositions.map((isSelected, index) => (
              <Box
                key={index}
                sx={{
                  border: "1px solid #000",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  visibility: isSelected ? "visible" : "hidden",
                }}
              >
                <div className="flex flex-col items-center">
                  <Typography
                    variant="body2"
                    align="center"
                    gutterBottom
                    display={isSelected}
                  >
                    {name}
                  </Typography>
                  <Barcode
                    value={barcode || "No Barcode"}
                    width={2}
                    height={80}
                    displayValue={true}
                  />
                </div>
              </Box>
            ))}
          </Box>
        </div>
      </div>
    </Dialog>
  );
};

export default BarcodePreviewModal;
