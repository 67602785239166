import apiClient from "../api";
import { flattenObject } from "./utils";

const ENDPOINTS = {
  BASE: "/productionLines",
  ID: (id) => `/productionLines/id/${id}`,
  DETAIL: (id) => `/productionLines/${id}`,
};

export const productionLinesService = {
  getAll: async (params = {}) => {
    const response = await apiClient.get(ENDPOINTS.BASE, { params });
    const responseData = response.data.data;
    const flattenedData = responseData.data.map((item) => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: {
        ...response.data.data,
        data: flattenedData,
      },
    };

    return modifiedResponse;
  },

  getById: async (id) => {
    if (!id) {
      return Promise.resolve({
        data: [],
        success: true,
        message: "No ID provided, operation ignored",
      });
    }

    const response = await apiClient.get(ENDPOINTS.ID(id));
    const responseData = response.data.data;
    const flattenedObject = flattenObject(responseData);

    const modifiedResponse = {
      ...response.data,
      data: flattenedObject,
    };

    return modifiedResponse;
  },

  post: async (params = {}) => {
    const response = await apiClient.post(ENDPOINTS.BASE, params);

    const responseData = response.data;
    return responseData;
  },

  putById: async (id, params = {}) => {
    const response = await apiClient.put(ENDPOINTS.ID(id), params);

    const responseData = response.data;
    return responseData;
  },

  deleteById: async (id) => {
    const response = await apiClient.delete(ENDPOINTS.ID(id));
    return response.data;
  },
};
