import React from "react";
import { useField } from "formik";
import {
  TextField,
  MenuItem,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useApiDropdown from "../../../hooks/useApiDropdown";

const CustomApiDropdown = ({
  label,
  disabled = false,
  required = true,
  fetchOptions,
  valueKey = "id",
  labelKey = "name",
  showIdInLabel = false,
  onSelectChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const theme = useTheme();

  const handleChange = (e) => {
    field.onChange(e);
    if (onSelectChange) {
      onSelectChange(e.target.value);
    }
  };

  const { options, isLoading, error } = useApiDropdown(
    fetchOptions,
    valueKey,
    labelKey,
    showIdInLabel
  );

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={4}>
        <Typography>
          {label}
          {required && <span className="text-red-700">*</span>}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          {...field}
          {...props}
          onChange={handleChange}
          select
          fullWidth
          helperText={errorText || error}
          error={!!errorText || !!error}
          disabled={isLoading || disabled}
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "#F3F4F6",
              borderRadius: "0.75rem",
              fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E5E7EB",
              },
              "&:hover fieldset": {
                borderColor: "#D1D5DB",
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
              },
            },
            "& .MuiInputBase-input": {
              color: "#4D5658",
              fontSize: "0.8rem",
            },
            "& .MuiFormHelperText-root": {
              fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
              fontSize: "0.7rem",
            },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            },
          }}
          required={required}
        >
          {isLoading ? (
            <MenuItem disabled>
              <CircularProgress size={20} />
            </MenuItem>
          ) : (
            options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          )}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default CustomApiDropdown;
