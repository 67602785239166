import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import { useFormikContext } from "formik";
import CustomSwitchField from "../CustomFormFields/CustomSwitchField";
import { CustomCheckboxField } from "../CustomFormFields";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getNextDateAndTime } from "../../../utils/getNextDateAndTime";

const SchedulerContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#F4F4F4",
  padding: theme.spacing(0, 2, 2, 2),
  marginTop: 2,
  borderRadius: theme.spacing(1),
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.secondary}`,
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.tertiary.main,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.body1,
  fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  textTransform: "none",
  color: "#000000",
  "&.Mui-selected": {
    color: "#FFFFFF",
    backgroundColor: theme.palette.tertiary.main,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    textAlign: "center",
    width: "30px",
    padding: theme.spacing(1),
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: theme.spacing(1),
    },
  },
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  justifyContent: "flex-start",
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 0),
}));

const RecurringScheduler = ({ name, disabled = false, ...props }) => {
  const { values, setFieldValue } = useFormikContext();

  const schedule = values[name] || {};
  console.log("😍😍😍schedule", schedule.interval || 1);
  const [interval, setInterval] = useState(`${schedule.interval}` || "1");

  useEffect(() => {
    if (`${interval}` !== `${schedule.interval}`) {
      setInterval(`${schedule.interval}`);
    }
  }, [schedule.interval]);

  // A list of valid recurring schedule types
  const scheduleTypes = ["hourly", "daily", "weekly", "monthly", "yearly"];

  // Figure out which tab to show based on scheduleType
  const currentTabIndex = scheduleTypes.indexOf(schedule.scheduleType);

  const handleTabChange = (event, newValue) => {
    setFieldValue(`${name}.scheduleType`, scheduleTypes[newValue]);
    setFieldValue(`${name}.interval`, 1);
    setFieldValue(`${name}.startTime`, dayjs().format("hh:mm A"));
    switch (scheduleTypes[newValue]) {
      case "hourly":
      case "daily":
      case "weekly":
        setFieldValue(`${name}.startDate`, dayjs().format("MM-DD-YYYY"));
        if (schedule.dayOfWeek === null || schedule.dayOfWeek.length === 0) {
          setFieldValue(`${name}.dayOfWeek`, [new Date().getDay()]);
        }
        break;
      case "monthly":
        setFieldValue(`${name}.dayOfMonth`, dayjs().date());
        setFieldValue(
          `${name}.startDate`,
          dayjs().date(dayjs().date()).format("MM-DD-YYYY")
        );
        break;
      case "yearly":
        setFieldValue(`${name}.dayOfMonth`, dayjs().date());
        setFieldValue(`${name}.month`, dayjs().month());
        setFieldValue(
          `${name}.startDate`,
          dayjs().date(dayjs().date()).format("MM-DD-YYYY")
        );
        break;
      default:
        break;
    }
  };

  // If user unchecks "Recurring", switch scheduleType to "one-time"
  const handleRecurringChange = (e) => {
    const isRecurring = e.target.checked;
    setFieldValue(`${name}.recurring`, isRecurring);
    if (!isRecurring) {
      setFieldValue(`${name}.scheduleType`, "one-time");
    } else {
      setFieldValue(`${name}.scheduleType`, "hourly");
    }
  };

  // ----- Utility handlers below -----
  const handleIntervalChange = (e) => {
    const newValue = e.target.value;
    console.log("😍😍😍newValue", newValue);
    setInterval(newValue);
    if (/^\d*$/.test(newValue) && newValue.length <= 2 && newValue !== "" && parseInt(newValue) > 0) {
      setFieldValue(`${name}.interval`, newValue);
    }
  };

  const handleSelectedDaysChange = (day) => {
    const currentDays = schedule.dayOfWeek || [];
    const updatedDays = currentDays.includes(day)
      ? currentDays.filter((d) => d !== day)
      : [...currentDays, day];
    setFieldValue(`${name}.dayOfWeek`, updatedDays);
  };

  const handleDayOfMonthChange = (value) => {
    if (value === "") {
      setFieldValue(`${name}.dayOfMonth`, "");
      setFieldValue(`${name}.startDate`, null);
      return;
    }

    setFieldValue(`${name}.dayOfMonth`, `${String(value.$D).padStart(2, 0)}`);
    setFieldValue(
      `${name}.startDate`,
      `${String(value.$M + 1).padStart(2, 0)}-${String(value.$D).padStart(
        2,
        0
      )}-${value.$y}`
    );
  };

  const handleMonthAndDayOfMonthChange = (value) => {
    setFieldValue(`${name}.month`, `${String(value.$M).padStart(2, 0)}`);
    setFieldValue(`${name}.dayOfMonth`, `${String(value.$D).padStart(2, 0)}`);

    setFieldValue(
      `${name}.startDate`,
      `${String(value.$M + 1).padStart(2, 0)}-${String(value.$D).padStart(
        2,
        0
      )}-${value.$y}`
    );
  };

  const handlestartDateChange = (value) => {
    setFieldValue(
      `${name}.startDate`,
      `${String(value.$M + 1).padStart(2, 0)}-${String(value.$D).padStart(
        2,
        0
      )}-${value.$y}`
    );
  };

  const handleStartTimeChange = (value) => {
    setFieldValue(
      `${name}.startTime`,
      `${String(value.$H > 11 ? value.$H - 12 : value.$H).padStart(
        2,
        0
      )}:${String(value.$m).padStart(2, 0)} ${value.$H > 11 ? "PM" : "AM"}`
    );
  };

  const getContent = () => {
    switch (schedule.scheduleType) {
      case "hourly":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextWrapper className="whitespace-nowrap">
              Every
              <StyledTextField
                value={interval}
                onChange={handleIntervalChange}
                variant="outlined"
                size="small"
                disabled={disabled}
              />
              hour(s) starting on
              <DesktopDatePicker
                onChange={handlestartDateChange}
                value={dayjs(schedule.startDate, "MM-DD-YYYY")}
                sx={{
                  maxWidth: "180px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiInputBase-input": {
                    padding: "8px",
                  },
                  ".MuiFormLabel-root": {
                    top: "-6px",
                  },
                  ".MuiButtonBase-root": {
                    padding: "4px",
                  },
                  ".MuiSvgIcon-root": {
                    width: "0.75em",
                    height: "0.75em",
                  },
                }}
                disabled={disabled}
              />
              at
              <TimePicker
                onChange={handleStartTimeChange}
                value={dayjs(schedule.startTime, "hh:mm A")}
                sx={{
                  maxWidth: "180px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiInputBase-input": {
                    padding: "8px",
                  },
                  ".MuiFormLabel-root": {
                    top: "-6px",
                  },
                  ".MuiButtonBase-root": {
                    padding: "4px",
                  },
                  ".MuiSvgIcon-root": {
                    width: "0.75em",
                    height: "0.75em",
                  },
                }}
                disabled={disabled}
              />
            </TextWrapper>
          </LocalizationProvider>
        );
      case "daily":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextWrapper className="whitespace-nowrap">
              Every
              <StyledTextField
                value={interval}
                onChange={handleIntervalChange}
                variant="outlined"
                size="small"
                disabled={disabled}
              />
              day(s) starting on
              <DesktopDatePicker
                onChange={handlestartDateChange}
                value={dayjs(schedule.startDate, "MM-DD-YYYY")}
                sx={{
                  maxWidth: "180px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiInputBase-input": {
                    padding: "8px",
                  },
                  ".MuiFormLabel-root": {
                    top: "-6px",
                  },
                  ".MuiButtonBase-root": {
                    padding: "4px",
                  },
                  ".MuiSvgIcon-root": {
                    width: "0.75em",
                    height: "0.75em",
                  },
                }}
                disabled={disabled}
              />
              at
              <TimePicker
                onChange={handleStartTimeChange}
                value={dayjs(schedule.startTime, "hh:mm A")}
                sx={{
                  maxWidth: "180px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiInputBase-input": {
                    padding: "8px",
                  },
                  ".MuiFormLabel-root": {
                    top: "-6px",
                  },
                  ".MuiButtonBase-root": {
                    padding: "4px",
                  },
                  ".MuiSvgIcon-root": {
                    width: "0.75em",
                    height: "0.75em",
                  },
                }}
                disabled={disabled}
              />
            </TextWrapper>
          </LocalizationProvider>
        );
      case "weekly":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
              <TextWrapper className="whitespace-nowrap">
                Every
                <StyledTextField
                  value={interval}
                  onChange={handleIntervalChange}
                  variant="outlined"
                  size="small"
                  disabled={disabled}
                />
                week(s) starting on
                <DesktopDatePicker
                  onChange={handlestartDateChange}
                  value={dayjs(schedule.startDate, "MM-DD-YYYY")}
                  sx={{
                    maxWidth: "180px",
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    ".MuiInputBase-input": {
                      padding: "8px",
                    },
                    ".MuiFormLabel-root": {
                      top: "-6px",
                    },
                    ".MuiButtonBase-root": {
                      padding: "4px",
                    },
                    ".MuiSvgIcon-root": {
                      width: "0.75em",
                      height: "0.75em",
                    },
                  }}
                  disabled={disabled}
                />
                at
                <TimePicker
                  onChange={handleStartTimeChange}
                  value={dayjs(schedule.startTime, "hh:mm A")}
                  sx={{
                    maxWidth: "180px",
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    ".MuiInputBase-input": {
                      padding: "8px",
                    },
                    ".MuiFormLabel-root": {
                      top: "-6px",
                    },
                    ".MuiButtonBase-root": {
                      padding: "4px",
                    },
                    ".MuiSvgIcon-root": {
                      width: "0.75em",
                      height: "0.75em",
                    },
                  }}
                  disabled={disabled}
                />
              </TextWrapper>
              <Box sx={{ mt: 2 }}>
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                  (day, index) => (
                    <FormControlLabel
                      key={day}
                      control={
                        <Checkbox
                          checked={schedule.dayOfWeek?.includes(index + 1)}
                          onChange={() => handleSelectedDaysChange(index + 1)}
                          disabled={disabled}
                        />
                      }
                      label={day}
                    />
                  )
                )}
              </Box>
            </Box>
          </LocalizationProvider>
        );
      case "monthly":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextWrapper className="whitespace-nowrap">
              Every
              <StyledTextField
                value={interval}
                onChange={handleIntervalChange}
                variant="outlined"
                size="small"
                disabled={disabled}
              />
              month(s) starting on
              <DesktopDatePicker
                onChange={handleDayOfMonthChange}
                value={dayjs(schedule.startDate, "MM-DD-YYYY")}
                sx={{
                  maxWidth: "180px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiInputBase-input": {
                    padding: "8px",
                  },
                  ".MuiFormLabel-root": {
                    top: "-6px",
                  },
                  ".MuiButtonBase-root": {
                    padding: "4px",
                  },
                  ".MuiSvgIcon-root": {
                    width: "0.75em",
                    height: "0.75em",
                  },
                }}
                disabled={disabled}
              />
              at
              <TimePicker
                onChange={handleStartTimeChange}
                value={dayjs(schedule.startTime, "hh:mm A")}
                sx={{
                  maxWidth: "180px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiInputBase-input": {
                    padding: "8px",
                  },
                  ".MuiFormLabel-root": {
                    top: "-6px",
                  },
                  ".MuiButtonBase-root": {
                    padding: "4px",
                  },
                  ".MuiSvgIcon-root": {
                    width: "0.75em",
                    height: "0.75em",
                  },
                }}
                disabled={disabled}
              />
            </TextWrapper>
          </LocalizationProvider>
        );
      case "yearly":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextWrapper className="whitespace-nowrap">
              Every
              <StyledTextField
                value={interval}
                onChange={handleIntervalChange}
                variant="outlined"
                size="small"
                disabled={disabled}
              />
              year(s) starting on

              <DesktopDatePicker
                onChange={handleMonthAndDayOfMonthChange}
                value={dayjs(schedule.startDate, "MM-DD-YYYY")}
                sx={{
                  maxWidth: "180px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiInputBase-input": {
                    padding: "8px",
                  },
                  ".MuiFormLabel-root": {
                    top: "-6px",
                  },
                  ".MuiButtonBase-root": {
                    padding: "4px",
                  },
                  ".MuiSvgIcon-root": {
                    width: "0.75em",
                    height: "0.75em",
                  },
                }}
                disabled={disabled}
              />

              at
              <TimePicker
                onChange={handleStartTimeChange}
                value={dayjs(schedule.startTime, "hh:mm A")}
                sx={{
                  maxWidth: "180px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiInputBase-input": {
                    padding: "8px",
                  },
                  ".MuiFormLabel-root": {
                    top: "-6px",
                  },
                  ".MuiButtonBase-root": {
                    padding: "4px",
                  },
                  ".MuiSvgIcon-root": {
                    width: "0.75em",
                    height: "0.75em",
                  },
                }}
                disabled={disabled}
              />
            </TextWrapper>
          </LocalizationProvider>
        );
      default:
        return null;
    }
  };

  // ----- Description text for each recurring type -----
  const getDescription = () => {
    switch (schedule.scheduleType) {
      case "hourly":
        return `Every ${schedule.interval} hour(s) starting on ${dayjs(
          schedule.startDate,
          "MM-DD-YYYY"
        ).format("MM/DD/YYYY")} at ${schedule.startTime}`;
      case "daily":
        return `Every ${schedule.interval} day(s) starting on ${dayjs(
          schedule.startDate,
          "MM-DD-YYYY"
        ).format("MM/DD/YYYY")} at ${schedule.startTime}`;
      case "weekly": {
        return `Every ${schedule.interval} week(s) starting on ${dayjs(
          schedule.startDate,
          "MM-DD-YYYY"
        ).format("MM/DD/YYYY")} at ${schedule.startTime}`;
      }
      case "monthly":
        // return `Every ${schedule.interval} month(s) on day ${schedule.dayOfMonth} at ${schedule.startTime}`;
        return `Every ${schedule.interval} month(s) starting on ${dayjs(
          schedule.startDate,
          "MM-DD-YYYY"
        ).format("MM/DD/YYYY")} at ${schedule.startTime}`;
      case "yearly": {
        // const monthName = [
        //   "January",
        //   "February",
        //   "March",
        //   "April",
        //   "May",
        //   "June",
        //   "July",
        //   "August",
        //   "September",
        //   "October",
        //   "November",
        //   "December",
        // ][schedule.month];
        // return `Runs yearly on day ${schedule.dayOfMonth} of ${monthName} at ${schedule.startTime}`;
        return `Every ${schedule.interval} year(s) starting on ${dayjs(
          schedule.startDate,
          "MM-DD-YYYY"
        ).format("MM/DD/YYYY")} at ${schedule.startTime}`;
      }
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <CustomSwitchField
          name={`${name}.notify`}
          label="Setup selection to notify automatically"
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <CustomCheckboxField
          name={`${name}.recurring`}
          label="Recurring"
          onChange={handleRecurringChange}
          disabled={disabled}
        />
      </Grid>

      {/* If recurring is TRUE, show the recurring UI */}
      {schedule.recurring ? (
        <SchedulerContainer>
          <StyledTabs
            value={currentTabIndex >= 0 ? currentTabIndex : 0}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <StyledTab label="Hourly" disabled={disabled} />
            <StyledTab label="Daily" disabled={disabled} />
            <StyledTab label="Weekly" disabled={disabled} />
            <StyledTab label="Monthly" disabled={disabled} />
            <StyledTab label="Yearly" disabled={disabled} />
          </StyledTabs>
          <ContentContainer>
            {getContent()}
            <Typography sx={{ mt: 2, color: "text.secondary" }}>
              {getDescription()}.{" "}
              <span className="text-blue-500">
                Next runtime is {getNextDateAndTime(values.schedule).date} at{" "}
                {getNextDateAndTime(values.schedule).time}.
              </span>
            </Typography>
          </ContentContainer>
        </SchedulerContainer>
      ) : (
        // If recurring is FALSE, show one-time date & time
        <Box
          sx={{
            mt: 2,
            p: 2,
            backgroundColor: "#F4F4F4",
            borderRadius: 3,
          }}
        >
          <Typography variant="subtitle2" gutterBottom>
            One-Time Schedule
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item>
                <DesktopDatePicker
                  label="Date"
                  onChange={handlestartDateChange}
                  value={dayjs(schedule.startDate, "MM-DD-YYYY")}
                  sx={{
                    maxWidth: "180px",
                    ".MuiInputBase-input": {
                      padding: "8px",
                    },
                    ".MuiButtonBase-root": {
                      padding: "4px",
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <TimePicker
                  label="Time"
                  onChange={handleStartTimeChange}
                  value={dayjs(schedule.startTime, "hh:mm A")}
                  sx={{
                    maxWidth: "180px",
                    ".MuiInputBase-input": {
                      padding: "8px",
                    },
                    ".MuiButtonBase-root": {
                      padding: "4px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      )}
    </Fragment>
  );
};

export default RecurringScheduler;
