import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import ContentCard from "../../../../components/common/ContentCard/ContentCard";
import CustomButton from "../../../../components/common/CustomButton";
import CustomWideLayout from "../../../../components/common/Layout/CustomWideLayout";
import CustomTextField from "../../../../components/common/CustomFormFields/CustomTextField";
import CustomTable from "../../../../components/common/CustomTable";
import CustomRecurringScheduler from "../../../../components/common/CustomRecurringScheduler";
import { usePaginatedData, useTableModal } from "../../../../hooks";
import {
  equipmentService,
  maintenanceMaterialsService,
} from "../../../../services";
import { maintenanceProceduresService } from "../../../../services/maintenanceProcedures";
import { MaintenanceMaterialsModal } from "../../../../components/modals/TableModals";
import {
  CancelModal,
  ContinueModal,
} from "../../../../components/common/CustomModals";
import CustomApiDropdown from "../../../../components/common/CustomFormFields/CustomApiDropdown";
import { employeesService } from "../../../../services/employees";
import DeleteModal from "../../../../components/common/CustomModals/deleteModal";

const initialValues = {
  procedure: "",
  description: "",
  equipmentID: "",
  employeeID: "",
  schedule: {
    scheduleType: "hourly",
    interval: 1,
    dayOfWeek: [dayjs().day()],
    dayOfMonth: dayjs().date(),
    month: dayjs().month(),
    recurring: true,
    notify: true,
    startDate: dayjs().format("MM-DD-YYYY"),
    startTime: dayjs().format("hh:mm A"),
  },
};

const validationSchema = Yup.object({
  procedure: Yup.string().required("Procedure is required"),
  description: Yup.string(),
  equipmentID: Yup.string().required("Equipment name is required"),
  employeeID: Yup.string().required("Employee is required"),
});

const MainForm = ({
  isNew,
  isDeleteMode,
  equipmentID,
  showDeleteModal,
  setShowDeleteModal,
  showContinueModal,
  setShowContinueModal,
  showCancelModal,
  handleCancelModalClose,
  handleCancelModalConfirm,
  handleContinueModalClose,
  handleContinueModalConfirm,
  handleDeleteProcedure,
  handleCancel,
  formikSubmitRef,
  formikResetRef,
  submitForm,
  resetForm,
  setIsFormDirty,
  isValid,
  isSubmitting,
  dirty,
}) => {
  const theme = useTheme();

  // Save Formik functions in refs for later use
  formikSubmitRef.current = submitForm;
  formikResetRef.current = resetForm;

  useEffect(() => {
    setIsFormDirty(dirty);
  }, [dirty, setIsFormDirty]);

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField
            name="procedure"
            label="Procedure"
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="description"
            label="Description"
            disabled={isDeleteMode}
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomApiDropdown
            name={"equipmentID"}
            label={"Equipment Name"}
            fetchOptions={equipmentService.getAll}
            valueKey={"EquipmentID"}
            labelKey={"Name"}
            showIdInLabel={false}
            disabled={isDeleteMode || !!equipmentID || !isNew}
            required={!isDeleteMode && !equipmentID && isNew}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomApiDropdown
            name={"employeeID"}
            label={"Employee Name"}
            fetchOptions={employeesService.getAll}
            valueKey={"EmployeeID"}
            labelKey={"Full_Name"}
            showIdInLabel={false}
            disabled={isDeleteMode}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomRecurringScheduler name="schedule" disabled={isDeleteMode} />
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <CustomButton
              boldText
              sx={{ mr: 2 }}
              onClick={() => handleCancel(dirty)}
            >
              {dirty ? "Cancel" : "Go Back"}
            </CustomButton>

            {isDeleteMode ? (
              <CustomButton
                boldText
                onClick={() => setShowDeleteModal(true)}
                sx={{
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.error.contrastText,
                }}
              >
                Delete
              </CustomButton>
            ) : (
              <CustomButton
                boldText
                onClick={() => setShowContinueModal(true)}
                disabled={isSubmitting || !dirty || (isNew && !isValid)}
              >
                {isNew ? "Create" : "Update"}
              </CustomButton>
            )}
          </Box>

          <CancelModal
            open={showCancelModal}
            onClose={handleCancelModalClose}
            onConfirm={handleCancelModalConfirm}
          />

          <ContinueModal
            open={showContinueModal}
            onClose={handleContinueModalClose}
            onConfirm={() => handleContinueModalConfirm(submitForm)}
          />

          {isDeleteMode && showDeleteModal && (
            <DeleteModal
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onConfirm={handleDeleteProcedure}
            />
          )}
        </Grid>
      </Grid>
    </Form>
  );
};

const MaintenanceProcedure = () => {
  const { maintenanceJobID, maintenanceProcedureID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { equipmentID, isDeleteMode } = location.state || {};

  const isNew = !maintenanceProcedureID;

  const pageTitle = isNew
    ? "Create New Maintenance Procedure"
    : isDeleteMode
      ? "Delete Maintenance Procedure"
      : "Edit Maintenance Procedure";

  const [formData, setFormData] = useState(initialValues);

  const { isOpen, isDelete, selectedId, openModal, closeModal } =
    useTableModal();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // ─── NEW STATE & REFS FOR UNSAVED CHANGES ─────────────────────────────
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const formikSubmitRef = useRef(null);
  const formikResetRef = useRef(null);
  // ────────────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (isNew) {
      if (equipmentID)
        setFormData({ ...initialValues, equipmentID: equipmentID });
      return;
    }

    const fetchData = async () => {
      try {
        const response = await maintenanceProceduresService.getById(
          maintenanceProcedureID
        );
        const data = response.data.data;
        setFormData({
          id: data.MaintenanceID || "",
          procedure: data.Procedure || "",
          description: data.Description || "",
          equipmentID: data["Maintenance_Job-EquipmentID"] || 1,
          employeeID: data["Employees-EmployeeID"] || 1,
          schedule: {
            id: data.ScheduleID || "",
            scheduleType: data["Schedule_Table-ScheduleType"] || "hourly",
            interval: data["Schedule_Table-RepeatInterval"] || 0,
            dayOfWeek:
              typeof data["Schedule_Table-DayOfWeek"] === "string"
                ? JSON.parse(data["Schedule_Table-DayOfWeek"])
                : data["Schedule_Table-DayOfWeek"],
            dayOfMonth: data["Schedule_Table-DayOfMonth"] || 1,
            month: data["Schedule_Table-Month"] || 1,
            notify: data["Schedule_Table-Notify"],
            recurring: data["Schedule_Table-ScheduleType"] !== "one-time",
            startDate: data["Schedule_Table-StartDate"],
            startTime: data["Schedule_Table-StartTime"],
          },
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
        toast.error("Failed to fetch data");
      }
    };

    fetchData();
  }, [equipmentID, isNew, maintenanceProcedureID]);

  const handlePost = async (values) => {
    try {
      const body = {
        Maintenance_JobID: maintenanceJobID,

        Procedure: values.procedure,
        Description: values.description,
        EmployeeID: values.employeeID,
        EquipmentID: values.equipmentID,

        ScheduleType: values.schedule.scheduleType,
        RepeatInterval: values.schedule.interval,
        TimeOfDay: values.schedule.timeOfDay,
        DayOfWeek: values.schedule.dayOfWeek,
        DayOfMonth: values.schedule.dayOfMonth,
        Month: values.schedule.month,

        Notify: values.schedule.notify,
        Recurring: values.schedule.recurring,

        StartDate: values.schedule.startDate,
        StartTime: values.schedule.startTime,
      };
      const response = await maintenanceProceduresService.post(body);
      const newId = response.data.maintenanceProcedure.MaintenanceID;
      navigate(
        `/production/equipment/maintenance-schedules/${maintenanceJobID}/maintenance-procedure/${newId}`, { replace: true }
      );
      toast.success("Maintenance procedure created successfully!");
    } catch (error) {
      console.error("Failed to create:", error);
      toast.error("Failed to create maintenance procedure.");
    }
  };

  const handlePut = async (values) => {
    try {
      const body = {
        Procedure: values.procedure,
        Description: values.description,
        EmployeeID: values.employeeID,
        EquipmentID: values.equipmentID,

        ScheduleID: values.schedule.id,
        ScheduleType: values.schedule.scheduleType,
        RepeatInterval: values.schedule.interval,
        TimeOfDay: values.schedule.timeOfDay,
        DayOfWeek: values.schedule.dayOfWeek,
        DayOfMonth: values.schedule.dayOfMonth,
        Month: values.schedule.month,

        Notify: values.schedule.notify,
        Recurring: values.schedule.recurring,

        StartDate: values.schedule.startDate,
        StartTime: values.schedule.startTime,
      };
      const updatedData = await maintenanceProceduresService.put(
        maintenanceProcedureID,
        body
      );
      setFormData({
        id: updatedData["data-MaintenanceID"] || "",
        procedure: updatedData["data-Procedure"] || "",
        description: updatedData["data-Description"] || "",
        equipmentID: updatedData["data-Maintenance_Job-EquipmentID"] || 1,
        employeeID: updatedData["data-Employees-EmployeeID"] || 1,
        schedule: {
          id: updatedData["data-ScheduleID"] || "",
          scheduleType:
            updatedData["data-Schedule_Table-ScheduleType"] || "hourly",
          interval: updatedData["data-Schedule_Table-RepeatInterval"] || 0,
          dayOfWeek:
            typeof updatedData["data-Schedule_Table-DayOfWeek"] === "string"
              ? JSON.parse(updatedData["data-Schedule_Table-DayOfWeek"])
              : updatedData["data-Schedule_Table-DayOfWeek"],
          dayOfMonth: updatedData["data-Schedule_Table-DayOfMonth"] || 1,
          month: updatedData["data-Schedule_Table-Month"] || 1,
          notify: updatedData["data-Schedule_Table-Notify"],
          recurring:
            updatedData["data-Schedule_Table-ScheduleType"] !== "one-time",
          startDate: updatedData["data-Schedule_Table-StartDate"],
          startTime: updatedData["data-Schedule_Table-StartTime"],
        },
      });
      toast.success("Maintenance procedure updated successfully!");
    } catch (error) {
      console.error("Failed to create:", error);
      toast.error("Failed to update maintenance procedure.");
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (isNew) {
        await handlePost(values);
      } else {
        await handlePut(values);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form.");
    }
  };

  const handleCancel = (dirty) => {
    if (dirty) {
      setShowCancelModal(true);
    } else {
      navigate(-1);
    }
  };

  const handleDeleteProcedure = async (row) => {
    try {
      await maintenanceProceduresService.delete(maintenanceProcedureID);
      navigate(-1);
      toast.success("Maintenance procedure deleted successfully!");
    } catch (error) {
      console.error("Failed to delete maintenance procedure:", error);
      toast.error("Failed to delete maintenance procedure.");
    }
  };

  // ─── Wrap navigation actions to check for unsaved changes ────────────────
  const handleNavigationAction = (action) => {
    if (isFormDirty) {
      setPendingNavigation(() => action);
      setShowUnsavedModal(true);
    } else {
      action();
    }
  };
  // ────────────────────────────────────────────────────────────────────────

  // ─── Updated Maintenance Material Actions ──────────────────────────────────
  const handleAddMaintenanceMaterial = () => {
    handleNavigationAction(() => {
      handleAdd();
    });
  };

  const handleEditMaintenanceMaterial = (row) => {
    handleNavigationAction(() => {
      handleEdit(row);
    });
  };

  const handleDeleteMaintenanceMaterial = (row) => {
    handleNavigationAction(() => {
      handleDelete(row);
    });
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
  };

  const handleCancelModalConfirm = () => {
    setShowCancelModal(false);
    navigate(0);
  };

  const handleContinueModalClose = () => {
    setShowContinueModal(false);
  };

  const handleContinueModalConfirm = (submitForm) => {
    setShowContinueModal(false);
    submitForm();
  };

  const {
    data,
    setData,
    totalItems,
    setTotalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  } = usePaginatedData(() =>
    maintenanceMaterialsService.getByMaintenanceProcedureId(
      maintenanceProcedureID
    )
  );

  // ─── Functions for Unsaved Changes Modal Actions ───────────────────────
  const handleSaveAndNavigate = async () => {
    if (formikSubmitRef.current) {
      // Calling submitForm returns a promise
      await formikSubmitRef.current();
      if (pendingNavigation) {
        pendingNavigation();
        setPendingNavigation(null);
      }
      setShowUnsavedModal(false);
    }
  };

  const handleDiscardAndNavigate = () => {
    if (formikResetRef.current) {
      // Reset the form to the saved formData
      formikResetRef.current(formData);
    }
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
    setShowUnsavedModal(false);
  };

  const cancelNavigation = () => {
    setShowUnsavedModal(false);
    setPendingNavigation(null);
  };
  // ────────────────────────────────────────────────────────────────────────

  const handleEdit = (row) => {
    const maintenanceMaterialsID = row.Maintenance_MaterialsID;
    openModal(maintenanceMaterialsID);
  };

  const handleDelete = (row) => {
    const maintenanceMaterialsID = row.Maintenance_MaterialsID;
    openModal(maintenanceMaterialsID, true);
  };

  const handleAdd = () => {
    openModal();
  };

  const dataConfig = [
    {
      key: "Inventory-Inventory_Name",
      header: "Inventory Name",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Description",
      header: "Inventory Description",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Type-Inventory_Type",
      header: "Inventory Type",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Type-Storage_Method",
      header: "Storage Method",
      visible: true,
    },
    {
      key: "Unit_Measure",
      header: "Unit of Measure",
      visible: true,
    },
    {
      key: "Qty_Used",
      header: "QTY Used",
      visible: true,
    },
    {
      key: "Unit_Price",
      header: "Unit Price",
      visible: true,
    },
  ];

  return (
    <CustomWideLayout>
      {/* ─── Unsaved Changes Modal ───────────────────────────── */}
      <Dialog open={showUnsavedModal} onClose={cancelNavigation}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent sx={{ padding: "0 24px" }}>
          <Typography>
            You have unsaved changes. Would you like to save them before
            proceeding?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px 24px",
          }}
        >
          <CustomButton onClick={handleSaveAndNavigate}>
            Save Changes
          </CustomButton>
          <CustomButton onClick={handleDiscardAndNavigate}>
            Discard Changes
          </CustomButton>
          <CustomButton onClick={cancelNavigation}>Cancel</CustomButton>
        </DialogActions>
      </Dialog>
      {/* ───────────────────────────────────────────────────────── */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
          <ContentCard whiteBackground={true} title={pageTitle}>
            <Formik
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={(values, formikHelpers) => {
                return handleSubmit(values);
              }}
              enableReinitialize={true}
            >
              {({ isSubmitting, isValid, dirty, submitForm, resetForm }) => (
                <MainForm
                  isNew={isNew}
                  isDeleteMode={isDeleteMode}
                  equipmentID={equipmentID}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  showContinueModal={showContinueModal}
                  setShowContinueModal={setShowContinueModal}
                  showCancelModal={showCancelModal}
                  handleCancelModalClose={handleCancelModalClose}
                  handleCancelModalConfirm={handleCancelModalConfirm}
                  handleContinueModalClose={handleContinueModalClose}
                  handleContinueModalConfirm={handleContinueModalConfirm}
                  handleDeleteProcedure={handleDeleteProcedure}
                  handleCancel={handleCancel}
                  formikSubmitRef={formikSubmitRef}
                  formikResetRef={formikResetRef}
                  submitForm={submitForm}
                  resetForm={resetForm}
                  setIsFormDirty={setIsFormDirty}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  dirty={dirty}
                />
              )}
            </Formik>
          </ContentCard>
        </Grid>
      </Grid>

      {!isNew && !isDeleteMode && (
        <CustomTable
          titleText={"Maintenance Supplies"}
          isNew={isNew}
          data={data}
          dataConfig={dataConfig}
          onEdit={handleEditMaintenanceMaterial}
          onDelete={handleDeleteMaintenanceMaterial}
          onAddText="Add Supplies"
          onAddClick={handleAddMaintenanceMaterial}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={changePage}
          searchText={"Search Schedule"}
          onSearch={handleSearch}
          showSearch={false}
        />
      )}

      {isOpen && (
        <MaintenanceMaterialsModal
          open={isOpen}
          onClose={closeModal}
          isDelete={isDelete}
          maintenanceMaterialsID={selectedId}
          setData={setData}
          setTotalItems={setTotalItems}
        />
      )}
    </CustomWideLayout>
  );
};

export default MaintenanceProcedure;
