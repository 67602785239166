import React, { useState, useEffect, useRef } from "react";
import {

  Grid,
  Box,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import ContentCard from "../../../../../components/common/ContentCard/ContentCard";
import CustomButton from "../../../../../components/common/CustomButton";
import CustomWideLayout from "../../../../../components/common/Layout/CustomWideLayout";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import CustomTextField from "../../../../../components/common/CustomFormFields/CustomTextField";

import CustomTable from "../../../../../components/common/CustomTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { usePaginatedData, useTableModal } from "../../../../../hooks";
import { equipmentService, productionMaterialsService, productionStepsService, } from "../../../../../services";
import {
  CancelModal,
  ContinueModal,
} from "../../../../../components/common/CustomModals";
import CustomApiDropdown from "../../../../../components/common/CustomFormFields/CustomApiDropdown";
import DeleteModal from "../../../../../components/common/CustomModals/deleteModal";
import { toast } from "react-toastify";
import { breakLabelText } from "../../../../../utils/breakLabelText";
import { set } from "date-fns";
import { ProductionMaterialsModal } from "../../../../../components/modals/TableModals/Production/productionLines/productionMaterialsModal";

const initialValues = {
  Step_Name: "",
  Step_Process: "",
  EquipmentID: "",
  EquipmentBarCode: "",
  OperationStatus: "",
  Employee: "",
};

const validationSchema = Yup.object({
  Step_Name: Yup.string().required("Procedure is required"),
  Step_Process: Yup.string().required("Description is required"),
  EquipmentID: Yup.string().required("Equipment name is required"),
});

const MainForm = ({
  isNew,
  isDeleteMode,
  showDeleteModal,
  setShowDeleteModal,
  showContinueModal,
  setShowContinueModal,
  showCancelModal,
  handleCancelModalClose,
  handleCancelModalConfirm,
  handleContinueModalClose,
  handleContinueModalConfirm,
  handleDeleteProductionStep,
  handleCancel,
  formikSubmitRef,
  formikResetRef,
  submitForm,
  resetForm,
  setFieldValue,
  setIsFormDirty,
  isValid,
  isSubmitting,
  dirty,
}) => {
  const theme = useTheme();

  // Save Formik functions in refs for later use
  formikSubmitRef.current = submitForm;
  formikResetRef.current = resetForm;

  useEffect(() => {
    setIsFormDirty(dirty);
  }, [dirty, setIsFormDirty]);

  const onChangeEquipment = async (value) => {
    const equipment = await equipmentService.getById(value);

    setFieldValue("EquipmentBarCode", equipment.data.Equipment_BarCode);
    setFieldValue("OperationStatus", equipment.data.Operational_Status);
  }
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField
            name="Step_Name"
            label="Step"
            disabled={true}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomApiDropdown
            name={"EquipmentID"}
            label={"Equipment Name"}
            fetchOptions={equipmentService.getAll}
            valueKey={"EquipmentID"}
            labelKey={"Name"}
            showIdInLabel={false}
            disabled={isDeleteMode}
            required={!isDeleteMode}
            onSelectChange={onChangeEquipment}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            name="Step_Process"
            label="Step Process"
            disabled={isDeleteMode}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="EquipmentBarCode"
            label="Equipment Barcode"
            disabled={true}
          />
        </Grid> <Grid item xs={12}>
          <CustomTextField
            name="OperationStatus"
            label="Operational Status"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <CustomButton
              boldText
              sx={{ mr: 2 }}
              onClick={() => handleCancel(dirty)}
            >
              {dirty ? "Cancel" : "Go Back"}
            </CustomButton>

            {isDeleteMode ? (
              <CustomButton
                boldText
                onClick={() => setShowDeleteModal(true)}
                sx={{
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.error.contrastText,
                }}
              >
                Delete
              </CustomButton>
            ) : (
              <CustomButton
                boldText
                onClick={() => setShowContinueModal(true)}
                disabled={isSubmitting || !dirty || (isNew && !isValid)}
              >
                {isNew ? "Create" : "Update"}
              </CustomButton>
            )}
          </Box>

          <CancelModal
            open={showCancelModal}
            onClose={handleCancelModalClose}
            onConfirm={handleCancelModalConfirm}
          />

          <ContinueModal
            open={showContinueModal}
            onClose={handleContinueModalClose}
            onConfirm={() => handleContinueModalConfirm(submitForm)}
          />

          {isDeleteMode && showDeleteModal && (
            <DeleteModal
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onConfirm={handleDeleteProductionStep}
            />
          )}
        </Grid>
      </Grid>
    </Form>
  );
};


const ProductionStep = () => {
  const { productionStepID, productionLineID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isDeleteMode, Production_Line } = location.state || {};

  const isNew = !productionStepID;

  const pageTitle = isNew
    ? "Create New Production Step"
    : isDeleteMode
      ? "Delete Production Step"
      : "Edit Production Step";

  const [formData, setFormData] = useState(initialValues);

  const { isOpen, isDelete, selectedId, openModal, closeModal } =
    useTableModal();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // ─── NEW STATE & REFS FOR UNSAVED CHANGES ─────────────────────────────
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const formikSubmitRef = useRef(null);
  const formikResetRef = useRef(null);
  // ────────────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (isNew) {
      setFormData({ ...initialValues, Step_Name: Production_Line });
      return;
    }

    const fetchData = async () => {
      try {
        const response = await productionStepsService.getById(productionStepID);
        const data = response.data;
        setFormData({
          Production_StepID: data.Production_StepID,
          Step_Name: data["Production_Line-Production_Line"],
          Step_Process: data.Step_Process,
          EquipmentID: data["Equipment-EquipmentID"],
          EquipmentBarCode: data["Equipment-Equipment_BarCode"],
          OperationStatus: data["Equipment-Operational_Status"],
          Employee: data["Employee-Full_Name"],
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
        toast.error("Failed to fetch data");
      }
    };

    fetchData();
  }, [productionStepID, isNew]);

  const handlePost = async (values) => {
    try {
      const body = {
        Production_LineID: productionLineID,
        EquipmentID: values.EquipmentID,
        Step_Process: values.Step_Process,
        Production_Step: 0,
      };
      const response = await productionStepsService.post(body);
      const newId = response.data.Production_StepID;
      navigate(`/production/product-assembly/production-line/${productionLineID}/product-step/${newId}`, { replace: true });
      toast.success("Production step created successfully!");
    } catch (error) {
      console.error("Failed to create:", error);
      toast.error("Failed to create production step.");
    }
  };

  const handlePut = async (values) => {
    try {
      const body = {
        Production_LineID: productionLineID,
        EquipmentID: values.EquipmentID,
        Step_Process: values.Step_Process,
      };
      const { data: updatedData } = await productionStepsService.put(
        productionStepID,
        body
      );
      console.log("🚀🚀🚀 Updated Data", updatedData);
      setFormData({
        Production_StepID: updatedData.Production_StepID,
        Step_Name: updatedData.Production_Line.Production_Line,
        Step_Process: updatedData.Step_Process,
        EquipmentID: updatedData.Equipment.EquipmentID,
        EquipmentBarCode: updatedData.Equipment.Equipment_BarCode,
        OperationStatus: updatedData.Equipment.Operational_Status,
      });
      toast.success("Production step updated successfully!");
    } catch (error) {
      console.error("Failed to create:", error);
      toast.error("Failed to update production step.");
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (isNew) {
        await handlePost(values);
      } else {
        await handlePut(values);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form.");
    }
  };

  const handleCancel = (dirty) => {
    if (dirty) {
      setShowCancelModal(true);
    } else {
      navigate(-1);
    }
  };

  const handleDeleteProductionStep = async () => {
    try {
      await productionStepsService.delete(productionStepID);
      setShowDeleteModal(false);
      navigate(-1);
      toast.success("Production step deleted successfully!");
    } catch (error) {
      console.error("Failed to delete production step:", error);
      toast.error("Failed to delete production step.");
    }
  };

  // ─── Wrap navigation actions to check for unsaved changes ────────────────
  const handleNavigationAction = (action) => {
    if (isFormDirty) {
      setPendingNavigation(() => action);
      setShowUnsavedModal(true);
    } else {
      action();
    }
  };
  // ────────────────────────────────────────────────────────────────────────

  // ─── Updated Production Step Supplies Actions ──────────────────────────────────
  const handleAddProductionMaterial = () => {
    handleNavigationAction(() => {
      handleAdd();
    });
  };

  const handleEditProductionMaterial = (row) => {
    handleNavigationAction(() => {
      handleEdit(row);
    });
  };

  const handleDeleteProductionMaterial = (row) => {
    handleNavigationAction(() => {
      handleDelete(row);
    });
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
  };

  const handleCancelModalConfirm = () => {
    setShowCancelModal(false);
    navigate(-1);
  };

  const handleContinueModalClose = () => {
    setShowContinueModal(false);
  };

  const handleContinueModalConfirm = async (submitForm) => {
    setShowContinueModal(false);
    submitForm();
  };


  const {
    data,
    setData,
    totalItems,
    setTotalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  } = usePaginatedData((params) =>
    productionMaterialsService.getByProductionStepId(productionStepID, params)
  );

  // ─── Functions for Unsaved Changes Modal Actions ───────────────────────
  const handleSaveAndNavigate = async () => {
    if (formikSubmitRef.current) {
      // Calling submitForm returns a promise
      await formikSubmitRef.current();
      if (pendingNavigation) {
        pendingNavigation();
        setPendingNavigation(null);
      }
      setShowUnsavedModal(false);
    }
  };

  const handleDiscardAndNavigate = () => {
    if (formikResetRef.current) {
      // Reset the form to the saved formData
      formikResetRef.current(formData);
    }
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
    setShowUnsavedModal(false);
  };

  const cancelNavigation = () => {
    setShowUnsavedModal(false);
    setPendingNavigation(null);
  };
  // ────────────────────────────────────────────────────────────────────────

  const handleEdit = (row) => {
    const productionMaterialsID = row.Production_MaterialsID;
    openModal(productionMaterialsID);
  };

  const handleDelete = (row) => {
    const productionMaterialsID = row.Production_MaterialsID;
    openModal(productionMaterialsID, true);
  };

  const handleAdd = () => {
    openModal();
  };

  const dataConfig = [
    {
      key: "Inventory-Inventory_Name",
      header: "Inventory Name",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Description",
      header: "Inventory Description",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Type-Inventory_Type",
      header: "Inventory Type",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Type-Storage_Method",
      header: "Storage Method",
      visible: true,
    },
    {
      key: "Unit_Measure",
      header: "Unit of Measure",
      visible: true,
    },
    {
      key: "Qty_Used",
      header: "QTY Used",
      visible: true,
    },
    {
      key: "Unit_Price",
      header: "Unit Price",
      visible: true,
    },
  ];

  const materialsData = data.map((item) => {
    return {
      ...item,
      "Unit_Price": `$${item['Unit_Price']}`,
    };
  });

  return (
    <CustomWideLayout>
      {/* ─── Unsaved Changes Modal ───────────────────────────── */}
      <Dialog open={showUnsavedModal} onClose={cancelNavigation}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent sx={{ padding: "0 24px" }}>
          <Typography>
            You have unsaved changes. Would you like to save them before
            proceeding?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px 24px",
          }}
        >
          <CustomButton onClick={handleSaveAndNavigate}>
            Save Changes
          </CustomButton>
          <CustomButton onClick={handleDiscardAndNavigate}>
            Discard Changes
          </CustomButton>
          <CustomButton onClick={cancelNavigation}>Cancel</CustomButton>
        </DialogActions>
      </Dialog>
      {/* ───────────────────────────────────────────────────────── */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
          <ContentCard whiteBackground={true} title={pageTitle}>
            <Formik
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting, isValid, dirty, submitForm, resetForm, setFieldValue }) => (
                <MainForm
                  isNew={isNew}
                  isDeleteMode={isDeleteMode}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  showContinueModal={showContinueModal}
                  setShowContinueModal={setShowContinueModal}
                  showCancelModal={showCancelModal}
                  handleCancelModalClose={handleCancelModalClose}
                  handleCancelModalConfirm={handleCancelModalConfirm}
                  handleContinueModalClose={handleContinueModalClose}
                  handleContinueModalConfirm={handleContinueModalConfirm}
                  handleDeleteProductionStep={handleDeleteProductionStep}
                  handleCancel={handleCancel}
                  formikSubmitRef={formikSubmitRef}
                  formikResetRef={formikResetRef}
                  submitForm={submitForm}
                  resetForm={resetForm}
                  setIsFormDirty={setIsFormDirty}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  dirty={dirty}
                  setFieldValue={setFieldValue}
                />
              )}
            </Formik>
          </ContentCard>
        </Grid>
      </Grid>

      {!isNew && !isDeleteMode && (
        <Box position="relative">
          <CustomTable
            titleText={"Production  Supplies"}
            isNew={isNew}
            data={materialsData}
            dataConfig={dataConfig}
            onEdit={handleEditProductionMaterial}
            onDelete={handleDeleteProductionMaterial}
            onAddText="Add Supplies"
            onAddClick={handleAddProductionMaterial}
            totalItems={totalItems}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={changePage}
            searchText={"Search"}
            onSearch={handleSearch}
          />
        </Box>
      )}

      {isOpen && <ProductionMaterialsModal
        open={isOpen}
        onClose={closeModal}
        isDelete={isDelete}
        productionMaterialsID={selectedId}
        setData={setData}
        setTotalItems={setTotalItems}
      />}
    </CustomWideLayout>
  );

};

export default ProductionStep;
