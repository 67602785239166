import moment from "moment/moment";

export const getNextDateAndTime = (schedule) => {
  // If no schedule, return nulls
  if (!schedule) {
    return { date: null, time: null };
  }

  const { scheduleType, startDate, startTime, dayOfWeek, interval } = schedule;

  // Parse StartDate + StartTime into a moment
  // Assuming StartDate is in "MM-DD-YYYY" and StartTime is in "hh:mm A" format
  const start = moment(`${startDate} ${startTime}`, "MM-DD-YYYY hh:mm A", true);
  const now = moment();

  // --- 1) Handle one-time ---
  if (scheduleType === "one-time") {
    // Next occurrence is simply the start date/time
    return {
      date: start.format("MM-DD-YYYY"),
      time: start.format("hh:mm A"),
    };
  }

  // Otherwise, we look for the next occurrence after 'now'
  // We'll create a 'next' moment that we'll move forward until it matches.
  let next = start.clone();
  // If start is already in the future, we begin checking from 'start'
  // If start is in the past, we begin checking from 'start' as well,
  // but the while loop will push it forward until it's after 'now'.
  // (Alternatively, we could begin from 'now', but be consistent in how you handle "multiple" logic.)

  switch (scheduleType) {
    // =============== HOURLY ===============
    case "hourly": {
      // Condition for an occurrence:
      //   (difference in hours from start) % interval == 0
      //   and next is after 'now'
      // We'll increment by 1 hour in the while loop.
      while (!next.isAfter(now) || next.diff(start, "hours") % interval !== 0) {
        next.add(1, "hour");
      }
      break;
    }

    // =============== DAILY ===============
    case "daily": {
      // Condition for an occurrence:
      //   (difference in days from start) % interval == 0
      //   and next is after 'now'
      while (!next.isAfter(now) || next.diff(start, "days") % interval !== 0) {
        next.add(1, "day");
      }
      break;
    }

    // =============== WEEKLY ===============
    case "weekly": {
      // Condition for an occurrence:
      //   (difference in whole weeks from start) % interval == 0
      //   dayOfWeek is in schedule.DayOfWeek
      //   next is after now

      // Ensure we have an array; fallback to any day if not provided
      const validDays =
        Array.isArray(dayOfWeek) && dayOfWeek.length
          ? dayOfWeek
          : [start.day()]; // fallback if DayOfWeek is null or empty

      while (
        !next.isAfter(now) ||
        next.diff(start, "weeks") % interval !== 0 ||
        !validDays.includes(next.day())
      ) {
        next.add(1, "day");
      }
      break;
    }

    // =============== MONTHLY ===============
    case "monthly": {
      // We want to preserve the “day of month” from `start`.
      // Example: if start is 31st, we try each multiple of interval months
      // on the 31st. If that month doesn't have 31 days, we clamp to last day.

      // The day of month we want to preserve:
      const desiredDayOfMonth = start.date();

      // We'll start from the largest multiple of `interval` months
      // that is >= now.
      // 1) How many months have passed since start -> now
      // 2) Round that down to nearest multiple of `interval`
      // 3) Then keep adding `interval` months until we find a time > now.

      // But to keep it simpler (and more explicit), let's just do a
      // loop that increments in steps of `interval` months until we pass `now`.

      // Start from the start itself
      let monthsOffset = 0;
      let candidate = start.clone();

      // Move candidate forward in intervals of months until we pass `now`
      while (!candidate.isAfter(now)) {
        monthsOffset += interval;
        candidate = start.clone().add(monthsOffset, "months");

        // Attempt to keep the same day of month (clamp if it doesn't exist)
        const daysInCandidateMonth = candidate.daysInMonth();
        candidate.date(Math.min(desiredDayOfMonth, daysInCandidateMonth));
      }

      next = candidate;

      break;
    }

    // =============== YEARLY ===============
    case "yearly": {
      // Condition:
      // 1) next is after now
      // 2) (years since start) % interval === 0
      //
      // We can do a loop that increments year by year, or do a direct calculation.
      // For clarity, let's show the loop approach:
      while (true) {
        if (next.isAfter(now)) {
          break;
        }
        // Jump one year at a time
        next.add(interval, "year");
      }

      break;
    }

    default: {
      // Unrecognized schedule type
      next = { date: null, time: null };
      break;
    }
  }

  // Now 'next' should be set to the first occurrence after 'now' that
  // meets the "multiple of interval" condition (and dayOfWeek if weekly).
  return {
    date: next.format("MM/DD/YYYY"),
    time: next.format("hh:mm A"),
  };
};
