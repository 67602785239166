import React from "react";

import { useNavigate } from "react-router-dom";

import CustomWideLayout from "../../../../components/common/Layout/CustomWideLayout";
import CustomTable from "../../../../components/common/CustomTable";
import { usePaginatedData } from "../../../../hooks";
import { productionLinesService } from "../../../../services";
import { DragAndDropTable } from "../../../../components/common/Tables";
import CustomDragDropTable from "../../../../components/common/CustomDragDropTable";
import { breakLabelText } from "../../../../utils/breakLabelText";

const ProductionLine = () => {
  const navigate = useNavigate();

  const {
    data,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  } = usePaginatedData(productionLinesService.getAll);

  const getTableData = (data) => {
    console.log(data);
    return data.map((row) => {
      return {
        Production_LineID: row.Production_LineID,
        Production_Line: row.Production_Line,
        Production_Description: row.Production_Description,
        Step_Quantity: row.Production_Steps.length,
        Open_Jobs: row.Production_Steps.length,
      };
    });
  }

  const handleEdit = (row) => {
    navigate(
      `/production/product-assembly/production-line/${row.Production_LineID}`
    );
  };

  const handleDelete = async (row) => {
    navigate(
      `/production/product-assembly/production-line/${row.Production_LineID}`, {
      state: {
        isDeleteMode: true,
      },
    });
  };

  const handleAdd = (row) => {
    navigate(
      `/production/product-assembly/production-line/new`
    );
  };

  const dataConfig = [
    {
      key: "Production_LineID",
      header: breakLabelText("Production Line ID"),
      visible: true,
    },
    {
      key: "Production_Line",
      header: "Production_Line",
      visible: true,
    },
    {
      key: "Production_Description",
      header: "Production_Description",
      visible: true,
    },
    {
      key: "Step_Quantity",
      header: "Step Quantity",
      visible: true,
    },
    {
      key: "Open_Jobs",
      header: "Open Jobs",
      visible: true,
    },
  ];
  return (
    <>
      <CustomWideLayout>
        <CustomTable
          titleText={"Production Lines"}
          data={getTableData(data)}
          dataConfig={dataConfig}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onAddText="Add New Production Line"
          onAddClick={handleAdd}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={changePage}
          searchText={"Search"}
          onSearch={handleSearch}
        />

      </CustomWideLayout>
    </>
  );
};

export default ProductionLine;
