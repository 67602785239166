import React, { useState, useEffect, useCallback, memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  PaginationItem,
  TextField,
  Button,
  styled,
  InputAdornment,
  IconButton,
  MenuList,
  MenuItem,
  ListItemIcon,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  Search,
  MoreHoriz,
} from "@mui/icons-material";
import editIcon from "../../../assets/images/editIcon.svg";
import trashIcon from "../../../assets/images/trashIcon.svg";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { DragAndDropTable } from "../Tables";

const CustomDragDropTable = memo(
  ({
    useSpecialRendering = false,
    renderConfig,

    titleText = "",

    isNew,
    showPagination = true,

    data = [],
    dataConfig = [],

    onEdit,
    onDelete,
    onOrderChange,
    menuItems, // Please use this instead

    onAddClick = null,
    onAddText = null,

    showSearch = true,
    searchText = "Search...",
    onSearch,

    currentPage,
    totalPages,
    onPageChange,

    pageSize = 10,

    backButton = false,
    backButtonText = "",
  }) => {
    const theme = useTheme();

    // For popup Menu
    const [clickedRowPos, setClickedRowPos] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [searchValue, setSearchValue] = useState("");

    const visibleColumns = dataConfig.filter((col) => col.visible);

    const navigate = useNavigate();

    const handleSearchChange = useCallback(
      (e) => {
        const newValue = e.target.value;
        setSearchValue(newValue);
        onSearch(newValue);
      },
      [onSearch]
    );
    const handlePageChange = (event, newPage) => {
      onPageChange(newPage, pageSize);
    };

    /**
     * This useEffect removes the menu popup the moment you click away or scroll
     */
    useEffect(() => {
      const handleClickOutsideOrScroll = (event) => {
        if (clickedRowPos) {
          setClickedRowPos(null);
          setSelectedRow(null);
        }
      };

      document.addEventListener("click", handleClickOutsideOrScroll);
      window.addEventListener("scroll", handleClickOutsideOrScroll);

      return () => {
        document.removeEventListener("click", handleClickOutsideOrScroll);
        window.removeEventListener("scroll", handleClickOutsideOrScroll);
      };
    }, [clickedRowPos]);

    /**
     * Show the same menu when the row is clicked.
     */
    const handleRowClick = (event, row) => {
      // If user clicked the row, not the icon
      event.stopPropagation();
      setClickedRowPos({ x: event.clientX, y: event.clientY });
      setSelectedRow(row);
    };

    /**
     * Show the same menu from the action icon (MoreHoriz).
     * (stopPropagation to prevent row's onClick from firing)
     */
    const handleActionClick = (event, row) => {
      event.stopPropagation();
      const rect = event.currentTarget.getBoundingClientRect();
      // Position near the icon
      setClickedRowPos({ x: rect.left - 160, y: rect.top });
      setSelectedRow(row);
    };

    const getMenuItems = useCallback(() => {
      if (menuItems) {
        return menuItems;
      }

      // Fall back to old pattern
      const defaultItems = [];
      if (onEdit) {
        defaultItems.push({
          icon: editIcon,
          label: "Edit",
          handler: onEdit,
        });
      }
      if (onDelete) {
        defaultItems.push({
          icon: trashIcon,
          label: "Delete",
          handler: onDelete,
        });
      }
      return defaultItems;
    }, [menuItems, onEdit, onDelete]);

    // ─────────────────────────────────────────────────────────────────────────────
    // Styles
    // ─────────────────────────────────────────────────────────────────────────────

    const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
      marginTop: theme.spacing(2),
      boxShadow: "none",
      backgroundColor: "transparent",
      marginBottom: "1rem",
      position: "relative",
    }));

    const StyledTable = styled(Table)(({ theme }) => ({
      borderCollapse: "separate",
      borderSpacing: "0 16px", // space between rows
    }));

    const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
      color: "#143664",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "1.15rem",
      borderWidth: 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.secondary,
      "&:first-of-type": {
        borderRadius: "20px 0 0 20px",
      },
      "&:last-child": {
        borderRadius: "0 20px 20px 0",
      },
    }));

    const StyledBodyCell = styled(TableCell)(({ theme }) => ({
      borderWidth: 0,
      padding: theme.spacing(2),
      "&:first-of-type": {
        borderRadius: "20px 0 0 20px",
      },
      "&:last-child": {
        borderRadius: "0 20px 20px 0",
      },
      "&:not(:last-child)": {
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
          height: "18px",
          width: "1px",
          backgroundColor: "#888888",
        },
      },
    }));

    const PillContent = styled("div")(({ theme, variant = "neutral" }) => {
      const variants = {
        neutral: {
          backgroundColor: theme.palette.primary.main,
          color: "#ffffff",
        },
        success: {
          backgroundColor: theme.palette.septenary.tertiary,
          color: theme.palette.primary.tertiary,
        },
        warning: {
          backgroundColor: theme.palette.senary.secondary,
          color: theme.palette.primary.tertiary,
        },
        done: {
          backgroundColor: theme.palette.primary.quaternary,
          color: "#ffffff",
        },
      };

      const variantStyles = variants[variant] || variants.neutral;

      return {
        ...variantStyles,
        borderRadius: "16px",
        padding: theme.spacing(2, 3),
        textAlign: "center",
      };
    });

    const UnderlinedBoldContent = styled("div")(({ theme }) => ({
      textDecoration: "underline",
      fontWeight: "bold",
    }));

    const StyledMenuList = styled(MenuList)(({ theme }) => ({
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      borderRadius: "12px",
      padding: "16px",
      gap: "8px",
      // boxShadow:
      //   "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      "& .MuiMenuItem-root": {
        padding: "8px",
        borderRadius: "8px",
        ...theme.typography.label1,
        color: "#2D3748",
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
      },
    }));

    const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
      backgroundColor: theme.palette.tertiary.main,
      borderRadius: "12px",
      width: "32px",
      height: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "16px",
        height: "16px",
      },
    }));

    // ─────────────────────────────────────────────────────────────────────────────
    // Rendering Helpers
    // ─────────────────────────────────────────────────────────────────────────────

    const renderCell = (column, row) => {
      const value = row[column.key];
      if (!useSpecialRendering) {
        return value;
      }

      if (column.key === "day" && row.isToday) {
        return <UnderlinedBoldContent>{value}</UnderlinedBoldContent>;
      }

      if (column.key.startsWith("Production")) {
        const statusKey = `${column.key}-status`;
        return <PillContent variant={row[statusKey]}>{value}</PillContent>;
      }

      return value;
    };

    const getRowColor = (row) => {
      if (!renderConfig?.rowColorConfig) return "white";
      const { key, colorMap } = renderConfig.rowColorConfig;
      const value = row[key];
      return colorMap[value] || "white";
    };

    // ─────────────────────────────────────────────────────────────────────────────
    // Render
    // ─────────────────────────────────────────────────────────────────────────────

    return (
      <>
        {/* Header Area */}
        <Grid
          container
          alignItems="center"
          marginBottom=".75rem"
          marginTop="1rem"
          spacing={2}
        >
          {/* Left column for Add New Button */}
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              gap: "8px",
            }}
          >
            {onAddText && (
              <Button
                variant="contained"
                onClick={onAddClick}
                disabled={isNew}
                sx={{
                  borderRadius: "12px",
                  padding: "8px 28px",
                  minHeight: "48px",
                  textTransform: "none",
                }}
              >
                {onAddText}
              </Button>
            )}
            {backButton && (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(-1);
                }}
                disabled={isNew}
                sx={{
                  borderRadius: "12px",
                  padding: "8px 28px",
                  minHeight: "48px",
                  textTransform: "none",
                }}
              >
                {backButtonText}
              </Button>
            )}
          </Grid>

          {/* Center column for Title */}
          <Grid item xs={4}>
            {titleText && (
              <Typography
                variant="h1"
                sx={{
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontSize: "32px",
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
              >
                {titleText}
              </Typography>
            )}
          </Grid>

          {/* Right column for Search Field */}
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            {showSearch && (
              <TextField
                placeholder={searchText}
                value={searchValue}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ margin: 0 }}>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "320px",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                    borderRadius: "12px",
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "16px 8px",
                    },
                  },
                }}
              />
            )}
          </Grid>
        </Grid>

        {/* Table */}
        <StyledTableContainer component={Paper}>
          <DragAndDropTable
            dataConfig={dataConfig}
            data={data}
            onRowClick={handleRowClick}
            onOrderChange={onOrderChange}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </StyledTableContainer>

        {/* Pagination */}
        <Stack
          spacing={2}
          sx={{
            "& .MuiPagination-ul": {
              margin: 0,
              padding: 0,
              "& .MuiPaginationItem-root": {
                margin: 0,
                padding: 0,
              },
            },
            marginBottom: "1rem",
          }}
        >
          {isNew ||
            !showPagination ||
            totalPages < 2 ||
            data.length < 1 ? null : (
            <Pagination
              count={totalPages}
              page={currentPage}
              siblingCount={1}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  components={{
                    previous: () => (
                      <div className="bg-BtnBg px-6 py-3 rounded-xl flex items-center gap-1 hover:bg-opacity-80 transition-all mr-auto">
                        <ArrowBack sx={{ fontSize: 12, color: "#fff" }} />
                        <span className="text-base font-normal text-BtnText">
                          Previous
                        </span>
                      </div>
                    ),
                    next: () => (
                      <div className="bg-BtnBg px-6 py-3 rounded-xl flex items-center gap-1 hover:bg-opacity-80 transition-all ml-auto">
                        <span className="text-base font-normal text-BtnText">
                          Next
                        </span>
                        <ArrowForward sx={{ fontSize: 12, color: "#fff" }} />
                      </div>
                    ),
                  }}
                />
              )}
            />
          )}
        </Stack>

        {/* Popup Menu */}
        {clickedRowPos && (
          <div
            className="fixed z-50"
            style={{
              left: clickedRowPos.x,
              top: clickedRowPos.y,
            }}
          >
            <StyledMenuList className="min-w-40 shadow-2xl">
              {getMenuItems().map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    item.handler(selectedRow);
                    setClickedRowPos(null);
                  }}
                  className="flex items-center gap-2 text-lg cursor-pointer hover:bg-background rounded-xl transition-all duration-300"
                >
                  <StyledListItemIcon
                    sx={{
                      minWidth: "32px",
                    }}
                  >
                    <img src={item.icon} alt={item.label} />
                  </StyledListItemIcon>
                  {item.label}
                </div>
              ))}
            </StyledMenuList>
          </div>
        )}
      </>
    );
  }
);

export default CustomDragDropTable;
