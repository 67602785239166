import React, { useState, useRef, useEffect } from "react";
import { useField } from "formik";
import {
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Paper,
  List,
  ListItem,
  ListItemButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useApiDropdown2 from "../../../hooks/useApiDropdown2";

const CustomApiDropdown2 = ({
  name,
  label,
  fetchOptions,
  valueKey = "id",
  labelKey = "name",
  formKey = "",
  showIdInLabel = false,
  onSelectChange,
  required = true,
  onCreateNewItem,
  isCreateNew = true,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { value: formikValue } = field;
  const { setValue } = helpers;
  const errorText = meta.error && meta.touched ? meta.error : "";
  const theme = useTheme();

  const [isFirst, setIsFirst] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const isSelectingOption = useRef(false);

  const { options, setOptions, isLoading, error } = useApiDropdown2(
    fetchOptions,
    inputValue,
    valueKey,
    labelKey,
    showIdInLabel
  );

  useEffect(() => {
    console.log("Fetching options...");
    fetchOptions()
      .then((res) => {
        if (res) {
          console.log("Options fetched:", res.data.data);
          setOptions(res.data.data);
        } else {
          console.log("No options found.");
          setOptions([]);
        }
      })
      .catch((error) => console.error("Error fetching options:", error));
  }, []);

  const handleInputChange = (event) => {
    const val = event.target.value;
    console.log("Input changed:", val);
    setInputValue(val);
    setIsDropdownVisible(!!val);
  };

  const handleOptionSelect = (option) => {
    console.log("Option selected:", option);
    isSelectingOption.current = true;
    setInputValue(option[labelKey]);
    setValue(option[valueKey]);
    setIsDropdownVisible(false);
    onSelectChange(option[valueKey]);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (!isSelectingOption.current) {
        const matchedOption = options.find(
          (opt) => opt[labelKey]?.toLowerCase() === inputValue?.toLowerCase()
        );
        if (inputValue && !matchedOption && isCreateNew) {
          console.log("No matching option found, opening create dialog.");
          setOpenCreateDialog(true);
        }
      }
      isSelectingOption.current = false;
    }, 150);
  };

  useEffect(() => {
    console.log("Checking Formik value:", options, formikValue, valueKey, labelKey);
    if (formikValue && options.length > 0 && isFirst) {
      const matchedOption = options.find((item) => item[valueKey] === formikValue);
      console.log("Matched option for Formik value:", matchedOption);
      setInputValue(matchedOption ? matchedOption[labelKey] : "");
      setIsFirst(false);
    }
  }, [formikValue, isFirst, labelKey, options, valueKey]);

  const handleCreateConfirm = async () => {
    setOpenCreateDialog(false);
    try {
      if (onCreateNewItem) {
        console.log("Creating new item with value:", inputValue);
        const createdItem = await onCreateNewItem(inputValue);

        const newId = createdItem.data[valueKey];
        const newLabel = createdItem.data[labelKey];

        console.log("New item created:", { newId, newLabel });

        setOptions((prev) => [
          ...prev,
          {
            value: newId,
            label: newLabel,
          },
        ]);

        setValue(newId);
        setInputValue(newLabel);
        setIsDropdownVisible(false);
      }
    } catch (error) {
      console.error("Create equipment type failed:", error);
    }
  };

  const handleCreateCancel = () => {
    console.log("Create dialog cancelled.");
    setOpenCreateDialog(false);
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={4}>
        <Typography>
          {label}
          {required && <span className="text-red-700">*</span>}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <div style={{ position: "relative" }}>
          <TextField
            {...field}
            {...props}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            fullWidth
            helperText={errorText || error}
            error={!!errorText || !!error}
            disabled={props?.disabled}
            required={required}
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: "#F3F4F6",
                borderRadius: "0.75rem",
                fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#E5E7EB",
                },
                "&:hover fieldset": {
                  borderColor: "#D1D5DB",
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary.main,
                },
              },
              "& .MuiInputBase-input": {
                color: "#4D5658",
                fontSize: "0.8rem",
              },
              "& .MuiFormHelperText-root": {
                fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
                fontSize: "0.7rem",
              },
            }}
          />

          {isDropdownVisible && options.length > 0 && (
            <Paper
              elevation={3}
              style={{
                position: "absolute",
                zIndex: 9999,
                width: "100%",
                maxHeight: 200,
                overflowY: "auto",
                marginTop: 4,
              }}
            >
              {isLoading ? (
                <ListItem>
                  <CircularProgress size={20} />
                  &nbsp; Loading...
                </ListItem>
              ) : (
                <List>
                  {options.map((option) => (
                    <ListItem
                      key={option[valueKey]}
                      disablePadding
                      onMouseDown={() => {
                        isSelectingOption.current = true;
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option[labelKey]}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </Paper>
          )}
        </div>
      </Grid>

      <Dialog
        open={openCreateDialog}
        onClose={handleCreateCancel}
        sx={{
          zIndex: 10000,
          ".MuiPaper-root": {
            maxWidth: "480px",
          },
          ".MuiDialogActions-root": {
            padding: "16px",
          },
        }}
      >
        <DialogTitle>Create a new item?</DialogTitle>
        <DialogContent>
          You typed "<strong>{inputValue}</strong>" which is not in the list.
          Would you like to create a new item with this name?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCreateCancel}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateConfirm}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CustomApiDropdown2;
