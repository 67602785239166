import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import BaseModal from "../baseModal";
import {
  cleaningMaterialsService,
  inventoriesService,
  inventoryTypesService,
  productionCoolerService,
} from "../../../../services";
import CustomTextField from "../../../common/CustomFormFields/CustomTextField";
import CustomApiDropdown from "../../../common/CustomFormFields/CustomApiDropdown";
import CustomButton from "../../../common/CustomButton";
import CustomPriceField from "../../../common/CustomFormFields/CustomPriceField";
import DeleteModal from "../../../common/CustomModals/deleteModal";
import CustomApiDropdown2 from "../../../common/CustomFormFields/CustomApiDropdown2";
import { toast } from "react-toastify";

const initialFormData = {
  Inventory_Name: "",
  Production_Name: "",
  Production_Description: "",
  Recipe_Name: "",
  Qty: "",
  Unit_Measure: "lbs",
  Cooler_Schedule: 0,
  Unit_Price: 0,
};

const validationSchema = Yup.object({
  Cooler_Schedule: Yup.string().required("Cooler Schedule is required"),
});

export const ProductionCoolerModal = ({
  open,
  onClose,
  production,
  recipe,
  setData,
  isDelete = false,
}) => {

  const isNew = recipe.Production_Coolers.length > 0 ? false : true;

  const [formData, setFormData] = useState(initialFormData);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (production && recipe) {
      setFormData({
        Inventory_Name: recipe.Inventory_Name,
        Production_Name: production.Production_Name,
        Production_Description: production.Production_Description,
        Recipe_Name: recipe.Recipe_Name,
        Qty: recipe.Recipe_Qty,
        Unit_Measure: recipe.Unit_Measure,
        Cooler_Schedule: recipe.Production_Coolers.length ? recipe.Production_Coolers[0].Cooler_Schedule : 0,
        Unit_Price: recipe.Production_Coolers.length ? recipe.Production_Coolers[0].Unit_Price : 0,
      });
    } else {
      setFormData(initialFormData);
    }

  }, [production, recipe, open]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (isNew) {
        await postFormData(values);
      } else {
        await putFormData(values);
      }
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const postFormData = async (values) => {
    try {
      const body = {
        ProductionID: production.ProductionID,
        Production_RecipeID: recipe.Production_RecipeID,
        Cooler_Schedule: values.Cooler_Schedule,
        Unit_Price: new Intl.NumberFormat("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(values.Unit_Price),
      };

      const response = await productionCoolerService.post(body);

      if (response) {
        setData((prev) => {
          const updatedData = [...prev];
          const recipeIndex = updatedData.findIndex(item => item.Production_RecipeID === recipe.Production_RecipeID);
          if (recipeIndex !== -1) {
            updatedData[recipeIndex].Production_Coolers.push(response.data);
          }
          return updatedData;
        });
      }

      toast.success("Production cooler added successfully");
    } catch (error) {
      console.error("Error adding Production cooler:", error);
      toast.error("Failed to add Production cooler");

    }

  };

  const putFormData = async (values) => {
    try {
      const body = {
        Cooler_Schedule: values.Cooler_Schedule,
        Unit_Price: new Intl.NumberFormat("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(values.Unit_Price),
      };
      const response = await productionCoolerService.put(
        recipe.Production_Coolers[0].Production_CoolerID,
        body
      );
      if (response) {
        setData((prev) => {
          const updatedData = [...prev];
          const recipeIndex = updatedData.findIndex(item => item.Production_RecipeID === recipe.Production_RecipeID);
          if (recipeIndex !== -1) {
            updatedData[recipeIndex].Production_Coolers = []
            updatedData[recipeIndex].Production_Coolers.push(response.data);
          }
          return updatedData;
        });
      }

      toast.success("Production cooler updated successfully");
    } catch (error) {
      console.error("Error updating cleaning material:", error);
      toast.error("Failed to update cleaning material");
    }

  };

  const handleDeleteMaterial = async () => {
    try {
      if (recipe && recipe.Production_Coolers.length > 0) {
        await productionCoolerService.delete(recipe.Production_Coolers[0].Production_CoolerID);

        setData((prev) => {
          const updatedData = [...prev];
          const recipeIndex = updatedData.findIndex(item => item.Production_RecipeID === recipe.Production_RecipeID);
          if (recipeIndex !== -1) {
            updatedData[recipeIndex].Production_Coolers = [];
          }
          return updatedData;
        });
        setShowDeleteModal(false);
        onClose();
        toast.success("Production cooler deleted successfully");
      } else {
        toast.error("Invalied Production cooler ID");
      }
    } catch (error) {
      console.error("Error deleting production cooler:", error);
      toast.error("Failed to delete production cooler");
    }

  };

  return (
    <>
      <BaseModal
        open={open}
        onClose={onClose}
        title={isDelete ? "Delete Cooler Item" : (isNew ? "Add Cooler Item" : "Edit Cooler Item")}
      >
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, isValid, dirty, values, setFieldValue }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="Inventory_Name"
                      label="Inventory Name"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="Production_Name"
                      label="Production Name"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="Production_Description"
                      label="Production Description"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="Recipe_Name"
                      label="Recipe Name"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="Qty"
                      label="Quantity"
                      placeholder="0"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="Unit_Measure"
                      label="Unit Measure"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="Cooler_Schedule"
                      label="Cooler Schedule"
                      disabled={isDelete}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomPriceField
                      name="Unit_Price"
                      label="Unit Price"
                      disabled={isDelete}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                      <CustomButton sx={{ mr: 2 }} onClick={onClose}>
                        Cancel
                      </CustomButton>
                      {isDelete ? (
                        <CustomButton
                          boldText
                          onClick={() => setShowDeleteModal(true)}
                          sx={{
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.error.contrastText,
                          }}
                        >
                          Delete
                        </CustomButton>
                      ) : (
                        <CustomButton
                          type="submit"
                          disabled={!dirty || (isNew && !isValid)}
                          loading={isSubmitting || undefined}
                        >
                          {isNew ? "Create" : "Update"}
                        </CustomButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </BaseModal>
      {!isNew && showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteMaterial}
        />
      )}
    </>
  );
};
