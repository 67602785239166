// Inventories CRUD Pages
import Inventories from "../pages/Inventories";
import InventoriesNew from "../pages/Inventories/InventoriesNew";
import InventoriesEdit from "../pages/Inventories/InventoriesEdit";
import InventoriesDelete from "../pages/Inventories/InventoriesDelete";
// Other Pages
import InventoryDashboard from "../pages/Inventories/InventoriesDashboard";

// Inventory Reports Page
import InventoryReports from "../pages/Reports/InventoryReports";

// Rejected Inventories CRUD Pages
import RejectedInventories from "../pages/RejectedInventories";
import RejectedInventoriesNew from "../pages/RejectedInventories/RejectedInventoriesNew";
import RejectedInventoriesEdit from "../pages/RejectedInventories/RejectedInventoriesEdit";
import RejectedInventoriesDelete from "../pages/RejectedInventories/RejectedInventoriesDelete";


export const InventoryRoutes = [
  {
    path: "/inventory/inventory-dashboard",
    element: <InventoryDashboard />,
    private: true,
  },
  {
    path: "/inventory/manage-inventories",
    element: <Inventories />,
    private: true,
  },
  {
    path: "/inventory/manage-inventories/new",
    element: <InventoriesNew />,
    private: true,
  },
  {
    path: "/inventory/manage-inventories/edit",
    element: <InventoriesEdit />,
    private: true,
  },
  {
    path: "/inventory/manage-inventories/delete",
    element: <InventoriesDelete />,
    private: true,
  },
  {
    path: "/inventory/manage-rejected-inventories",
    element: <RejectedInventories />,
    private: true,
  },
  {
    path: "/inventory/manage-rejected-inventories/new",
    element: <RejectedInventoriesNew />,
    private: true,
  },
  {
    path: "/inventory/manage-rejected-inventories/edit",
    element: <RejectedInventoriesEdit />,
    private: true,
  },
  {
    path: "/inventory/manage-rejected-inventories/delete",
    element: <RejectedInventoriesDelete />,
    private: true,
  },
  {
    path: "/inventory/inventory-reports",
    element: <InventoryReports />,
    private: true,
  },

];