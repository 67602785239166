import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useRef,
  Fragment,
  memo,
} from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import MoreHoriz from "@mui/icons-material/MoreHoriz"; // Make sure to import this
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import styled from "styled-components";
import dragAndDrop from '../../../assets/images/dragAndDrop.svg'

// These constants help us simulate API behavior without actual network calls
// const SIMULATE_ERROR = true; // Toggle to test success/failure scenarios
// const SIMULATE_DELAY = 5000; // Simulates network latency in milliseconds

// Mock API function to simulate backend communication
// const saveItemOrder = async (items) => {
//   console.log("Saving new order:", items);

//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       if (SIMULATE_ERROR) {
//         console.log("API call failed");
//         reject(new Error("Failed to save order"));
//       } else {
//         console.log("API call succeeded");
//         resolve({ success: true });
//       }
//     }, SIMULATE_DELAY);
//   });
// };

// AFTER: Replace with custom hook
const useReorderItems = (initialItems, onOrderChange) => {
  // State management for items, loading state, and errors
  const [items, setItems] = useState(initialItems);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Keep reference of previous state for rollback capability
  const previousItems = useRef(items);

  const reorderItems = async (newItems) => {
    setIsLoading(true);
    previousItems.current = items;
    setItems(newItems); // Optimistic update

    try {
      await onOrderChange(newItems);
      previousItems.current = newItems;
      console.log("Reorder successful");
    } catch (error) {
      setItems(previousItems.current); // Rollback on failure
      console.log("Reorder failed, reverting to previous state");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    items,
    reorderItems,
    isLoading,
    error,
  };
};

// Drag Handle Component
const DragHandle = () => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <button
      {...attributes}
      {...listeners}
      ref={ref}
      style={{
        cursor: "grab",
        marginRight: "10px",
        background: "none",
        border: "none",
      }}
    >
      <img src={dragAndDrop} alt="Drag handle" />

    </button>
  );
};

// Sortable Item Context
const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() { },
});

// Sortable Item Component
const SortableItem = ({ headerColumns = [], children, id }) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    cursor: "grabbing !important",

    transform: CSS.Translate.toString(transform),
    transition,
    listStyle: "none",
    backgroundColor: "#fff",
    margin: "15px 0",
    borderRadius: "20px",
    display: "grid",
    gridTemplateColumns: generateGridTemplate(headerColumns.length),
    gap: "10px",
    padding: "10px 20px",
    height: "76px",
  };

  return (
    <SortableItemContext.Provider value={context}>
      <li ref={setNodeRef} style={style}>
        {children}
      </li>
    </SortableItemContext.Provider>
  );
};

// Sortable Overlay Component
const SortableOverlay = ({ children }) => {
  const dropAnimationConfig = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: "1.4",
        },
      },
    }),
  };

  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
  );
};

const ActionColumn = ({ item, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickEdit = () => {
    handleClose();
    onEdit(item);
  }

  const onClickDelete = () => {
    handleClose();
    onDelete(item);
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton
        onClick={handleMenuClick}
        sx={{
          "&:hover": {
            backgroundColor: "#F5F8FA",
            "& .MuiSvgIcon-root": {
              color: "#1976d2", // Replace with your theme color
            },
          },
          borderRadius: "8px",
        }}
      >
        <MoreHoriz
          sx={{
            color: "#666666",
            fontSize: "28px",
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ marginLeft: "-90px" }} // Adjust this value as needed
      >
        {" "}
        {onEdit && (
          <MenuItem onClick={onClickEdit}>Edit</MenuItem>
        )}
        {onDelete && (
          <MenuItem onClick={onClickDelete}>Delete</MenuItem>
        )}
      </Menu>
      <DragHandle />
    </div >
  );
};

const StyledColumn = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  color: #4D5658;
`;

const StyledColumnWithDivider = styled(StyledColumn)`
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 1px;
    background-color: #888888;
  }
`;

// For SortableItem component, modify the gridTemplateColumns style
const generateGridTemplate = (columnCount) => {
  // If there are no columns, return a simple "1fr 1fr" (for empty state + actions)
  if (columnCount === 0) return "1fr 1fr";

  // If there's only one column plus actions, return "1fr 1fr"
  if (columnCount === 1) return "1fr 1fr";

  // For 2+ columns: first column is 1fr, middle columns are 2fr, last column (actions) is 1fr
  let template = "1fr"; // First column

  // Add middle columns (all 2fr)
  for (let i = 1; i < columnCount; i++) {
    template += " 2fr";
  }

  // Add actions column
  template += " 1fr";

  return template;
};

// Main Sortable List Component
const SortableList = ({ headerColumns = [], items, onRenderItem, onChange, onRowClick, onEdit, onDelete }) => {
  const [active, setActive] = useState(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const headerStyle = {
    padding: "5px 10px", // Match the body row padding
    margin: "0 0", // Match the body row margin
    // border: "1px solid #ccc", // Match the body row border
    borderRadius: "20px", // Match the body row border radius
    backgroundColor: "#CCD7E4",
    fontWeight: "bold",
    display: "grid",
    gridTemplateColumns: generateGridTemplate(headerColumns.length),
    color: "#143664",
    fontSize: "15px",
    lineHeight: "1.15rem",
  };

  const headerColumnStyle = {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
    height: "56px",
    flexWrap: "wrap",
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => setActive(active)}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);
          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => setActive(null)}
    >
      <SortableContext items={items}>
        {/* Header */}
        <div style={headerStyle}>
          {headerColumns.map((column) => (
            <div key={column.key} style={headerColumnStyle}>
              <span dangerouslySetInnerHTML={{ __html: column.header }} />
            </div>
          ))}
          <div style={headerColumnStyle}>
            {"Actions"}
          </div>
          <div style={{ flex: 1 }}></div>{" "}
          {/* Empty space for the drag handle column */}
        </div>

        {/* Body */}
        <ul style={{ padding: 0, margin: 0 }}>
          {items.map((item,) => (
            <Fragment key={item.id}
            >
              <SortableItem headerColumns={headerColumns} id={item.id}
              >
                {headerColumns.map((column) => (
                  <StyledColumnWithDivider key={column.key}
                    onClick={(e) => onRowClick(e, item)}
                  >
                    {item[column.key]}
                  </StyledColumnWithDivider>
                ))}
                <ActionColumn
                  item={item}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              </SortableItem>
            </Fragment>
          ))}
        </ul>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? onRenderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
};

export const DragAndDropTable = memo(({
  renderConfig,
  data = [],
  dataConfig = [],
  onOrderChange,
  onEdit,
  onDelete,
  onRowClick,
}) => {

  const visibleColumns = dataConfig.filter((col) => col.visible);

  // Use our new custom hook instead of useState
  const { items, reorderItems, isLoading, error } = useReorderItems(data, onOrderChange);
  return (
    <div>
      {/* Add loading indicator */}
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#e8f4fd",
              color: "#143664",
              fontSize: "14px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="primary" size={24} />
            <p>Saving changes...</p>
          </div>
        </div>
      )}

      {/* Add error message display */}
      {error && (
        <div
          style={{
            padding: "10px",
            backgroundColor: "#ffebee",
            color: "#d32f2f",
            marginBottom: "10px",
          }}
        >
          {error}
        </div>
      )}

      <SortableList
        headerColumns={visibleColumns}
        items={items}
        onChange={reorderItems}
        onRowClick={onRowClick}
        onEdit={onEdit}
        onDelete={onDelete}
        onRenderItem={(item) => (
          <SortableItem headerColumns={visibleColumns} id={item.id}>
            {visibleColumns.map((column) => (
              <StyledColumnWithDivider key={column.key}>
                {item[column.key]}
              </StyledColumnWithDivider>
            ))}
            <ActionColumn
              item={item}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </SortableItem>
        )}
      />
    </div>
  );
});

