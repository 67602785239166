import React from "react";
import { Container } from "@mui/material";
import ContentCard from "../../../components/common/ContentCard/ContentCard";
import Dashboard from "../../../components/common/Layout/Dashboard";
import CustomButton from "../../../components/common/CustomButton";
import Stack from "@mui/material/Stack";

const Equipment = () => {
  return (
    <Dashboard transparent={true}>
      <Container
        maxWidth="300px"
        disableGutters
        sx={{
          ml: 0,
          mr: "auto",
          height: "200px",
          width: "700px",
        }}
      >
        <ContentCard title="Equipment" whiteBackground={true}>
          <Stack direction="row" flexWrap="wrap" rowGap={2} columnGap={2}>
            <CustomButton
              boldText={true}
              to={"/production/equipment/equipment-management"}
              sx={{
                padding: "10px 16px",
              }}
            >
              Equipment Management
            </CustomButton>
            <CustomButton
              boldText={true}
              to={"/production/equipment/cleaning-schedules"}
              sx={{
                padding: "10px 16px",
              }}
            >
              Cleaning Schedules
            </CustomButton>
            <CustomButton
              boldText={true}
              to={"/production/equipment/maintenance-schedules"}
              sx={{
                padding: "10px 16px",
              }}
            >
              Maintenance Schedules
            </CustomButton>
          </Stack>
        </ContentCard>
      </Container>
    </Dashboard>
  );
};

export default Equipment;
