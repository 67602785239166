import React, { useState, useEffect } from "react";
import {
  Button,
} from "@mui/material";
import CustomWideLayout from "../../../components/common/Layout/CustomWideLayout";


import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePaginatedData, useTableModal } from "../../../hooks";
import {
  productionSchedulesService
} from "../../../services";
import { ProductionPlanningModal } from "../../../components/modals/TableModals";
import dayjs from "dayjs";
import CustomDragDropTable from "../../../components/common/CustomDragDropTable";
import { toast } from "react-toastify";


const DaySchedule = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [nextSlotNumber, setNextSlotNumber] = useState(1);
  const location = useLocation();

  const { date } = useParams();
  const { Production_LineID } = location.state || {};

  const navigate = useNavigate();

  const { isOpen, isDelete, selectedId, openModal, closeModal } = useTableModal();

  const {
    data: data,
    loading,
    error,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
    setData,
  } = usePaginatedData(() => productionSchedulesService.getByDate(date));

  const handleEdit = (row) => {
    const productionScheduleID = row.Production_ScheduleID;
    openModal(productionScheduleID); // Opens modal with existing row data

  };
  const handleDelete = (row) => {
    const productionScheduleID = row.Production_ScheduleID;
    openModal(productionScheduleID, true);
  };
  const handleAdd = () => {
    openModal();
  };

  const handleProductionSlotOrderChange = async (items) => {
    const bodyData = items.map((item, index) => {
      return {
        Production_ScheduleID: item.Production_ScheduleID,
        Schedule_Order: index + 1,
      };
    });

    const body = {
      data: bodyData,
    };

    try {
      await productionSchedulesService.updateSlotOrder(body);
      changePage(0);
      toast.success("Slot reordered successfully!");
    } catch (error) {
      console.error("Failed to reorder production slot:", error);
      toast.error("Failed to reorder production slot.");
      throw error;
    }
  }

  const dataConfig = [
    {
      key: "id",
      header: "Order ID",
      visible: false,
    },
    {
      key: "Production_ScheduleID",
      header: "Schedule ID",
      visible: false,
    },
    {
      key: "Slot",
      header: "Slot",
      visible: true,
    },
    {
      key: "Production_Name",
      header: "Production Name",
      visible: true,
    },
    {
      key: "Production_Description",
      header: "Production Description",
      visible: true,
    },
    {
      key: "Recipe_Name",
      header: "Recipe/Procedure",
      visible: true,
    },
    {
      key: "Production_1200lbs_Qty",
      header: "Qty of 1200#",
      visible: true,
    },
    {
      key: "Tube_Size",
      header: "Case Size (lbs)",
      visible: true,
    },
  ];


  useEffect(() => {
    if (data) {
      const filteredData = data.filter((row) => row["Schedule_Type"] !== "PM" && row["Schedule_Type"] !== "DC");
      const slotNumber = filteredData.length + 1;
      setNextSlotNumber(slotNumber);
    }
  }, [data]);

  const tableData = data.map((row) => {
    return {
      ...row,
      id: row["Schedule_Order"],
      Schedule_Order: row["Schedule_Order"],
      Slot: `0${row["Production_Slot"]}`,
      Production_Name: row["Production_Name"],
      Production_Description: row["Production_Description"],
      Recipe_Name: row["Recipe_Name"],
      Production_1200lbs_Qty: row["Production_1200lbs_Qty"],
      Tube_Size: row['Schedule_Type'] === "MainLine" ? `${row["Tube_Size"]} lbs tube` : "",
    };
  });

  return (
    <CustomWideLayout>
      <CustomDragDropTable
        titleText={`Production for ${dayjs(date).format("DD MMM YYYY")}`}
        data={tableData}
        dataConfig={dataConfig}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onAddText="Add to Production"
        onAddClick={handleAdd}
        onOrderChange={handleProductionSlotOrderChange}
        orderKey={"id"}
        // totalItems={totalItems}
        // currentPage={currentPage}
        // totalPages={totalPages}
        onPageChange={changePage}
        // searchText={"Search Schedule"}
        // onSearch={handleSearch}
        showSearch={false}
        showPagination={false}
        backButton={true}
        backButtonText="Back"

      />
      {isOpen &&
        <ProductionPlanningModal
          open={isOpen}
          onClose={closeModal}
          productionScheduleID={selectedId}
          date={date}
          productionLineID={Production_LineID}
          nextSlotNumber={nextSlotNumber}
          setData={setData}
          isDelete={isDelete}
        />}
    </CustomWideLayout>
  );
};

export default DaySchedule;
