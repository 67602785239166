import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  Button,
  Switch,
  useTheme,
} from "@mui/material";
import ContentCard from "../../../../components/common/ContentCard/ContentCard";
import CustomButton from "../../../../components/common/CustomButton";
import CustomWideLayout from "../../../../components/common/Layout/CustomWideLayout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import CustomTextField from "../../../../components/common/CustomFormFields/CustomTextField";
import CustomTable from "../../../../components/common/CustomTable";
import CustomRecurringScheduler from "../../../../components/common/CustomRecurringScheduler";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePaginatedData, useTableModal } from "../../../../hooks";
import {
  cleaningJobsService,
  cleaningMaterialsService,
  equipmentService,
  inventoryTypesService,
  productionsService,
} from "../../../../services";
import { CleaningMaterialsModal } from "../../../../components/modals/TableModals";
import {
  CancelModal,
  ContinueModal,
} from "../../../../components/common/CustomModals";
import CustomApiDropdown from "../../../../components/common/CustomFormFields/CustomApiDropdown";
import { employeesService } from "../../../../services/employees";
import CustomApiDropdown2 from "../../../../components/common/CustomFormFields/CustomApiDropdown2";
import { CustomSelectField } from "../../../../components/common/CustomFormFields";
import { PRODUCTION_RUN_STATUS } from "../../../../utils/productionJobData";
import { COOLER_ADDED_STATUS } from "../../../../utils/productionCooler.util";
import { ProductionCoolerModal } from "../../../../components/modals/TableModals/ProductionCooler/productionCoolerModal";
import { toast } from "react-toastify";

const initialValues = {
  Production_Name: "",
};

const validationSchema = Yup.object({
  Production_Name: Yup.string().required("Production Name is required"),
});

const InventoryForProductionEdit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(initialValues);

  const location = useLocation();
  const theme = useTheme()


  const { productionID } = useParams();
  // const { equipmentID } = location.state || {};

  // const isNew = !cleaningProcedureID;
  const navigate = useNavigate();

  const { isOpen, isDelete, selectedId, openModal, closeModal } = useTableModal();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showContinueModal, setShowContinueModal] = useState(false);

  const [productions, setProductions] = useState([]);
  const [selectedProductionID, setSelectedProductionID] = useState(productionID);
  const [selectedProduction, setSelectedProduction] = useState(null);
  const [selectedRecipeName, setSelectedRecipeName] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const handleEdit = (row) => {
    console.log('🚀🚀🚀row', row, selectedProduction);
    setSelectedRecipe(row);
    openModal(row.Production_RecipeID); // Opens modal with existing row data
  };
  const handleDelete = (row) => {
    if (row.Production_Coolers.length > 0) {
      setSelectedRecipe(row);
      openModal(row.Production_RecipeID, true); // Opens modal with existing row data
    } else {
      toast.error("Please add recipe to cooler first!");
    }
  };

  const fetchRecipesByProductionID = async (productionID) => {
    try {
      const response = await productionsService.getRecipesById(productionID);
      const data = response.data;
      if (data) {
        setSelectedProduction(data.production);
        setSelectedRecipeName(data.production.ProductionSchedule.Recipe_Name);
        setRecipes(data.recipes);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await productionsService.getAll({ all: true });
        const data = response.data.data;
        if (data) {
          setProductions(data.map((item) => ({
            label: `${item.Production_Name} (${item.Production_Description})`,
            value: item.ProductionID,
          })));
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedProductionID) {
      fetchRecipesByProductionID(selectedProductionID);
    }
  }, [selectedProductionID]);

  const tableData = recipes.map((item) => ({
    ...item,
    Inventory_Name: item.Inventory.Inventory_Name,
    Inventory_Description: item.Inventory.Inventory_Description,
    Unit_Of_Measure: item.Unit_Measure,
    Quantity: item.Recipe_Qty * selectedProduction.ProductionSchedule.Production_1200lbs_Qty,
    Inventory_Type: item.Inventory.Inventory_Type.Inventory_Type,
    Storage_Method: item.Inventory.Inventory_Type.Storage_Method,
    Cooler_Schedule: item.Production_Coolers.length > 0 ? `${item.Production_Coolers[0].Cooler_Schedule} days` : '',
    STATUS: item.Production_Coolers.length > 0 ? COOLER_ADDED_STATUS.TRUE : COOLER_ADDED_STATUS.FALSE,
  }));

  const dataConfig = [
    {
      key: "Production_RecipeID",
      header: "Which table ID?",
      visible: false,
    },
    {
      key: "Inventory_Name",
      header: "Inventory Name",
      visible: true,
    },
    {
      key: "Inventory_Description",
      header: "Inventory Description",
      visible: true,
    },
    {
      key: "Unit_Of_Measure",
      header: "Unit of Measure",
      visible: true,
    },
    {
      key: "Quantity",
      header: "Quantity",
      visible: true,
    },
    {
      key: "Inventory_Type",
      header: "Inventory Type",
      visible: true,
    },
    {
      key: "Storage_Method",
      header: "Storage Method",
      visible: true,
    },
    {
      key: "Cooler_Schedule",
      header: "Cooler Schedule",
      visible: true,
    },
  ];

  const renderConfig = {
    rowColorConfig: {
      key: 'STATUS',
      colorMap: {
        [COOLER_ADDED_STATUS.TRUE]: theme.palette.septenary.tertiary,     // light green
        [COOLER_ADDED_STATUS.FALSE]: theme.palette.grey[400],     // light gray
      }
    }
  };

  return (
    <CustomWideLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ marginTop: 4, height: 200 }}>
          <ContentCard whiteBackground={true} title={"Choose Production"}>
            <Formik
              initialValues={{
                ...FormData,
                Production_Name: selectedProductionID,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, formikHelpers) => {
                // return handleSubmit(values);
              }}
              enableReinitialize={true}
            >
              {({
                isSubmitting,
                isValid,
                dirty,
                submitForm,
                setFieldValue,
                errors,
                values,
              }) => {
                return <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CustomSelectField
                        name="Production_Name"
                        label="Production Name"
                        options={productions}
                        onChange={(e) => {
                          setFieldValue('Production_Name', e.target.value);
                          setSelectedProductionID(e.target.value);
                        }}
                      />
                      {/* <CustomApiDropdown2
                        name="Production_Name"
                        label="Production Name"
                        fetchOptions={(val) => {
                          const { keyword } = val || {};
                          return productionsService.getAll({
                            keyword,
                          });
                        }}
                        valueKey="ProductionID"
                        labelKey="Production_Name"
                        showIdInLabel={false}
                        isCreateNew={false}
                        onSelectChange={setSelectedProductionID}
                      // disabled={isDelete || !values.inventoryTypeID}
                      /> */}
                    </Grid>
                  </Grid>
                </Form>
              }}
            </Formik>
          </ContentCard>
        </Grid>
      </Grid>

      <CustomTable
        titleText={selectedProduction ? `${selectedProduction?.Production_Name || ''} -  ${selectedRecipeName}` : 'Production Recipes'}
        data={tableData}
        renderConfig={renderConfig}
        dataConfig={dataConfig}
        onEdit={handleEdit}
        onDelete={handleDelete}
        totalItems={2}
        currentPage={1}
        totalPages={1}
        searchText={"Search Items"}
        showSearch={false}
        backButtonText={"Go Back"}
        backButton={true}
      />
      {selectedProduction && selectedRecipeName && isOpen && (
        <ProductionCoolerModal
          open={isOpen}
          onClose={closeModal}
          production={selectedProduction}
          recipeName={selectedRecipeName}
          recipe={selectedRecipe}
          isDelete={isDelete}
          setData={setRecipes}
        />
      )}

    </CustomWideLayout>
  );
};

export default InventoryForProductionEdit;
