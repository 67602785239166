import moment from "moment";

/**
 * Transforms production schedules into a weekly view.
 * This function is used to display production schedules in a weekly view.
 * @param {array} productionSchedules - The production schedules to transform.
 * @param {string} [date] - The date to use for determining "isToday".
 * @returns {array} - The transformed production schedules for the week.
 */
export const getProductionJobWeekly = (productionSchedules, date) => {
    // Get the current date to determine "isToday"
    const today = date ?? moment().format("YYYY-MM-DD");
    const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const weekData = weekDays.map((day, index) => {
        const dayDate = moment().startOf("isoWeek").add(index, "days").format("YYYY-MM-DD");
        const isToday = dayDate === today;

        // Filter productions for the current day
        const dailyProductions = productionSchedules.filter(
            (p) => p.Production_Date === dayDate
        );

        let transformedDay = { day, isToday };

        for (let i = 1; i <= 7; i++) {
            if (dailyProductions[i - 1]) {

                let status = "";
                const production = dailyProductions[i - 1];
                if (production.Production && production.Production.Production_Runs && production.Production.Production_Runs.length > 0) {
                    const runStatuses = production.Production.Production_Runs.map(run => run.Run_Status);


                    if (runStatuses.includes(PRODUCTION_RUN_STATUS.CANCELLED)) {
                        status = 'danger';
                    } else if (runStatuses.every(status => status === PRODUCTION_RUN_STATUS.COMPLETE) || dayDate < today) {
                        status = "done";
                    } else if (runStatuses.includes(PRODUCTION_RUN_STATUS.PROCESSING)) {
                        status = "warning";

                    } else {
                        status = "done";
                    }


                } else if (isToday) {
                    console.log("🚀🚀🚀 DayDate", production);

                    status = "success";
                } else {
                    if (dayDate < today) {
                        status = "done";
                    } else {
                        status = "";
                    }
                }

                // transformedDay[`Slot${i}Name`] = production.Production_Name;
                transformedDay[`Slot${i}Name`] = production.Recipe_Name;
                transformedDay[`Slot${i}Name-status`] = status;
                transformedDay[`Slot${i}Name-ScheduleID`] = production.Production_ScheduleID;
                transformedDay[`Slot${i}Name-ProductionID`] = production.Production.ProductionID;
                transformedDay[`Slot${i}Name-LineID`] = production.Production_LineID;

            } else {

                transformedDay[`Slot${i}Name`] = "-";
                transformedDay[`Slot${i}Name-status`] = dayDate < today ? "done" : '';
                transformedDay[`Slot${i}Name-ScheduleID`] = null;
                transformedDay[`Slot${i}Name-ProductionID`] = null;
                transformedDay[`Slot${i}Name-LineID`] = null;
            }
        }

        return transformedDay;
    });

    return weekData;
};


export const PRODUCTION_RUN_STATUS = {
    TODO: "To-Do",
    PROCESSING: "Processing",
    CANCELLED: "Cancelled",
    COMPLETE: "Complete",
    TODAY_TODO: "Today-Todo",

}