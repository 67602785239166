// components/modals/UserModal.js
import React, { useEffect, useState } from 'react';
import BaseModal from '../../baseModal';
import { TextField, Button, Box, Grid } from '@mui/material';
import {
  cleaningMaterialsService,
  equipmentService,
  unitMeasuresService

} from "../../../../../services";
import { Form, Formik } from "formik";
import CustomTextField from "../../../../common/CustomFormFields/CustomTextField";
import CustomButton from "../../../../common/CustomButton";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import CustomApiDropdown from "../../../../common/CustomFormFields/CustomApiDropdown";
import { inventoryTypesService } from "../../../../../services";

const initialFormData = {
  date: '',
};

const validationSchema = Yup.object({
  // inventoryName: Yup.string().required('Name is required'),
  // description: Yup.string().required('Description is required'),
  // unitOfMeasure: Yup.string().required('Equipment name is required'),
});

export const ProductionPlanningAddScheduleModal = ({ open, onClose, productionLineID }) => {
  const [formData, setFormData] = useState(initialFormData);

  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await postFormData(values);  // Pass values here
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const postFormData = async (values) => {  // Make async and accept values
    console.log('values:', values.date);
    navigate(
      // `/production/production-planning/${formData.date}`, { replace: true }
      `/production/production-planning/${values.date}`, {
      state: {
        Production_LineID: productionLineID,
      },
    }








    );
  };

  return (
    <BaseModal open={open} onClose={onClose} title={'Scheduling Date'}>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  type={"date"}
                  name="date"
                  label="Enter Date"
                />
              </Grid>


              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                  <Box>
                    <CustomButton
                      boldText
                      sx={{
                        mr: 2,
                      }}
                      onClick={onClose}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      boldText
                      type="submit"
                      disabled={!dirty || !isValid}
                    >
                      {'Create'}
                    </CustomButton>
                  </Box>
                </Box>
              </Grid>

            </Grid>
          </Form>
        )}
      </Formik>
    </BaseModal>
  );
};