import { useNavigate } from "react-router-dom";

import CustomWideLayout from "../../../../components/common/Layout/CustomWideLayout";
import CustomTable from "../../../../components/common/CustomTable";
import { usePaginatedData, useTableSize } from "../../../../hooks";
import { equipmentService } from "../../../../services";
import { breakLabelText } from "../../../../utils/breakLabelText";

const EquipmentManagement = () => {
  const navigate = useNavigate();
  const pageSize = useTableSize(true);

  const {
    data: equipmentData,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  } = usePaginatedData(equipmentService.getAll, { size: pageSize });

  const handleEdit = (row) => {
    navigate(`/production/equipment/equipment-management/${row.EquipmentID}`);
  };

  const handleDelete = async (row) => {
    navigate(`/production/equipment/equipment-management/${row.EquipmentID}`, {
      state: {
        isDeleteMode: true,
      },
    });
  };

  const handleAdd = () => {
    navigate(`/production/equipment/equipment-management/new`);
  };

  const dataConfig = [
    {
      key: "Name",
      header: breakLabelText("Equipment Name"),
      visible: true,
    },
    {
      key: "Equipment_BarCode",
      header: breakLabelText("Equipment Barcode"),
      visible: true,
    },
    {
      key: "Model_Number",
      header: breakLabelText("Model Number"),
      visible: true,
    },
    {
      key: "Next_Cleaning_Date",
      header: breakLabelText("Next Cleaning Date"),
      visible: true,
    },
    {
      key: "Next_Maintenance_Date",
      header: breakLabelText("Next Maintenance Date"),
      visible: true,
    },
    {
      key: "Operational_Status",
      header: breakLabelText("Operational Status"),
      visible: true,
    },
    {
      key: "InventoryID",
      header: breakLabelText("ID"),
      visible: false,
    },
  ];

  return (
    <CustomWideLayout>
      <CustomTable
        titleText={"Manage Equipment"}
        data={equipmentData}
        dataConfig={dataConfig}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onAddText="Add New Equipment"
        onAddClick={handleAdd}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={changePage}
        searchText={"Search Equipment"}
        onSearch={handleSearch}
        pageSize={pageSize}
        backButton={true}
        backButtonText="Go Back"
      />
    </CustomWideLayout>
  );
};

export default EquipmentManagement;
