import apiClient from "../api";
import { flattenObject, normalizeParams } from "./utils";

const ENDPOINTS = {
  BASE: "/maintenanceMaterials",
  DETAIL: (id) => `/maintenanceMaterials/${id}`,
};

export const maintenanceMaterialsService = {
  getAll: async (params = {}) => {
    const response = await apiClient.get(ENDPOINTS.BASE, { params });

    const responseData = response.data.data;
    const flattenedData = responseData.data.map((item) => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: {
        ...response.data.data,
        data: flattenedData,
      },
    };
    return modifiedResponse;
  },

  getById: async (id) => {
    const response = await apiClient.get(ENDPOINTS.DETAIL(id));
    const flattenedData = flattenObject(response.data.data);
    return flattenedData;
  },

  getByMaintenanceProcedureId: async (maintenanceProcedureID, params = {}) => {
    if (!maintenanceProcedureID) {
      return;
    }

    const normalizedParams = normalizeParams({
      ...params,
      MaintenanceID: maintenanceProcedureID,
    });

    return maintenanceMaterialsService.getAll(normalizedParams);
  },

  getByEquipmentId: async (equipmentId, params = {}) => {
    const searchParams = {
      ...params,
      MaintenanceID: equipmentId,
    };
    return maintenanceMaterialsService.getAll(searchParams);
  },

  delete: async (maintenanceMaterialsID, params = {}) => {
    const response = await apiClient.delete(
      ENDPOINTS.DETAIL(maintenanceMaterialsID)
    );
    return response.data;
  },

  post: async (params = {}) => {
    const response = await apiClient.post(ENDPOINTS.BASE, params);

    const responseData = response.data.data;

    if (response.data.success) {
      return flattenObject(responseData);
    } else {
      return false;
    }
  },

  put: async (maintenanceMaterialsID, params = {}) => {
    const response = await apiClient.put(
      ENDPOINTS.DETAIL(maintenanceMaterialsID),
      params
    );
    const responseData = response.data.data;

    if (response.data.success) {
      return flattenObject(responseData);
    } else {
      return false;
    }
  },
};
