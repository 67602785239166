

import ManageWarehouse from "../pages/Inventories/ManageWarehouse";
import ManageWarehouseEdit from "../pages/Inventories/ManageWarehouse/Edit";

export const WarehouseRoutes = [
  /*====================================================================================================================
                                            WAREHOUSE MANAGEMENT
 ====================================================================================================================*/
  {
    path: "/warehouse/warehouse-management",
    element: <ManageWarehouse />,
    private: true,
  },
  {
    path: "/warehouse/warehouse-management/new",
    element: <ManageWarehouseEdit />,
    private: true,
  },
  {
    path: "/warehouse/warehouse-management/:warehouseID",
    element: <ManageWarehouseEdit />,
    private: true,
  },

];