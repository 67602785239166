import { useState, useEffect, useCallback } from "react";
import { debounce } from "@mui/material";

const usePaginatedData = (fetchFunction, initialParams = {}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [totalAmount, setTotalAmounts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async (params) => {
    setLoading(true);
    try {
      const response = await fetchFunction(params);
      const responseData = response.data;
      setData(responseData.data);
      setTotalItems(responseData.totalItems);
      setTotalAmounts(responseData.totalAmount);
      setTotalPages(responseData.totalPages);
      setCurrentPage(responseData.currentPage);
      return responseData;
    } catch (err) {
      setError(err);
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(initialParams);
  }, [initialParams.size]);

  const changePage = (newPage, newSize) => {
    fetchData({ ...initialParams, page: newPage, size: newSize });
  };

  const handleSearch = useCallback(
    debounce((searchTerm) => {
      fetchData({ ...initialParams, keyword: searchTerm, page: 1 });
    }, 300),
    [initialParams]
  );

  return {
    data,
    setData, // Allows external modification of state
    loading,
    error,
    totalItems,
    setTotalItems, // Allows external modification of total items count
    totalAmount,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  };
};

export default usePaginatedData;
