// components/modals/UserModal.js
import React, { useEffect, useState } from 'react';
import BaseModal from '../../baseModal';
import { TextField, Button, Box, Grid, Alert } from '@mui/material';
import { maintenanceMaterialsService, productionRunService, productionStepsService } from "../../../../../services";
import { Form, Formik } from "formik";
import CustomTextField from "../../../../common/CustomFormFields/CustomTextField";
import CustomButton from "../../../../common/CustomButton";
import * as Yup from "yup";
import { employeesService } from '../../../../../services/employees';
import CustomApiDropdown from '../../../../common/CustomFormFields/CustomApiDropdown';
import CustomApiDropdown2 from '../../../../common/CustomFormFields/CustomApiDropdown2';
import { PRODUCTION_RUN_STATUS } from '../../../../../utils/productionJobData';
import { toast } from 'react-toastify';
import { set } from 'date-fns';
import DeleteModal from '../../../../common/CustomModals/deleteModal';
import { CustomSelectField } from '../../../../common/CustomFormFields';

const initialFormData = {
    Date: '',
    Start_Time: '',
    Production_Line: '',
    EmployeeID: '',
    Expected_Qty: 0,
    End_Time: '',
    Equipment: '',
    Equipment_Barcode: "",
    Actual_Qty: 0,
    Production_Step: "",
    Recipe_Name: "",
};

const validationSchema = Yup.object({
    // Start_Time: Yup.string().required('Start Time is required'),
    // EmployeeID: Yup.string().required('Employee ID is required'),
    // Expected_Qty: Yup.number().required('Expected Quantity is required'),
    // End_Time: Yup.string().required('End Time is required'),
    // Actual_Qty: Yup.number().required('Actual Quantity is required'),
});

export const ProductionRunModal = ({ open, onClose,
    isDelete,
    isComplete,
    date,
    productionID,
    productionStepID,
    productionRunID,
    setData,
    expectedQty,
}) => {
    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isShowSkipModal, setIsShowSkipModal] = useState(false);
    const [employees, setEmployees] = useState([]);
    // const isComplete = productionRunID ? false : true;
    useEffect(() => {
        const fetchUserData = async () => {
            if (productionStepID && productionID && open) {
                setIsLoading(true);
                setError(null);
                try {
                    const data = await productionStepsService.getById(productionStepID)
                    const stepData = data.data;

                    console.log("😍😍😍 Production Step Data", stepData);

                    setFormData({
                        Date: date,
                        Start_Time: stepData['Production_Run-Start_Time'] || '',
                        Production_Line: stepData['Production_Line-Production_Line'] || '',
                        EmployeeID: stepData['Production_Run-EmployeeID'] || '',
                        Expected_Qty: stepData['Production_Run-Expected_Qty'] || expectedQty,
                        Actual_Qty: stepData['Production_Run-Actual_Qty'] || expectedQty,
                        End_Time: stepData['Production_Run-End_Time'] || "",
                        Equipment: stepData['Equipment-Name'] || '',
                        Equipment_Barcode: stepData['Equipment-Equipment_BarCode'] || '',
                        Production_Step: stepData['Step_Process'] || '',
                        Recipe_Name: stepData['Recipe-Recipe_Name'] || '',
                    });
                } catch (err) {
                    setError('Failed to fetch user data');
                    console.error(err);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setFormData(initialFormData);
            }
        };

        fetchUserData();
    }, [productionStepID, productionID, open]);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const data = await employeesService.getAll();
                console.log("😍😍😍 Employees Data", data.data.data);
                setEmployees(
                    data.data.data.map((item) => {
                        return {
                            value: item.EmployeeID,
                            label: `${item.Full_Name}`,
                        };
                    })
                );
            } catch (error) {
                console.error('Failed to fetch employees:', error);
            }
        };

        fetchEmployees();
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            if (isDelete) {
                await skipFormData(values);
                return;
            } else if (isComplete) {
                await postFormData(values);  // Pass values here
            } else {
                await putFormData(values);   // Pass values here
            }
            onClose();
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const postFormData = async (values) => {  // Make async and accept values
        try {
            const body = {
                "Production_StepID": productionStepID,
                "ProductionID": productionID,
                "Run_Status": PRODUCTION_RUN_STATUS.COMPLETE,
                "Production_Date": date,
                "Start_Time": values.Start_Time,
                "End_Time": values.End_Time,
                "Expected_Qty": values.Expected_Qty,
                "Actual_Qty": values.Actual_Qty,
                "EmployeeID": values.EmployeeID,
            };
            const response = await productionRunService.post(body);  // Add await
            setData((prevData) => {
                return prevData.map((row) => {
                    if (row.Production_StepID === productionStepID) {
                        return {
                            ...row,
                            'Production_Run-Start_Time': values.Start_Time,
                            'Production_Run-End_Time': values.End_Time,
                            'Production_Run-Expected_Qty': values.Expected_Qty,
                            'Production_Run-Actual_Qty': values.Actual_Qty,
                            'Production_Run-EmployeeID': values.EmployeeID,
                            'Production_Run-Run_Status': PRODUCTION_RUN_STATUS.COMPLETE,
                            'Production_Run-Employee-Full_Name': employees.find((item) => item.value === values.EmployeeID).label,
                            'Production_Run-Production_RunID': response.data.Production_RunID,
                        };
                    }
                    return row;
                });
            });

            toast.success('Production Run saved successfully');


        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to save Production Run');
        }
    };

    const putFormData = async (values) => {  // Make async and accept values
        try {
            let body = {
                "Start_Time": values.Start_Time,
                "End_Time": values.End_Time,
                "Expected_Qty": values.Expected_Qty,
                "Actual_Qty": values.Actual_Qty,
                "EmployeeID": values.EmployeeID,
            };

            if (isComplete) {
                body.Run_Status = PRODUCTION_RUN_STATUS.COMPLETE;
            }
            const res = await productionRunService.put(productionRunID, body);  // Add await    
            console.log('🤣🤣🤣🤣', res.data);

            setData((prevData) => {
                return prevData.map((row) => {
                    console.log('🤣🤣🤣🤣', row.Production_StepID, productionStepID);
                    if (row.Production_StepID === productionStepID) {
                        return {
                            ...row,
                            'Production_Run-Start_Time': values.Start_Time,
                            'Production_Run-End_Time': values.End_Time,
                            'Production_Run-Expected_Qty': values.Expected_Qty,
                            'Production_Run-Actual_Qty': values.Actual_Qty,
                            'Production_Run-EmployeeID': values.EmployeeID,
                            'Production_Run-Employee-Full_Name': employees.find((item) => item.value === values.EmployeeID).label,
                        };
                    }
                    return row;
                });
            });

            toast.success('Production Run updated successfully');

        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to update Production Run');
        }
    };

    const skipFormData = async (values) => {
        setIsShowSkipModal(true);
    };

    const handleSkipStep = async () => {
        try {
            const body = {
                "Production_StepID": productionStepID,
                "ProductionID": productionID,
                "Run_Status": PRODUCTION_RUN_STATUS.CANCELLED,
                "Production_Date": date,
            };
            if (productionRunID) {
                await productionRunService.put(productionRunID, body);  // Add await
            } else {
                await productionRunService.post(body);  // Add await
            }

            setData((prevData) => {
                return prevData.map((row) => {
                    if (row.Production_StepID === productionStepID) {
                        return {
                            ...row,
                            'Production_Run-Run_Status': PRODUCTION_RUN_STATUS.CANCELLED,
                        };
                    }
                    return row;
                });
            });

            // Close Modals
            setIsShowSkipModal(false);
            onClose();

            // Show Toast Alert
            toast.success('Production Step skipped successfully');


        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to skip Production Run');
        }
    };

    const getTitle = () => {
        return isDelete ? "Skip Production Step" : (isComplete ? 'Complete Production Run' : 'Edit Production Run');
    }
    return (
        <>
            <BaseModal open={open} onClose={onClose} title={getTitle()}>
                <Formik
                    initialValues={formData}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {({ isSubmitting, isValid, dirty, values, setFieldValue }) => {
                        return (
                            <Form>
                                {isDelete ?
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="Production_Line"
                                                label="Production Line"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="Production_Step"
                                                label="Production Step"
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                                                <Box>
                                                    <CustomButton
                                                        boldText
                                                        sx={{
                                                            mr: 2,
                                                        }}
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </CustomButton>
                                                    <CustomButton
                                                        boldText
                                                        type="submit"
                                                        color={"error"}
                                                    >
                                                        Skip
                                                    </CustomButton>
                                                </Box>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                    :
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="Date"
                                                label="Date"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="Start_Time"
                                                label="Start Time"
                                                type="time"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="Production_Line"
                                                label="Production Line"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomSelectField
                                                name="EmployeeID"
                                                label="Employee"
                                                // fetchOptions={employeesService.getAll}
                                                options={employees}
                                                showIdInLabel={false}
                                                disabled={false}
                                            // onSelectChange={(value) => {
                                            //     setFieldValue("EmployeeID", value);
                                            // }}
                                            />

                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="Expected_Qty"
                                                label="Expected Qty"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="End_Time"
                                                label="End Time"
                                                type="time"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="Equipment"
                                                label="Equipment"
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="Equipment_Barcode"
                                                label="Equipment Barcode"
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CustomTextField
                                                name="Actual_Qty"
                                                label="Actual Qty"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                                                <Box>
                                                    <CustomButton
                                                        boldText
                                                        sx={{
                                                            mr: 2,
                                                        }}
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </CustomButton>
                                                    <CustomButton
                                                        boldText
                                                        type="submit"
                                                        disabled={!dirty || (isComplete && !isValid)}
                                                    >
                                                        {isComplete ? 'Complete' : 'Update'}
                                                    </CustomButton>
                                                </Box>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                }

                            </Form>
                        );

                    }}
                </Formik>

            </BaseModal>
            {isShowSkipModal && (<DeleteModal
                open={isShowSkipModal}
                onClose={() => setIsShowSkipModal(false)}
                onConfirm={handleSkipStep}
                title="Skip Step Confirmation"
                message="Are you sure you want to skip this step?"
                confirmText="Skip"
            />)}
        </>
    );
};
