import React, { useState, useEffect, useCallback, memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  PaginationItem,
  TextField,
  Button,
  styled,
  InputAdornment,
  IconButton,
  MenuList,
  MenuItem,
  ListItemIcon,
  Typography,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select, Box,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  Search,
  MoreHoriz,
} from "@mui/icons-material";
import editIcon from "../../../assets/images/editIcon.svg";
import trashIcon from "../../../assets/images/trashIcon.svg";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { CustomDropdown, CustomSelectField } from "../CustomFormFields";

const CustomTable = memo(
  ({
    renderConfig,

    titleText = "",
    onClickCell = null,

    isNew,
    showPagination = true,

    data = [],
    dataConfig = [],
    dropdownOptions = null,
    dropdownValue = null,
    dropdownOnChange = null,

    onEdit,
    onDelete,
    menuItems, // Please use this instead
    disableRowClick = false,
    disableActionsColumn = false,

    onAddClick = null,
    onAddText = null,

    showSearch = true,
    searchText = "Search...",
    onSearch,

    currentPage,
    totalPages,
    onPageChange,

    pageSize = 10,

    backButton = false,
    backButtonText = "",
  }) => {
    const theme = useTheme();

    // For popup Menu
    const [clickedRowPos, setClickedRowPos] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(null);

    const [searchValue, setSearchValue] = useState("");
    const [titleValue, setTitleValue] = useState(null)

    const visibleColumns = dataConfig.filter((col) => col.visible);

    const navigate = useNavigate();

    const handleSearchChange = useCallback(
      (e) => {
        const newValue = e.target.value;
        setSearchValue(newValue);
        onSearch(newValue);
      },
      [onSearch]
    );
    const handlePageChange = (event, newPage) => {
      onPageChange(newPage, pageSize);
    };

    /**
     * This useEffect removes the menu popup the moment you click away or scroll
     */
    useEffect(() => {
      const handleClickOutsideOrScroll = (event) => {
        if (clickedRowPos) {
          setClickedRowPos(null);
          setSelectedRow(null);
          setSelectedColumn(null)
        }
      };

      document.addEventListener("click", handleClickOutsideOrScroll);
      window.addEventListener("scroll", handleClickOutsideOrScroll);

      return () => {
        document.removeEventListener("click", handleClickOutsideOrScroll);
        window.removeEventListener("scroll", handleClickOutsideOrScroll);
      };
    }, [clickedRowPos]);

    /**
     * Show the same menu when the row is clicked.
     */
    const handleRowClick = (event, row) => {
      // If user clicked the row, not the icon
      event.stopPropagation();
      setClickedRowPos({ x: event.clientX, y: event.clientY });
      setSelectedRow(row);
      setSelectedColumn(null) // No specific column for row clicks
    };

    /**
     * Show the same menu from the action icon (MoreHoriz).
     * (stopPropagation to prevent row's onClick from firing)
     */
    const handleActionClick = (event, row) => {
      event.stopPropagation();
      const rect = event.currentTarget.getBoundingClientRect();
      // Position near the icon
      setClickedRowPos({ x: rect.left - 160, y: rect.top });
      setSelectedRow(row);
      setSelectedColumn(null); // No specific column for action clicks
    };

    const getMenuItems = useCallback(() => {
      if (menuItems) {
        return menuItems;
      }

      // Fall back to old pattern
      const defaultItems = [];
      if (onEdit) {
        defaultItems.push({
          icon: editIcon,
          label: "Edit",
          handler: onEdit,
        });
      }
      if (onDelete) {
        defaultItems.push({
          icon: trashIcon,
          label: "Delete",
          handler: onDelete,
        });
      }
      return defaultItems;
    }, [menuItems, onEdit, onDelete]);

    // ─────────────────────────────────────────────────────────────────────────────
    // Styles
    // ─────────────────────────────────────────────────────────────────────────────

    const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
      marginTop: theme.spacing(2),
      boxShadow: "none",
      backgroundColor: "transparent",
      marginBottom: "1rem",
      position: "relative",
    }));

    const StyledTable = styled(Table)(({ theme }) => ({
      borderCollapse: "separate",
      borderSpacing: "0 16px", // space between rows
    }));

    const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
      color: "#143664",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "1.15rem",
      borderWidth: 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.secondary,
      "&:first-of-type": {
        borderRadius: "20px 0 0 20px",
      },
      "&:last-child": {
        borderRadius: "0 20px 20px 0",
      },
    }));

    const StyledBodyCell = styled(TableCell)(({ theme }) => ({
      borderWidth: 0,
      padding: theme.spacing(2),
      "&:first-of-type": {
        borderRadius: "20px 0 0 20px",
      },
      "&:last-child": {
        borderRadius: "0 20px 20px 0",
      },
      "&:not(:last-child)": {
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
          height: "18px",
          width: "1px",
          backgroundColor: "#888888",
        },
      },
    }));

    const PillContent = styled("div")(({ theme, variant = "neutral" }) => {
      const variants = {
        neutral: {
          backgroundColor: theme.palette.primary.main,
          color: "#ffffff",
        },
        success: {
          backgroundColor: theme.palette.septenary.tertiary,
          color: theme.palette.primary.tertiary,
        },
        warning: {
          backgroundColor: theme.palette.senary.secondary,
          color: theme.palette.primary.tertiary,
        },
        done: {
          backgroundColor: theme.palette.primary.quaternary,
          color: "#ffffff",
        },
        danger: {
          backgroundColor: theme.palette.quaternary.secondary,
          color: "#ffffff",
        },
      };

      const variantStyles = variants[variant] || variants.neutral;

      return {
        ...variantStyles,
        borderRadius: "16px",
        padding: theme.spacing(2, 3),
        textAlign: "center",
      };
    });

    const UnderlinedBoldContent = styled("div")(({ theme }) => ({
      textDecoration: "underline",
      fontWeight: "bold",
    }));

    const StyledMenuList = styled(MenuList)(({ theme }) => ({
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      borderRadius: "12px",
      padding: "16px",
      gap: "8px",
      // boxShadow:
      //   "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      "& .MuiMenuItem-root": {
        padding: "8px",
        borderRadius: "8px",
        ...theme.typography.label1,
        color: "#2D3748",
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
      },
    }));

    const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
      backgroundColor: theme.palette.tertiary.main,
      borderRadius: "12px",
      width: "32px",
      height: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "16px",
        height: "16px",
      },
    }));

    // ─────────────────────────────────────────────────────────────────────────────
    // Rendering Helpers
    // ─────────────────────────────────────────────────────────────────────────────

    const renderCell = (column, row) => {
      const value = row[column.key];
      const renderType = column.renderType

      // Step 1: Prepare the content based on render type
      let content = value; // Default content

      switch (renderType) {
        case "date": {
          const isToday = row.isToday;
          if (isToday) {
            content = <UnderlinedBoldContent>{value}</UnderlinedBoldContent>;
            return content;
          }
          break;
        }

        case "pill": {
          const statusKey = `${column.key}-status`;
          content = (
            <PillContent variant={row[statusKey]}>
              {value}
            </PillContent>
          );
          break;
        }

        // Add other render types here as needed
      }

      // Step 2: Wrap in clickable div if needed
      if (column.clickable) {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();

              // Get position for the menu
              const rect = e.currentTarget.getBoundingClientRect();

              // Set the position and context for the popup menu
              setClickedRowPos({ x: rect.right + 10, y: rect.top });
              setSelectedRow(row);
              setSelectedColumn(column.key)
            }}
            style={{ cursor: "pointer" }}
          >
            {content}
          </div>
        );
      }


      // if (column.clickable) {
      //   console.log("I am clickable!")
      // }
      //
      // if (!useSpecialRendering) {
      //   return value;
      // }
      //
      // if (column.key === "day" && row.isToday) {
      //   return <UnderlinedBoldContent>{value}</UnderlinedBoldContent>;
      // }
      //
      // if (column.key.startsWith("Production")) {
      //   const statusKey = `${column.key}-status`;
      //   return (
      //     <PillContent
      //       variant={row[statusKey]}
      //       onClick={(e) => {
      //         e.stopPropagation(); // Prevent row click
      //         onEdit(row, column.key); // Pass both row and column
      //       }}
      //       style={{ cursor: "pointer" }}
      //     >
      //       {value}
      //     </PillContent>
      //   );
      // }

      return content;
    };

    const getRowColor = (row) => {
      if (!renderConfig?.rowColorConfig) return "white";
      const { key, colorMap } = renderConfig.rowColorConfig;
      const value = row[key];
      return colorMap[value] || "white";
    };

    // ─────────────────────────────────────────────────────────────────────────────
    // Render
    // ─────────────────────────────────────────────────────────────────────────────

    /**
     * This use effect is watching the button
     */
    useEffect(() => {
      // Function to check for text overflow and set up event handlers
      const checkTextOverflow = () => {
        const container = document.querySelector('.truncate-button-container');
        if (!container) return;

        const textElement = container.querySelector('.truncate-button-text');
        const tooltipElement = container.querySelector('.truncate-tooltip');

        if (textElement && tooltipElement) {
          // Check if text is truncated
          const isOverflowing = textElement.offsetWidth < textElement.scrollWidth;

          // Set up the event handlers
          container.onmouseenter = () => {
            if (isOverflowing) {
              tooltipElement.style.opacity = '1';
              tooltipElement.style.visibility = 'visible';
            }
          };

          container.onmouseleave = () => {
            tooltipElement.style.opacity = '0';
            tooltipElement.style.visibility = 'hidden';
          };
        }
      };

      // Run the check immediately
      checkTextOverflow();

      // Add resize listener to recheck when window size changes
      window.addEventListener('resize', checkTextOverflow);

      // Clean up
      return () => {
        const container = document.querySelector('.truncate-button-container');
        if (container) {
          container.onmouseenter = null;
          container.onmouseleave = null;
        }
        window.removeEventListener('resize', checkTextOverflow);
      };
    }, [onAddText]); // Re-run when text changes


    return (
      <>
        {/* Header Area */}
        <Grid container alignItems="center" marginBottom=".75rem" marginTop="1rem">
          {/* Left section - 1/3 width */}
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {onAddText && (
                <Box sx={{ width: "33.33%", position: "relative" }} className="truncate-button-container">
                  <Button
                    variant="contained"
                    onClick={onAddClick}
                    disabled={isNew}
                    sx={{
                      borderRadius: "12px",
                      minHeight: "48px",
                      width: "100%",
                      textTransform: "none",
                      padding: "8px 16px",
                    }}
                  >
                    <Typography
                      noWrap
                      component="span"
                      sx={{
                        width: "100%",
                        display: "block",
                        maxWidth: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      className="truncate-button-text"
                    >
                      {onAddText}
                    </Typography>
                  </Button>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "-30px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      backgroundColor: "rgba(0,0,0,0.8)",
                      color: "white",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                      zIndex: 1000,
                      opacity: 0,
                      visibility: "hidden",
                      transition: "opacity 0.2s",
                    }}
                    className="truncate-tooltip"
                  >
                    {onAddText}
                  </Box>
                </Box>
              )}

              {backButton && (
                <Button
                  variant="contained"
                  onClick={() => navigate(-1)}
                  disabled={isNew}
                  sx={{
                    borderRadius: "12px",
                    minHeight: "48px",
                    width: "33.33%",
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {backButtonText}
                </Button>
              )}

              {dropdownOptions && (
                <FormControl sx={{ width: "33.33%" }}>
                  <Select
                    value={dropdownValue}
                    onChange={dropdownOnChange}
                    displayEmpty
                    sx={{
                      width: "100%",
                      height: "48px",
                      borderRadius: "12px"
                    }}
                  >
                    {dropdownOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Grid>

          {/* Middle section - 1/3 width */}
          <Grid item xs={4}>
            <Typography
              variant="h1"
              sx={{
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "32px",
                fontWeight: 700,
                color: theme.palette.primary.main,
              }}
            >
              {titleText}
            </Typography>
          </Grid>

          {/* Right section - 1/3 width */}
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            {showSearch && (
              <TextField
                placeholder={searchText}
                value={searchValue}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ margin: 0 }}>
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "320px",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                    borderRadius: "12px",
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "16px 8px",
                    },
                  },
                }}
              />
            )}
          </Grid>
        </Grid>

        {/* Table */}
        <StyledTableContainer component={Paper}>
          <StyledTable>
            <TableHead>
              <TableRow>
                {visibleColumns.map((column) => (
                  <StyledHeaderCell key={column.key}>
                    <span dangerouslySetInnerHTML={{ __html: column.header }} />
                  </StyledHeaderCell>
                ))}
                {/* Only render Actions header if not disabled */}
                {!disableActionsColumn && <StyledHeaderCell>Actions</StyledHeaderCell>}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  // Disable row clicks if the prop disableRowClick=true is passed
                  onClick={!disableRowClick ? (e) => handleRowClick(e, row) : undefined}
                  sx={{
                    backgroundColor: getRowColor(row),
                    cursor: !disableRowClick ? "pointer" : "inherit", // Don't do a pointer if the prop disableRowClick=true
                  }}
                >
                  {visibleColumns.map((column) => (
                    <StyledBodyCell key={column.key} onClick={(e) => onClickCell ? onClickCell(e, column, row) : null}>
                      {renderCell(column, row)}
                    </StyledBodyCell>
                  ))}

                  {/* Only render Actions column if not disabled */}
                  {!disableActionsColumn && (
                    <StyledBodyCell>
                      <IconButton
                        onClick={(e) => handleActionClick(e, row)}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#F5F8FA",
                          },
                          borderRadius: "8px",
                        }}
                      >
                        <MoreHoriz
                          sx={{
                            color:
                              selectedRow === row
                                ? theme.palette.tertiary.main
                                : "#666666",
                            fontSize: "28px",
                          }}
                        />
                      </IconButton>
                    </StyledBodyCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>

        {/* Pagination */}
        <Stack
          spacing={2}
          sx={{
            "& .MuiPagination-ul": {
              margin: 0,
              padding: 0,
              "& .MuiPaginationItem-root": {
                margin: 0,
                padding: 0,
              },
            },
            marginBottom: "1rem",
          }}
        >
          {isNew ||
            !showPagination ||
            totalPages < 2 ||
            data.length < 1 ? null : (
            <Pagination
              count={totalPages}
              page={currentPage}
              siblingCount={1}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  components={{
                    previous: () => (
                      <div className="bg-BtnBg px-6 py-3 rounded-xl flex items-center gap-1 hover:bg-opacity-80 transition-all mr-auto">
                        <ArrowBack sx={{ fontSize: 12, color: "#fff" }} />
                        <span className="text-base font-normal text-BtnText">
                          Previous
                        </span>
                      </div>
                    ),
                    next: () => (
                      <div className="bg-BtnBg px-6 py-3 rounded-xl flex items-center gap-1 hover:bg-opacity-80 transition-all ml-auto">
                        <span className="text-base font-normal text-BtnText">
                          Next
                        </span>
                        <ArrowForward sx={{ fontSize: 12, color: "#fff" }} />
                      </div>
                    ),
                  }}
                />
              )}
            />
          )}
        </Stack>

        {/* Popup Menu */}
        {clickedRowPos && (
          <div
            className="fixed z-50"
            style={{
              left: clickedRowPos.x,
              top: clickedRowPos.y,
            }}
          >
            <StyledMenuList className="min-w-40 shadow-2xl">
              {getMenuItems().map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    item.handler(selectedRow, selectedColumn);
                    setClickedRowPos(null);
                  }}
                  className="flex items-center gap-2 text-lg cursor-pointer hover:bg-background rounded-xl transition-all duration-300"
                >
                  <StyledListItemIcon
                    sx={{
                      minWidth: "32px",
                    }}
                  >
                    <img src={item.icon} alt={item.label} />
                  </StyledListItemIcon>
                  {item.label}
                </div>
              ))}
            </StyledMenuList>
          </div>
        )}
      </>
    );
  }
);

export default CustomTable;
