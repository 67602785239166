// components/modals/UserModal.js
import React, { useEffect, useState } from 'react';
import BaseModal from '../baseModal';
import { TextField, Button, Box, Grid, useTheme } from '@mui/material';
import { inventoriesService, warehouseChildService, unit, warehouseChildServiceMeasuresService, unitMeasuresService, productionRecipesService } from "../../../../services";
import { Form, Formik } from "formik";
import CustomTextField from "../../../common/CustomFormFields/CustomTextField";
import CustomButton from "../../../common/CustomButton";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import CustomApiDropdown from "../../../common/CustomFormFields/CustomApiDropdown";
import dayjs from 'dayjs';
import { CustomDateField, CustomSelectField, CustomTimeField } from '../../../common/CustomFormFields';
import { toast } from "react-toastify";
import CustomPriceField from '../../../common/CustomFormFields/CustomPriceField';
import DeleteModal from '../../../common/CustomModals/deleteModal';

const initialFormData = {
  Product_Name: '',
  Recipe_Name: '',
  SKU_Number: '',
  Date_Manufactured: dayjs().format('YYYY-MM-DD'),
  Time_Manufactured: dayjs().format('HH:mm:ss'),
  Temperature: 0.0,
  Case_Size: 0,
  Unit_Qty: 0,
  Unit_Price: 0.0,
  Sub_Total: 0.0,
};

const validationSchema = Yup.object({
  Recipe_Name: Yup.string().required('Recipe name is required'),
  SKU_Number: Yup.string().required('SKU number is required'),
  Date_Manufactured: Yup.string().required('Date manufactured is required'),
  Time_Manufactured: Yup.string().required('Time manufactured is required'),
  Temperature: Yup.string().required('Temperature is required'),
  Case_Size: Yup.number().required('Case size is required'),
  Unit_Qty: Yup.number().required('Unit quantity is required'),
  Unit_Price: Yup.number().required('Unit price is required'),
});

export const WarehouseChildModal = ({ open, onClose, isDelete, setData, warehsChildID, warehouse }) => {
  const isNew = !warehsChildID;
  const [formData, setFormData] = useState(initialFormData);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [description, setDescription] = useState("")
  const [type, setType] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [measureOptions, setMeasureOptions] = useState([]);
  const [recipeOptions, setRecipeOptions] = useState([]);
  const navigate = useNavigate();

  const { warehouseID } = useParams();
  const theme = useTheme();

  useEffect(() => {
    const fetchUserData = async () => {
      if (warehsChildID && open) {
        setIsLoading(true);
        setError(null);
        try {
          const data = await warehouseChildService.getById(warehsChildID)

          setFormData({
            Recipe_Name: data.data.data['Recipe_Name'] || '',
            Date_Manufactured: data.data.data['Date_Manufactured'] || '',
            Time_Manufactured: data.data.data['Time_Manufactured'] || '',
            // SKU_Number: data.data.data['SKU_Number'] || '',
            SKU_Number: warehouse.SKU_Number || '',
            Temperature: data.data.data['Temperature'] || 0.0,
            Case_Size: data.data.data['Case_Size'] || 0,
            Unit_Qty: data.data.data['Unit_Qty'] || 0,
            Unit_Price: warehouse.Unit_Price || 0.0,
            Sub_Total: warehouse.Unit_Price * data.data.data['Unit_Qty'] || 0.0,
          });
          // handleInventoryChange(warehsChildID)

        } catch (err) {
          setError('Failed to fetch user data');
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      } else {
        setFormData(initialFormData);
      }
    };

    fetchUserData();

  }, [warehsChildID, open]);

  useEffect(() => {
    if (warehouse.Product_Name) {
      setFormData({
        ...formData,
        Product_Name: warehouse.Product_Name,
        Unit_Price: warehouse.Unit_Price,
        SKU_Number: warehouse.SKU_Number,
      })
    }
  }, [warehouse]);

  useEffect(() => {
    fetchUnitMeasure().then((data) => setMeasureOptions(data));
    fetchProductionRecipes().then((data) => setRecipeOptions(data));
  }, []);


  const fetchUnitMeasure = async () => {
    try {
      const response = await unitMeasuresService.getAll();
      const data = response.data.data;
      return data.map((item) => {
        return {
          value: item.Case_Size,
          label: item.UM_Description,
        };
      });
    } catch (error) {
      console.error("Failed to fetch unit measure:", error);
      toast.error("Failed to fetch unit measure.");
    }
  };

  const fetchProductionRecipes = async () => {
    try {
      const response = await productionRecipesService.getGroupedAll();
      const data = response.data.data;
      return data.map((item) => {
        return {
          value: item.Recipe_Name,
          label: item.Recipe_Name,
        };
      });
    } catch (error) {
      console.error("Failed to fetch unit measure:", error);
      toast.error("Failed to fetch unit measure.");
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (isNew) {
        await postFormData(values);
      } else {
        await putFormData(values);
      }
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const postFormData = async (values) => {
    try {
      const body = {
        WarehouseID: warehouseID,
        Case_Size: values.Case_Size,
        Unit_MeasureID: warehouse.Unit_MeasureID,
        Recipe_Name: values.Recipe_Name,
        SKU_Number: values.SKU_Number,
        Date_Manufactured: values.Date_Manufactured,
        Time_Manufactured: values.Time_Manufactured,
        Temperature: values.Temperature,
        Unit_Qty: values.Unit_Qty,

        Unit_Price: new Intl.NumberFormat("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(values.Unit_Price), Case_Size: values.Case_Size,
      };
      const response = await warehouseChildService.post(body);

      if (response) {
        setData((prev) => [...prev,
        {
          ...response.data,
          "Warehouse-Product_Name": warehouse.Product_Name,
          "Unit_Measure-UM_Description": response.data.Unit_Measure.UM_Description,
          Unit_Price: `$${warehouse.Unit_Price}`,
        }
        ]);
      }
      toast.success("Product added successfully");
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
      throw error;
    }

  };

  const putFormData = async (values) => {
    try {
      const body = {
        WarehouseID: warehouseID,
        Case_Size: values.Case_Size,
        Unit_MeasureID: warehouse.Unit_MeasureID,
        Recipe_Name: values.Recipe_Name,
        SKU_Number: values.SKU_Number,
        Date_Manufactured: values.Date_Manufactured,
        Time_Manufactured: values.Time_Manufactured,
        Temperature: values.Temperature,
        Unit_Qty: values.Unit_Qty,
        Unit_Price: new Intl.NumberFormat("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(values.Unit_Price), Case_Size: values.Case_Size,
      };

      const response = await warehouseChildService.put(
        warehsChildID,
        body
      );
      console.log("🚀🚀🚀response", response.data);

      if (response) {
        setData((prev) =>
          prev.map((item) =>
            item.Warehs_ChildID === warehsChildID ? {
              ...response.data,
              "Warehouse-Product_Name": warehouse.Product_Name,
              "Unit_Measure-UM_Description": response.data.Unit_Measure.UM_Description,
              Unit_Price: `$${warehouse.Unit_Price}`,
            } : item
          )
        );
      }

      toast.success("Product updated successfully");
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("Failed to update product");
      throw error;
    }

  };

  const handleDeleteMaterial = async () => {
    try {
      await warehouseChildService.delete(warehsChildID);
      setData((prevData) =>
        prevData.filter(
          (item) => item.Warehs_ChildID !== warehsChildID
        )
      );
      setShowDeleteModal(false);
      onClose();

      toast.success("Product deleted successfully");

    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("Failed to delete product");
    }

  };


  // const handleInventoryChange = async (value) => {
  //   try {
  //     const response = await inventoriesService.getById(value);
  //     const data = response.data
  //     setDescription(data.Inventory_Description)
  //     setType(data['Inventory_Type-Inventory_Type'])
  //   } catch (error) {
  //     console.error('Error fetching inventory details:', error);
  //   }
  // };

  return (
    <>
      <BaseModal open={open} onClose={onClose} title={isDelete ? 'Delete Product' : (isNew ? 'Add Product' : 'Edit Product')}
        sx={{ height: 'auto', maxHeight: '90vh', overflowY: 'auto' }}
      >
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, isValid, dirty, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomTextField
                    name="Product_Name"
                    label="Product Name"
                    disabled={true}
                    options={measureOptions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomSelectField
                    options={recipeOptions}
                    name="Recipe_Name"
                    label="Recipe Name"
                    disabled={isDelete}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="SKU_Number"
                    label="SKU Number"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomDateField
                    name="Date_Manufactured"
                    label="Production Date"
                    disabled={isDelete}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTimeField
                    name="Time_Manufactured"
                    label="Production Time"
                    disabled={isDelete}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="Temperature"
                    label="Temperature (°C)"
                    disabled={isDelete}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    label="Case Size"
                    name="Case_Size"
                    disabled={isDelete}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="Unit_Qty"
                    label="In Stock"
                    disabled={isDelete}
                    onChange={(e) => {
                      setFieldValue("Unit_Qty", parseFloat(e.target.value) || 0);
                      setFieldValue("Sub_Total", parseFloat(e.target.value) * warehouse.Unit_Price);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomPriceField
                    name="Unit_Price"
                    label="Case Price"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomPriceField
                    name="Sub_Total"
                    label="Sub Total Price"
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                    <Box>
                      <CustomButton sx={{ mr: 2 }} onClick={onClose}>
                        Cancel
                      </CustomButton>
                      {isDelete ? (
                        <CustomButton
                          boldText
                          onClick={() => setShowDeleteModal(true)}
                          sx={{
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.error.contrastText,
                          }}
                        >
                          Delete
                        </CustomButton>
                      ) : (
                        <CustomButton
                          type="submit"
                          disabled={!dirty || (isNew && !isValid)}
                          loading={isSubmitting || undefined}
                        >
                          {isNew ? "Create" : "Update"}
                        </CustomButton>
                      )}
                    </Box>
                  </Box>
                </Grid>

              </Grid>
            </Form>
          )}
        </Formik>
      </BaseModal>
      {!isNew && showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteMaterial}
        />
      )}
    </>
  );
};
