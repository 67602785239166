import apiClient from "../api";
import { flattenObject, normalizeParams } from "./utils";

const ENDPOINTS = {
  BASE: "/cleaningMaterials",
  DETAIL: (id) => `/cleaningMaterials/${id}`,
};

export const cleaningMaterialsService = {
  getAll: async (params = {}) => {
    const response = await apiClient.get(ENDPOINTS.BASE, { params });

    const responseData = response.data.data;
    const flattenedData = responseData.data.map((item) => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: {
        ...response.data.data,
        data: flattenedData,
      },
    };
    return modifiedResponse;
  },

  getById: async (id) => {
    const response = await apiClient.get(ENDPOINTS.DETAIL(id));
    const flattenedData = flattenObject(response.data.data);
    return flattenedData;
  },

  getByCleaningProcedureId: async (cleaningProcedureID, params = {}) => {
    if (!cleaningProcedureID) {
      return;
    }

    const normalizedParams = normalizeParams({
      ...params,
      CleaningID: cleaningProcedureID,
    });

    return cleaningMaterialsService.getAll(normalizedParams);
  },

  getByEquipmentId: async (equipmentId, params = {}) => {
    const searchParams = {
      ...params,
      CleaningID: equipmentId,
    };
    return cleaningMaterialsService.getAll(searchParams);
  },

  delete: async (cleaningMaterialsID, params = {}) => {
    const response = await apiClient.delete(
      ENDPOINTS.DETAIL(cleaningMaterialsID)
    );
    return response.data;
  },

  post: async (params = {}) => {
    const response = await apiClient.post(ENDPOINTS.BASE, params);

    const responseData = response.data.data;

    if (response.data.success) {
      return flattenObject(responseData);
    } else {
      return false;
    }
  },

  put: async (cleaningMaterialsID, params = {}) => {
    const response = await apiClient.put(
      ENDPOINTS.DETAIL(cleaningMaterialsID),
      params
    );
    const responseData = response.data.data;

    if (response.data.success) {
      return flattenObject(responseData);
    } else {
      return false;
    }
  },
};
