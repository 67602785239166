import apiClient from "../api";

const ENDPOINTS = {
  BASE: "/equipmentTypes",
  DETAIL: (id) => `/equipmentTypes/${id}`,
};

export const equipmentTypesService = {
  getAll: async (params = {}) => {
    const response = await apiClient.get(ENDPOINTS.BASE, { params });
    return response.data;
  },
  createNew: async (payload = {}) => {
    const response = await apiClient.post(ENDPOINTS.BASE, payload);
    return response.data;
  },
};
