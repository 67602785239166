import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import BaseModal from "./../baseModal";
import {
  maintenanceMaterialsService,
  inventoriesService,
  inventoryTypesService,
} from "../../../../services";
import CustomTextField from "../../../common/CustomFormFields/CustomTextField";
import CustomApiDropdown from "../../../common/CustomFormFields/CustomApiDropdown";
import CustomButton from "../../../common/CustomButton";
import CustomPriceField from "../../../common/CustomFormFields/CustomPriceField";
import DeleteModal from "../../../common/CustomModals/deleteModal";
import CustomApiDropdown2 from "../../../common/CustomFormFields/CustomApiDropdown2";

const initialFormData = {
  inventoryID: "",
  description: "",
  inventoryTypeID: "",
  quantityUsed: "",
  unitPrice: "",
  unit: "lbs",
};

const validationSchema = Yup.object({
  inventoryID: Yup.string().required("Inventory is required"),
  inventoryTypeID: Yup.string().required("Inventory Type is required"),
  quantityUsed: Yup.number().required("Used Quantity is required"),
});

export const MaintenanceMaterialsModal = ({
  open,
  onClose,
  maintenanceMaterialsID,
  setData,
  setTotalItems,
  isDelete = false,
}) => {
  const isNew = !maintenanceMaterialsID;
  const [formData, setFormData] = useState(initialFormData);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { maintenanceProcedureID } = useParams();
  const theme = useTheme();

  useEffect(() => {
    const fetchUserData = async () => {
      if (maintenanceMaterialsID && open) {
        try {
          const response = await maintenanceMaterialsService.getById(
            maintenanceMaterialsID
          );
          setFormData({
            inventoryID: response["Inventory-InventoryID"] || "",
            inventoryTypeID:
              response["Inventory-Inventory_Type-Inventory_TypeID"] || "",
            quantityUsed: response["Qty_Used"] || 0,
            unitPrice: response["Unit_Price"] || 0,
            unit: response["Unit_Measure"] || "lbs",
            description: response["Inventory-Inventory_Description"] || "",
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        setFormData(initialFormData);
      }
    };

    fetchUserData();
  }, [maintenanceMaterialsID, open]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (isNew) {
        await postFormData(values);
      } else {
        await putFormData(values);
      }
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const postFormData = async (values) => {
    const body = {
      MaintenanceID: maintenanceProcedureID,
      InventoryID: values.inventoryID,
      Qty_Used: values.quantityUsed,
      Unit_Measure: values.unit,
      Unit_Price: new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(values.unitPrice),
    };
    const response = await maintenanceMaterialsService.post(body);
    if (response) {
      setData((prev) => [...prev, response]);
      setTotalItems((prev) => prev - 1);
    }
  };

  const putFormData = async (values) => {
    const body = {
      InventoryID: values.inventoryID,
      Qty_Used: values.quantityUsed,
      Unit_Measure: values.unit,
      Unit_Price: new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(values.unitPrice),
    };
    const response = await maintenanceMaterialsService.put(
      maintenanceMaterialsID,
      body
    );
    if (response) {
      setData((prev) =>
        prev.map((item) =>
          item.Maintenance_MaterialsID === maintenanceMaterialsID
            ? response
            : item
        )
      );
    }
  };

  const handleDeleteMaterial = async () => {
    await maintenanceMaterialsService.delete(maintenanceMaterialsID);
    setData((prevData) =>
      prevData.filter(
        (item) => item.Maintenance_MaterialsID !== maintenanceMaterialsID
      )
    );
    setTotalItems((prev) => prev - 1);
    setShowDeleteModal(false);
    onClose();
  };

  return (
    <>
      <BaseModal
        open={open}
        onClose={onClose}
        title={isNew ? "Add Supplies" : "Edit Supplies"}
      >
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, isValid, dirty, values, setFieldValue }) => {
            const handleInventoryTypeChange = async (value) => {
              setFieldValue("inventoryTypeID", value);
              setFieldValue("inventoryID", "");
              setFieldValue("description", "");
              setFieldValue("unit", "lbs");
              setFieldValue("unitPrice", 0);
              setFieldValue("quantityUsed", "");
            };

            const handleInventoryChange = async (value) => {
              try {
                const response = await inventoriesService.getById(value);
                const data = response.data;

                if (
                  data.Inventory_Children &&
                  data.Inventory_Children.length > 0
                ) {
                  const sortedChildren = data.Inventory_Children.sort(
                    (a, b) => {
                      return (
                        new Date(a.Date_Received) - new Date(b.Date_Received)
                      );
                    }
                  );

                  const earliestChild = sortedChildren[0];
                  setFieldValue("unitPrice", earliestChild.Unit_Price);
                  if (
                    earliestChild.Answered_Questions &&
                    earliestChild.Answered_Questions.length > 0
                  ) {
                    const containerQuestion =
                      earliestChild.Answered_Questions.find(
                        (item) => item.Product_Question.Question === "container"
                      );
                    setFieldValue(
                      "unit",
                      containerQuestion && containerQuestion.Answer === "flat"
                        ? "flats"
                        : "lbs"
                    );
                  } else {
                    setFieldValue("unit", "lbs");
                  }
                }
                setFieldValue("description", data.Inventory_Description || "");
                setFieldValue("inventoryID", value);
                setFieldValue("quantityUsed", "");
              } catch (error) {
                console.error("Error fetching inventory details:", error);
              }
            };

            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomApiDropdown
                      name={"inventoryTypeID"}
                      label={"Inventory Type"}
                      fetchOptions={inventoryTypesService.getAll}
                      valueKey={"Inventory_TypeID"}
                      labelKey={"Inventory_Type"}
                      showIdInLabel={false}
                      onSelectChange={handleInventoryTypeChange}
                      disabled={isDelete}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomApiDropdown2
                      name={"inventoryID"}
                      label={"Inventory Name"}
                      fetchOptions={(val) => {
                        const { keyword } = val || {};
                        return inventoriesService.getAll({
                          inventory_type: values.inventoryTypeID,
                          keyword,
                        });
                      }}
                      valueKey={"InventoryID"}
                      labelKey={"Name"}
                      showIdInLabel={false}
                      onSelectChange={handleInventoryChange}
                      disabled={isDelete || !values.inventoryTypeID}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="description"
                      label="Description"
                      disabled
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="quantityUsed"
                      label="Quantity Used"
                      placeholder="0"
                      disabled={isDelete}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="unit"
                      label="Unit Measure"
                      disabled
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomPriceField
                      name="unitPrice"
                      label="Unit Price"
                      disabled
                      required={false}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                      <CustomButton sx={{ mr: 2 }} onClick={onClose}>
                        Cancel
                      </CustomButton>
                      {isDelete ? (
                        <CustomButton
                          boldText
                          onClick={() => setShowDeleteModal(true)}
                          sx={{
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.error.contrastText,
                          }}
                        >
                          Delete
                        </CustomButton>
                      ) : (
                        <CustomButton
                          type="submit"
                          disabled={!dirty || (isNew && !isValid)}
                          loading={isSubmitting || undefined}
                        >
                          {isNew ? "Create" : "Update"}
                        </CustomButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </BaseModal>
      {!isNew && showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteMaterial}
        />
      )}
    </>
  );
};
