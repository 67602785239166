import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ContentCard from "../../../../../components/common/ContentCard/ContentCard";
import CustomApiDropdown from "../../../../../components/common/CustomFormFields/CustomApiDropdown";
import CustomButton from "../../../../../components/common/CustomButton";
import CustomTable from "../../../../../components/common/CustomTable";
import CustomTextField from "../../../../../components/common/CustomFormFields/CustomTextField";
import CustomWideLayout from "../../../../../components/common/Layout/CustomWideLayout";

import {
  equipmentService,
  maintenanceJobsService,
} from "../../../../../services";
import {
  CancelModal,
  ContinueModal,
} from "../../../../../components/common/CustomModals";
import { usePaginatedData } from "../../../../../hooks";
import { maintenanceProceduresService } from "../../../../../services/maintenanceProcedures";
import DeleteModal from "../../../../../components/common/CustomModals/deleteModal";
import { breakLabelText } from "../../../../../utils/breakLabelText";
import { DragAndDropTable, MiniCalendarTable } from "../../../../../components/common/Tables";

const initialData = {
  id: "",
  name: "",
  description: "",
  equipmentID: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  equipmentID: Yup.string().required("Equipment name is required"),
});

const MainForm = ({
  isNew,
  isDeleteMode,
  equipmentID,
  showDeleteModal,
  setShowDeleteModal,
  showContinueModal,
  setShowContinueModal,
  showCancelModal,
  setShowCancelModal,
  handleCancel,
  handleDeleteMaintenanceJob,
  formikSubmitRef,
  formikResetRef,
  submitForm,
  resetForm,
  setIsFormDirty,
  isValid,
  isSubmitting,
  dirty,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Save Formik functions in refs for later use
  formikSubmitRef.current = submitForm;
  formikResetRef.current = resetForm;

  useEffect(() => {
    setIsFormDirty(dirty);
  }, [dirty, setIsFormDirty]);

  return (
    <Form
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: "350px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField name="name" label="Name" disabled={isDeleteMode} />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="description"
            label="Description"
            disabled={isDeleteMode}
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomApiDropdown
            name={"equipmentID"}
            label={"Equipment Name"}
            fetchOptions={equipmentService.getAll}
            valueKey={"EquipmentID"}
            labelKey={"Name"}
            showIdInLabel={false}
            disabled={isDeleteMode || equipmentID}
            required={!isDeleteMode && !equipmentID}
          />
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1 }} />

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <CustomButton
            boldText
            sx={{ mr: 2 }}
            onClick={() => handleCancel(dirty)}
          >
            {dirty ? "Cancel" : "Go Back"}
          </CustomButton>

          {isDeleteMode ? (
            <CustomButton
              boldText
              onClick={() => setShowDeleteModal(true)}
              sx={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
              }}
            >
              Delete
            </CustomButton>
          ) : (
            <CustomButton
              boldText
              onClick={() => setShowContinueModal(true)}
              disabled={isSubmitting || !dirty || (isNew && !isValid)}
            >
              {isNew ? "Create" : "Update"}
            </CustomButton>
          )}
        </Box>

        <CancelModal
          open={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onConfirm={() => {
            setShowCancelModal(false);
            navigate(-1);
          }}
        />

        <ContinueModal
          open={showContinueModal}
          onClose={() => setShowContinueModal(false)}
          onConfirm={() => {
            setShowContinueModal(false);
            submitForm();
          }}
        />

        {isDeleteMode && showDeleteModal && (
          <DeleteModal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={handleDeleteMaintenanceJob}
          />
        )}
      </Grid>
    </Form>
  );
};

const MaintenanceJobsEdit = () => {
  const { maintenanceJobID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { equipmentID, isDeleteMode } = location.state || {};

  const isNew = !maintenanceJobID;

  const pageTitle = isNew
    ? "Create New Maintenance Schedule"
    : isDeleteMode
      ? "Delete Maintenance Schedule"
      : "Edit Maintenance Schedule";

  const [formData, setFormData] = useState(initialData);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // ─── NEW STATE & REFS FOR UNSAVED CHANGES ─────────────────────────────
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const formikSubmitRef = useRef(null);
  const formikResetRef = useRef(null);
  // ────────────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (isNew) {
      if (equipmentID)
        setFormData({ ...initialData, equipmentID: equipmentID });

      return;
    }

    const fetchData = async () => {
      try {
        const response = await maintenanceJobsService.getById(maintenanceJobID);
        const data = response.data;
        setFormData({
          id: data.Maintenance_JobID,
          name: data.Maint_Name,
          description: data.Maint_Description,
          equipmentID: data["Equipment-EquipmentID"],
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
        toast.error("Failed to fetch data");
      }
    };

    fetchData();
  }, [equipmentID, isNew, maintenanceJobID]);

  const handlePost = async (values) => {
    try {
      const body = {
        EquipmentID: values.equipmentID,
        Maint_Name: values.name,
        Maint_Description: values.description,
      };
      const response = await maintenanceJobsService.post(body);
      const newId = response.data.Maintenance_JobID;
      navigate(`/production/equipment/maintenance-schedules/${newId}`, { replace: true });
      toast.success("Maintenance schedule created successfully!");
    } catch (error) {
      console.error("Failed to create:", error);
      toast.error("Failed to create maintenance schedule.");
    }
  };

  const handlePut = async (values) => {
    try {
      const body = {
        EquipmentID: values.equipmentID,
        Maint_Name: values.name,
        Maint_Description: values.description,
      };
      const { data: updatedData } = await maintenanceJobsService.put(
        maintenanceJobID,
        body
      );
      setFormData({
        id: updatedData.Maintenance_JobID,
        name: updatedData.Maint_Name,
        description: updatedData.Maint_Description,
        equipmentID: updatedData.EquipmentID,
      });
      toast.success("Maintenance schedule updated successfully!");
    } catch (error) {
      console.error("Failed to create:", error);
      toast.error("Failed to update maintenance schedule.");
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (isNew) {
        await handlePost(values);
      } else {
        await handlePut(values);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form.");
    }
  };

  const handleCancel = (dirty) => {
    if (dirty) {
      setShowCancelModal(true);
    } else {
      navigate(-1);
    }
  };

  const handleDeleteMaintenanceJob = async () => {
    try {
      await maintenanceJobsService.delete(maintenanceJobID);
      setShowDeleteModal(false);
      navigate(-1);
      toast.success("Maintenance schedule deleted successfully!");
    } catch (error) {
      console.error("Failed to delete maintenance schedule:", error);
      toast.error("Failed to delete maintenance schedule.");
    }
  };

  // ─── Wrap navigation actions to check for unsaved changes ────────────────
  const handleNavigationAction = (action) => {
    if (isFormDirty) {
      setPendingNavigation(() => action);
      setShowUnsavedModal(true);
    } else {
      action();
    }
  };
  // ────────────────────────────────────────────────────────────────────────

  // ─── Updated Maintenance Procedure Actions ──────────────────────────────────
  const handleAddMaintenanceProcedure = () => {
    handleNavigationAction(() => {
      navigate(
        `/production/equipment/maintenance-schedules/${maintenanceJobID}/maintenance-procedure/new`,
        {
          state: { equipmentID: formData.equipmentID },
        }
      );
    });
  };

  const handleEditMaintenanceProcedure = (row) => {
    handleNavigationAction(() => {
      navigate(
        `/production/equipment/maintenance-schedules/${maintenanceJobID}/maintenance-procedure/${row.MaintenanceID}/`
      );
    });
  };

  const handleDeleteMaintenanceProcedure = (row) => {
    handleNavigationAction(() => {
      navigate(
        `/production/equipment/maintenance-schedules/${maintenanceJobID}/maintenance-procedure/${row.MaintenanceID}/`,
        {
          state: {
            isDeleteMode: true,
          },
        }
      );
    });
  };

  const {
    data,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  } = usePaginatedData((params) =>
    maintenanceProceduresService.getByMaintenanceJobId(maintenanceJobID, params)
  );

  // ─── Functions for Unsaved Changes Modal Actions ───────────────────────
  const handleSaveAndNavigate = async () => {
    if (formikSubmitRef.current) {
      // Calling submitForm returns a promise
      await formikSubmitRef.current();
      if (pendingNavigation) {
        pendingNavigation();
        setPendingNavigation(null);
      }
      setShowUnsavedModal(false);
    }
  };

  const handleDiscardAndNavigate = () => {
    if (formikResetRef.current) {
      // Reset the form to the saved formData
      formikResetRef.current(formData);
    }
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
    setShowUnsavedModal(false);
  };

  const cancelNavigation = () => {
    setShowUnsavedModal(false);
    setPendingNavigation(null);
  };
  // ────────────────────────────────────────────────────────────────────────

  const dataConfig = [
    {
      key: "Procedure",
      header: breakLabelText("Procedure Name"),
      visible: true,
    },
    {
      key: "Description",
      header: breakLabelText("Procedure Description"),
      visible: true,
    },
    {
      key: "Employees-Full_Name",
      header: breakLabelText("Employee"),
      visible: true,
    },
    {
      key: "nextScheduleDate",
      header: breakLabelText("Next Cleaning Date"),
      visible: true,
    },
    {
      key: "nextScheduleTime",
      header: breakLabelText("Next Cleaning Time"),
      visible: true,
    },
  ];

  // ─────────────────────── Get Calendar Data ────────────────────────────────
  const calendarData = data.map((item) => {
    return {
      Label: item['Procedure'],
      ScheduleID: item['Schedule_Table-ScheduleID'],
      ScheduleType: item['Schedule_Table-ScheduleType'],
      Notify: item['Schedule_Table-Notify'],
      StartDate: item['Schedule_Table-StartDate'],
      StartTime: item['Schedule_Table-StartTime'],
      TimeOfDay: item['Schedule_Table-TimeOfDay'],
      DayOfWeek: item['Schedule_Table-DayOfWeek'],
      DayOfMonth: item['Schedule_Table-DayOfMonth'],
      Month: item['Schedule_Table-Month'],
      RepeatInterval: item['Schedule_Table-RepeatInterval']
    };
  });

  return (
    <CustomWideLayout>
      {/* ─── Unsaved Changes Modal ───────────────────────────── */}
      <Dialog open={showUnsavedModal} onClose={cancelNavigation}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent sx={{ padding: "0 24px" }}>
          <Typography>
            You have unsaved changes. Would you like to save them before
            proceeding?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px 24px",
          }}
        >
          <CustomButton onClick={handleSaveAndNavigate}>
            Save Changes
          </CustomButton>
          <CustomButton onClick={handleDiscardAndNavigate}>
            Discard Changes
          </CustomButton>
          <CustomButton onClick={cancelNavigation}>Cancel</CustomButton>
        </DialogActions>
      </Dialog>
      {/* ───────────────────────────────────────────────────────── */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
          <ContentCard whiteBackground={true} title={pageTitle}>
            <Formik
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting, isValid, dirty, submitForm, resetForm }) => (
                <MainForm
                  isNew={isNew}
                  isDeleteMode={isDeleteMode}
                  equipmentID={equipmentID}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  showContinueModal={showContinueModal}
                  setShowContinueModal={setShowContinueModal}
                  showCancelModal={showCancelModal}
                  setShowCancelModal={setShowCancelModal}
                  handleCancel={handleCancel}
                  handleDeleteMaintenanceJob={handleDeleteMaintenanceJob}
                  formikSubmitRef={formikSubmitRef}
                  formikResetRef={formikResetRef}
                  submitForm={submitForm}
                  resetForm={resetForm}
                  setIsFormDirty={setIsFormDirty}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  dirty={dirty}
                />
              )}
            </Formik>
          </ContentCard>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
          <ContentCard whiteBackground={true} title="Schedule Calendar">
            <MiniCalendarTable
              data={calendarData}
            ></MiniCalendarTable>
          </ContentCard>
        </Grid>
      </Grid>

      {!isNew && !isDeleteMode && (
        <Box position="relative">
          <CustomTable
            titleText={"Maintenance Procedures"}
            isNew={isNew}
            data={data}
            dataConfig={dataConfig}
            onEdit={handleEditMaintenanceProcedure}
            onDelete={handleDeleteMaintenanceProcedure}
            onAddText="Add New Maintenance Procedure"
            onAddClick={handleAddMaintenanceProcedure}
            totalItems={totalItems}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={changePage}
            searchText={"Search Maintenance Procedures"}
            onSearch={handleSearch}
          />
        </Box>
      )}
    </CustomWideLayout>
  );
};

export default MaintenanceJobsEdit;
