import { useState, useEffect, useCallback } from "react";

const useTableSize = (autoResize = false, minRows = 1, maxRows = 20) => {
  const calculateSize = useCallback(() => {
    if (!autoResize) return 10;
    const rowHeight = 110;
    const availableHeight = window.innerHeight - 350;
    let possibleRows = Math.floor(availableHeight / rowHeight);
    return Math.max(minRows, Math.min(possibleRows, maxRows));
  }, [autoResize, maxRows, minRows]);

  const [pageSize, setPageSize] = useState(calculateSize);

  useEffect(() => {
    if (!autoResize) return;

    const handleResize = () => {
      setPageSize(calculateSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [autoResize, calculateSize]);

  return pageSize;
};

export default useTableSize;
