import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import {
  startOfWeek,
  addDays,
  subWeeks,
  addWeeks,
  format,
  isSameDay,
  eachDayOfInterval,
  isToday,
  differenceInDays,
} from 'date-fns';
import { parseISO, parse } from 'date-fns';
import { generateCalendarData } from '../../../utils/getCalendarData';

const CustomCellDate = ({ date, referenceDate }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      ...(isSameDay(date, referenceDate) && {
        backgroundColor: '#ff4433',
        color: 'white',
        borderRadius: '4px',
      }),
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '8px',
        height: '8px',
      }}
    >
      {format(date, 'd')}
    </div>
  </Box>
);

const getDotsColor = (events, startIndex, endIndex) => {
  const relevantEvents = events.slice(startIndex, endIndex);
  const referenceDate = new Date(Date.UTC(2025, 11, 28, 8)); // Same as in Calendar component

  // If all events are completed, use grey
  if (relevantEvents.every((event) => event.completed)) {
    return '#666';
  }

  // Priority logic for incomplete tasks
  let hasOverdueTasks = false;
  let hasLateWarningTasks = false;

  for (const event of relevantEvents) {
    if (!event.completed) {
      const eventDate = new Date(event.date);
      const daysDiff = differenceInDays(referenceDate, eventDate);

      if (daysDiff > 3) {
        hasOverdueTasks = true;
        break; // Highest priority, no need to check further
      } else if (daysDiff > 0) {
        hasLateWarningTasks = true;
      }
    }
  }

  if (hasOverdueTasks) return '#ff4433'; // Red for severely overdue
  if (hasLateWarningTasks) return '#ffb366'; // Yellow for recently overdue
  return '#666'; // Grey for future tasks or completed tasks
};

const CustomCellDot = ({ events, startIndex, endIndex, color }) => {
  const relevantEvents = events.slice(startIndex, endIndex);

  return relevantEvents.length > 0 ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: color,
        }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: 'transparent',
        }}
      />
    </Box>
  );
};

const CustomDay = ({ date, referenceDate, events }) => {
  const eventsForDate =
    events?.filter((event) => {
      const eventDate = parse(event.date, 'yyyy-MM-dd', new Date());
      return isSameDay(eventDate, date);
    }) || [];

  const numberOfEvents = eventsForDate.length;

  const getTooltipContent = () => {
    return eventsForDate
      .map(
        (event, index) =>
          `${index + 1}. ${event.label} (${event.completed ? 'Completed' : 'Pending'
          })`
      )
      .join('\n');
  };

  const getDotColor = (startIndex, endIndex) => {
    const relevantEvents = eventsForDate.slice(startIndex, endIndex);
    if (!relevantEvents.length) return 'transparent';

    if (relevantEvents.every((event) => event.completed)) {
      return '#666';
    }

    const today = referenceDate; // Using reference date as "today"
    let hasOverdueTasks = false;
    let hasLateWarningTasks = false;

    for (const event of relevantEvents) {
      if (!event.completed) {
        const eventDate = parse(event.date, 'yyyy-MM-dd', new Date());
        const daysDiff = differenceInDays(today, eventDate);

        if (daysDiff > 3) {
          hasOverdueTasks = true;
          break;
        } else if (daysDiff > 0) {
          hasLateWarningTasks = true;
        }
      }
    }

    if (hasOverdueTasks) return '#ff4433';
    if (hasLateWarningTasks) return '#ffb366';
    return '#666';
  };

  const DayContent = (
    <Box
      sx={{
        flex: 1,
        aspectRatio: '1/1',
        margin: '2px',
        backgroundColor: 'white',
        transition: 'all 0.2s ease',
        '&:hover': {
          backgroundColor: '#f5f5f5',
          boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        },
      }}
    >
      <Grid container spacing={0.25} sx={{ height: '100%' }}>
        {/* First Column */}
        <Grid size={4}>
          <CustomCellDate date={date} referenceDate={referenceDate} />
        </Grid>
        <Grid size={4}>
          <CustomCellDot
            events={eventsForDate}
            startIndex={2}
            endIndex={3}
            color={getDotColor(2, 3)}
          />
        </Grid>
        <Grid size={4}>
          <CustomCellDot
            events={eventsForDate}
            startIndex={5}
            endIndex={6}
            color={getDotColor(5, 6)}
          />
        </Grid>

        {/* Second Column */}
        <Grid size={4}>
          <CustomCellDot
            events={eventsForDate}
            startIndex={0}
            endIndex={1}
            color={getDotColor(0, 1)}
          />
        </Grid>
        <Grid size={4}>
          <CustomCellDot
            events={eventsForDate}
            startIndex={3}
            endIndex={4}
            color={getDotColor(3, 4)}
          />
        </Grid>
        <Grid size={4}>
          <CustomCellDot
            events={eventsForDate}
            startIndex={6}
            endIndex={7}
            color={getDotColor(6, 7)}
          />
        </Grid>

        {/* Third Column */}
        <Grid size={4}>
          <CustomCellDot
            events={eventsForDate}
            startIndex={1}
            endIndex={2}
            color={getDotColor(1, 2)}
          />
        </Grid>
        <Grid size={4}>
          <CustomCellDot
            events={eventsForDate}
            startIndex={4}
            endIndex={5}
            color={getDotColor(4, 5)}
          />
        </Grid>
        <Grid size={4}>
          <CustomCellDot
            events={eventsForDate}
            startIndex={7}
            endIndex={8}
            color={getDotColor(7, 8)}
          />
        </Grid>
      </Grid>
    </Box>
  );

  return numberOfEvents > 0 ? (
    <Tooltip
      title={getTooltipContent()}
      placement="top"
      disableInteractive={true}
      componentsProps={{
        tooltip: {
          sx: {
            whiteSpace: 'pre-line',
            maxWidth: 300,
          },
        },
      }}
    >
      {DayContent}
    </Tooltip>
  ) : (
    DayContent
  );
};

const DayOfWeekHeaders = () => {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return (
    <Box sx={{ display: 'flex', gap: '4px' }}>
      {daysOfWeek.map((day) => (
        <Box
          key={day}
          sx={{
            flex: 1,
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '2px',
            fontWeight: 'bold',
          }}
        >
          {day}
        </Box>
      ))}
    </Box>
  );
};

// Calculation functions
const calculateLastWeekStartDay = (referenceDate) => {
  return startOfWeek(subWeeks(referenceDate, 1), { weekStartsOn: 1 });
};

const calculateCurrentWeekStartDay = (referenceDate) => {
  return startOfWeek(referenceDate, { weekStartsOn: 1 });
};

const calculateNextWeekStartDay = (referenceDate, weekOffset) => {
  return startOfWeek(addWeeks(referenceDate, weekOffset + 1), {
    weekStartsOn: 1,
  });
};

const WeekRow = ({ startDate, referenceDate, events }) => {
  const days = eachDayOfInterval({
    start: startDate,
    end: addDays(startDate, 6),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        borderTop: '1px solid #ccc',
      }}
    >
      {days.map((date) => (
        <CustomDay
          key={date.toISOString()}
          date={date}
          referenceDate={referenceDate}
          events={events}
        />
      ))}
    </Box>
  );
};

const LastWeekRow = ({ referenceDate, events }) => (
  <WeekRow
    startDate={calculateLastWeekStartDay(referenceDate)}
    referenceDate={referenceDate}
    events={events}
  />
);

const CurrentWeekRow = ({ referenceDate, events }) => (
  <WeekRow
    startDate={calculateCurrentWeekStartDay(referenceDate)}
    referenceDate={referenceDate}
    events={events}
  />
);

const NextWeekRow = ({ referenceDate, weekOffset, events }) => (
  <WeekRow
    startDate={calculateNextWeekStartDay(referenceDate, weekOffset)}
    referenceDate={referenceDate}
    events={events}
  />
);

const CalendarHeader = ({ referenceDate }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        fontSize: '1.2rem',
      }}
    >
      <span style={{ fontWeight: 'bold' }}>
        {format(referenceDate, 'MMMM')}
      </span>
      <span style={{ marginLeft: '4px' }}>{format(referenceDate, 'yyyy')}</span>
    </Box>
  );
};

export const MiniCalendarTable = ({ data }) => {
  const [referenceDate, setReferenceDate] = React.useState(
    new Date(Date.now())
  );
  const startDate = format(calculateLastWeekStartDay(referenceDate), 'yyyy-MM-dd');
  const endDate = addDays(calculateLastWeekStartDay(referenceDate), 35);
  const newEndDate = format(endDate, 'yyyy-MM-dd');

  const [calendarData, setCalendarData] = React.useState([]);

  useEffect(() => {
    const sampleData = generateCalendarData(data, startDate, newEndDate);
    setCalendarData(sampleData);
  }, [data, startDate, newEndDate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <CalendarHeader referenceDate={referenceDate} />

      <DayOfWeekHeaders />
      <LastWeekRow referenceDate={referenceDate} events={calendarData} />
      <CurrentWeekRow referenceDate={referenceDate} events={calendarData} />
      <NextWeekRow
        referenceDate={referenceDate}
        weekOffset={0}
        events={calendarData}
      />
      <NextWeekRow
        referenceDate={referenceDate}
        weekOffset={1}
        events={calendarData}
      />
      <NextWeekRow
        referenceDate={referenceDate}
        weekOffset={2}
        events={calendarData}
      />
    </Box>
  );
};
