import { useNavigate } from 'react-router-dom';

import CustomWideLayout from "../../../components/common/Layout/CustomWideLayout";
import CustomTable from "../../../components/common/CustomTable";
import { usePaginatedData } from "../../../hooks";
import { cleaningJobsService, equipmentService, productionSchedulesService } from "../../../services";
import exclamationIcon from "../../../assets/images/exclamationIcon.svg";
import skipIcon from "../../../assets/images/skipIcon.svg";
import checkIcon from "../../../assets/images/checkIcon.svg";
import dayjs from 'dayjs';
import { getProductionJobWeekly, PRODUCTION_RUN_STATUS } from '../../../utils/productionJobData';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import moment from 'moment';

const ManageProductionJobs = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [title, setTitle] = useState("");

  const today = moment().format('YYYY-MM-DD');
  const {
    data: data,
    loading,
    error,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
    testSearch
  } = usePaginatedData(() => productionSchedulesService.getByWeekly(today));

  console.log("🚀 ~ file: index.js ~ line 116 ~ ManageProductionJobs ~ data", data)

  const handleEdit = (row) => {
    navigate(`/production/equipment/equipment-management/${row.EquipmentID}`);
  };
  const handleSkip = async (row) => {
    try {
      await equipmentService.delete(row.EquipmentID);
      navigate(0);
    } catch (error) {
      console.error('Failed to delete:', error);
    }
  };

  const handleAdd = (row) => {
    navigate("/production/manage-production-jobs/production-job");
  }

  const handleClickCell = (e, column, row) => {
    const lineID = row[`${column.key}-LineID`];
    const scheduleID = row[`${column.key}-ScheduleID`];
    const productionID = row[`${column.key}-ProductionID`];
    if (lineID) {
      navigate(`/production/manage-production-jobs/production-job/${lineID}`, {
        state: {
          Production_ScheduleID: scheduleID,
          productionID: productionID,
        }
      });
    } else {
      toast.error('No schedule found for this slot');
    }
  }

  const dataConfig = [
    {
      key: 'day',
      header: 'Day',
      visible: true,
      renderType: 'date'
    },
    {
      key: 'Slot1Name',
      header: 'Slot 1',
      visible: true,
      renderType: 'pill'
    },
    {
      key: 'Slot2Name',
      header: 'Slot 2',
      visible: true,
      renderType: 'pill'
    },
    {
      key: 'Slot3Name',
      header: 'Slot 3',
      visible: true,
      renderType: 'pill'
    },
    {
      key: 'Slot4Name',
      header: 'Slot 4',
      visible: true,
      renderType: 'pill'
    },
    {
      key: 'Slot5Name',
      header: 'Slot 5',
      visible: true,
      renderType: 'pill'
    },
    {
      key: 'Slot6Name',
      header: 'Slot 6',
      visible: true,
      renderType: 'pill'
    },
    {
      key: 'Slot7Name',
      header: 'Slot 7',
      visible: true,
      renderType: 'pill'
    },
  ];

  const tableData = getProductionJobWeekly(data, today);

  useEffect(() => {
    if (today) {
      const firstDayOfMonth = moment(today);

      // Get the start of the month
      const startOfMonth = firstDayOfMonth.clone().startOf('month');

      // Calculate the week number of the month
      const weekNumber = Math.ceil(firstDayOfMonth.diff(startOfMonth, 'days') / 7);
      const month = firstDayOfMonth.format('MM');
      const year = firstDayOfMonth.format('YYYY');
      const weekText = weekNumber <= 2 ? "st" : (weekNumber == 2 ? "nd" : (weekNumber == 3 ? "rd" : "th"));
      const weekTitle = `${weekNumber}${weekText} Week ${month}/${year}`;
      setTitle(weekTitle);
    }
  }, [today]);

  return (
    <>
      <CustomWideLayout>
        <CustomTable
          useSpecialRendering={true}
          // renderConfig={renderConfig}
          titleText={title}

          data={tableData}
          dataConfig={dataConfig}

          isShowActions={false}

          onAddText="Current Production Running"
          onAddClick={handleAdd}

          totalItems={totalItems}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={changePage}

          searchText={"Search Date"}
          onSearch={handleSearch}

          showPagination={false}
          onClickCell={handleClickCell}
          disableRowClick={true}
          disableActionsColumn={true}
        />

      </CustomWideLayout>
    </>
  );
};

export default ManageProductionJobs;