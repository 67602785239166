import React from "react";
import logo from "../../assets/images/logo.png";

const HomePage = () => {
  return (
    <div className="flex-1 flex justify-center items-center bg-white">
      <div className="flex flex-col items-center gap-6">
        <img src={logo} alt="Logo" className="h-12 w-96 bg-white" />
        <p className="text-xl font-semibold">
          Texas Tripe Inventory Management System (IMS)
        </p>
      </div>
    </div>
  );
};

export default HomePage;
