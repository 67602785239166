import React from "react";
import { useNavigate } from "react-router-dom";

import CustomWideLayout from "../../../../components/common/Layout/CustomWideLayout";
import CustomTable from "../../../../components/common/CustomTable";
import { usePaginatedData, useTableSize } from "../../../../hooks";
import { productionRecipesService } from "../../../../services";
import { breakLabelText } from "../../../../utils/breakLabelText";

const ProductionRecipes = () => {
  const navigate = useNavigate();
  const pageSize = useTableSize(true);

  const {
    data,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  } = usePaginatedData(productionRecipesService.getGroupedAll, { size: pageSize });

  const handleEdit = (row) => {
    navigate(
      `/production/product-assembly/recipe-management/${row.Recipe_Slug}`
    );
  };

  const handleDelete = async (row) => {
    navigate(`/production/product-assembly/recipe-management/${row.Recipe_Slug}`, {
      state: {
        isDeleteMode: true,
      },
    });
    // try {
    //   await productionRecipesService.deleteBySlug(row.Recipe_Slug);
    //   navigate(0);
    // } catch (error) {
    //   console.error("Failed to delete:", error);
    // }
  };

  const handleAdd = (row) => {
    navigate(`/production/product-assembly/recipe-management/new`);
  };

  const dataConfig = [
    {
      key: "Recipe_Name",
      header: breakLabelText("Recipe Name"),
      visible: true,
    },
    {
      key: "Recipe_Description",
      header: breakLabelText("Recipe Description"),
      visible: true,
    },
    {
      key: "items",
      header: breakLabelText("Item Quantity"),
      visible: true,
    },
    {
      key: "weight",
      header: breakLabelText("Recipe Weight"),
      visible: true,
    },
  ];

  return (
    <CustomWideLayout>
      <CustomTable
        titleText={"Recipes"}
        data={data}
        dataConfig={dataConfig}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onAddText="Add New Recipe"
        onAddClick={handleAdd}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={changePage}
        searchText={"Search Recipe"}
        onSearch={handleSearch}
        pageSize={pageSize}
        backButton={true}
        backButtonText="Go Back"
      />
    </CustomWideLayout>
  );
};

export default ProductionRecipes;
