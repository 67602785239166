// components/modals/UserModal.js
import React, { useEffect, useState } from 'react';
import BaseModal from '../../baseModal';
import { TextField, Button, Box, Grid, Typography, useTheme } from '@mui/material';
import { Form, Formik } from "formik";
import CustomTextField from "../../../../common/CustomFormFields/CustomTextField";
import CustomButton from "../../../../common/CustomButton";
import * as Yup from "yup";
import DropdownField from "../../../../common/DropdownField";
import { productionSchedulesService } from '../../../../../services/productionSchedules';
import { Schedule, Warehouse } from '@mui/icons-material';
import { CustomSelectField } from '../../../../common/CustomFormFields';
import { productionLinesService, productionRecipesService, warehouseService } from '../../../../../services';
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import DeleteModal from '../../../../common/CustomModals/deleteModal';

const initialFormData = {
  Production_Date: '',
  Recipe_Name: '',
  Production_Name: '',
  Production_Description: '',
  Production_Slot: '',
  Production_1200lbs_Qty: '',
  Tube_Size: '',
  Warehouse_SKU: '',
};

const validationSchema = Yup.object({
  Production_Date: Yup.string().required('Date is required'),
  Recipe_Name: Yup.string().required('Recipe name is required'),
  Production_Name: Yup.string().required('Equipment name is required'),
  Production_Description: Yup.string().required('Description is required'),
  Production_Slot: Yup.string().required('Slot is required'),
  Production_1200lbs_Qty: Yup.string().required('Quantity is required'),
  Tube_Size: Yup.string().required('Tube size is required'),
  Warehouse_SKU: Yup.string().required('Warehouse SKU is required'),
});

const validationPMSchema = Yup.object({
  Production_Date: Yup.string().required('Date is required'),
  Production_Slot: Yup.string().required('Slot is required'),
});

const validationDCSchema = Yup.object({
  Production_Date: Yup.string().required('Date is required'),
  Production_Slot: Yup.string().required('Slot is required'),
});



export const ProductionPlanningModal = ({
  open, onClose,
  isDelete = false,
  setData,
  date,
  nextSlotNumber,
  productionScheduleID,
  productionLineID,
}) => {

  const isNew = !productionScheduleID;
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [scheduleType, setScheduleType] = useState('')
  const [recipeOptions, setRecipeOptions] = useState([]);
  const [tubeSizes, setTubeSizes] = useState([]);
  const [qty1200Options, setQty1200] = useState([]);
  const [slotOptions, setSlotOptions] = useState([]);
  const [title, setTitle] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productionLine, setProductionLine] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    setTubeSizes(
      [
        {
          value: 2,
          label: "2",
        },
        {
          value: 5,
          label: "5",
        }
      ]
    );

    setQty1200(Array.from({ length: 10 }, (_, index) => ({
      value: index + 1,
      label: (index + 1).toString(),
    })));

    setSlotOptions(Array.from({ length: 7 }, (_, index) => ({
      value: index + 1,
      label: (index + 1).toString(),
    })));


    const fetchScheduleData = async () => {
      if (productionScheduleID && open) {
        setIsLoading(true);
        setError(null);
        try {
          const data = await productionSchedulesService.getById(productionScheduleID)
          setFormData({
            Production_Date: date,
            Recipe_Name: data.data.data['Recipe_Name'] || '',
            Production_Name: data.data.data['Production_Name'] || `${productionLine ? productionLine.Production_Line : ''}`,
            Production_Description: data.data.data['Production_Description'] || '',
            Production_Slot: data.data.data['Production_Slot'] || nextSlotNumber,
            Production_1200lbs_Qty: data.data.data['Production_1200lbs_Qty'] || '',  //|| qty1200Options[0].value,
            Tube_Size: data.data.data['Tube_Size'] || '', //tubeSizes[0].value,
            Warehouse_SKU: data.data.data['Warehouse_SKU'] || '',
          });

          setScheduleType(data.data.data['Schedule_Type']);
        } catch (err) {
          setError('Failed to fetch user data');
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      } else {
        setFormData({
          ...initialFormData,
          Production_Date: date,
          Production_Slot: nextSlotNumber || 1,
          Production_Name: `${productionLine ? productionLine.Production_Line : ''} `,
          // Tube_Size: tubeSizes[0].value || '',
          // Production_1200lbs_Qty: qty1200Options[0].value || '',
        });
      }
    };

    fetchScheduleData();
  }, [productionScheduleID, open]);


  useEffect(() => {
    fetchProductionRecipes().then((data) => setRecipeOptions(data));
  }, []);

  useEffect(() => {
    const fetchProductionLineData = async () => {
      try {
        const response = await productionLinesService.getById(productionLineID);
        const data = response.data;
        setProductionLine(data);
        setFormData(
          (prevData) => ({
            ...prevData,
            Production_Name: `${data.Production_Line || ''} `,
          })
        )

      } catch (error) {
        console.error('Failed to fetch production line:', error);
      }
    };

    fetchProductionLineData();
  }, [productionLineID]);

  const fetchProductionRecipes = async () => {
    try {
      const response = await productionRecipesService.getGroupedAll();
      const data = response.data.data;
      return data.map((item) => {
        return {
          value: item.Recipe_Name,
          label: item.Recipe_Name,
        };
      });
    } catch (error) {
      console.error("Failed to fetch production recipe:", error);
      toast.error("Failed to fetch production recipe.");
    }
  };

  useEffect(() => {
    const fetchWarehouseData = async () => {
      try {
        const response = await warehouseService.getAll({ all: true });
        const data = response.data.data;
        setWarehouseOptions(data.map((item) => {
          return {
            value: item.Warehouse_SKU_Number,
            label: `${item.Product_Name} (${item.Warehouse_SKU_Number})`,
          };
        }));
      } catch (error) {
        console.error('Failed to fetch warehouse:', error);
      }
    };

    fetchWarehouseData();
  }, []);

  const handleModalClose = () => {
    setScheduleType('');  // Reset title
    onClose();                  // Call the original onClose
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (isNew) {
        await postFormData(values);  // Pass values here
      } else {
        await putFormData(values);   // Pass values here
      }
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const postFormData = async (values) => {  // Make async and accept values
    console.log('🚀🚀🚀values:', values, scheduleType);
    if (scheduleType === 'MainLine') {
      await postMainLineData(values);
    } else if (scheduleType === 'PM') {
      await postPreventativeMaintenanceData(values);
    } else if (scheduleType === 'DC') {
      await postDailyCleaningData(values);
    }
  };

  const postMainLineData = async (values) => {  // Make async and accept values
    try {
      const body = {
        Production_Date: date,
        Production_LineID: productionLineID,
        Recipe_Name: values.Recipe_Name,
        Production_Name: values.Production_Name,
        Production_Description: values.Production_Description,
        Warehouse_SKU: values.Warehouse_SKU,
        Production_Slot: values.Production_Slot,
        Production_1200lbs_Qty: values.Production_1200lbs_Qty,
        Tube_Size: values.Tube_Size,
        Schedule_Type: scheduleType,
      };
      const response = await productionSchedulesService.post(body);  // Add await

      console.log('😍😍😍response:', response);
      if (response) {
        setData((prevData) => [...prevData,
        response.data
        ]);
      }

      toast.success("Production schedule created successfully.");
    } catch (error) {
      console.error('Error creating production schedule:', error.response.data.errorMessage);
      toast.error(error.response.data.errorMessage || "Failed to create production schedule.");
      throw error;
    }
  };


  const postPreventativeMaintenanceData = async (values) => {  // Make async and accept values
    try {
      const body = {
        Production_Date: date,
        Production_LineID: productionLineID,
        Recipe_Name: values.Recipe_Name,
        // Production_Name: values.Production_Name,
        Production_Description: values.Production_Description,
        // Warehouse_SKU: values.Warehouse_SKU,
        Production_Slot: values.Production_Slot,
        // Production_1200lbs_Qty: values.Production_1200lbs_Qty,
        // Tube_Size: values.Tube_Size,
        Schedule_Type: scheduleType,
      };
      const response = await productionSchedulesService.post(body);  // Add await

      console.log('😍😍😍response:', response);
      if (response) {
        setData((prevData) => [...prevData,
        response.data
        ]);
      }

      toast.success("Production schedule created successfully.");
    } catch (error) {
      console.error('Error creating production schedule:', error.response.data.errorMessage);
      toast.error(error.response.data.errorMessage || "Failed to create production schedule.");
      throw error;
    }
  };

  const postDailyCleaningData = async (values) => {  // Make async and accept values
    try {
      const body = {
        Production_Date: date,
        Production_LineID: productionLineID,
        Recipe_Name: values.Recipe_Name,
        // Production_Name: values.Production_Name,
        Production_Description: values.Production_Description,
        // Warehouse_SKU: values.Warehouse_SKU,
        Production_Slot: values.Production_Slot,
        // Production_1200lbs_Qty: values.Production_1200lbs_Qty,
        // Tube_Size: values.Tube_Size,
        Schedule_Type: scheduleType,
      };
      const response = await productionSchedulesService.post(body);  // Add await

      console.log('😍😍😍response:', response);
      if (response) {
        setData((prevData) => [...prevData,
        response.data
        ]);
      }

      toast.success("Production schedule created successfully.");
    } catch (error) {
      console.error('Error creating production schedule:', error.response.data.errorMessage);
      toast.error(error.response.data.errorMessage || "Failed to create production schedule.");
      throw error;
    }
  };

  const putFormData = async (values) => {  // Make async and accept values
    if (scheduleType === 'MainLine') {
      await putMainLineData(values);
    } else if (scheduleType === 'PM') {
      await putPreventativeMaintenanceData(values);
    } else if (scheduleType === 'DC') {
      await putDailyCleaningData(values);
    }
  };

  const putMainLineData = async (values) => {  // Make async and accept values
    try {
      const body = {
        Production_Date: values.Production_Date,
        Production_LineID: productionLineID,
        Recipe_Name: values.Recipe_Name,
        Production_Name: values.Production_Name,
        Production_Description: values.Production_Description,
        Warehouse_SKU: values.Warehouse_SKU,
        Production_Slot: values.Production_Slot,
        Production_1200lbs_Qty: values.Production_1200lbs_Qty,
        Tube_Size: values.Tube_Size,
        Schedule_Type: scheduleType,
      };
      await productionSchedulesService.put(productionScheduleID, body);  // Add await
      toast.success("Production schedule updated successfully.");

      setData((prevData) => {
        const index = prevData.findIndex((item) => item.Production_ScheduleID === productionScheduleID);
        if (index === -1) {
          return prevData;
        }
        const newData = [...prevData];
        newData[index] = {
          ...body,
          Production_ScheduleID: productionScheduleID,
        };
        return newData;
      });
    } catch (error) {
      console.error('Error updating production schedule:', error);
      toast.error("Failed to update production schedule.");
      throw error
    }
  };


  const putPreventativeMaintenanceData = async (values) => {  // Make async and accept values
    try {
      const body = {
        Production_Date: values.Production_Date,
        Production_LineID: productionLineID,
        Recipe_Name: values.Recipe_Name,
        // Production_Name: values.Production_Name,
        Production_Description: values.Production_Description,
        // Warehouse_SKU: values.Warehouse_SKU,
        Production_Slot: values.Production_Slot,
        // Production_1200lbs_Qty: values.Production_1200lbs_Qty,
        // Tube_Size: values.Tube_Size,
        Schedule_Type: scheduleType,
      };
      await productionSchedulesService.put(productionScheduleID, body);  // Add await
      toast.success("Production schedule updated successfully.");

      setData((prevData) => {
        const index = prevData.findIndex((item) => item.Production_ScheduleID === productionScheduleID);
        if (index === -1) {
          return prevData;
        }
        const newData = [...prevData];
        newData[index] = {
          ...body,
          Production_ScheduleID: productionScheduleID,
        };
        return newData;
      });
    } catch (error) {
      console.error('Error updating production schedule:', error);
      toast.error("Failed to update production schedule.");
      throw error
    }
  };

  const putDailyCleaningData = async (values) => {  // Make async and accept values
    try {
      const body = {
        Production_Date: values.Production_Date,
        Production_LineID: productionLineID,
        Recipe_Name: values.Recipe_Name,
        // Production_Name: values.Production_Name,
        Production_Description: values.Production_Description,
        // Warehouse_SKU: values.Warehouse_SKU,
        Production_Slot: values.Production_Slot,
        // Production_1200lbs_Qty: values.Production_1200lbs_Qty,
        // Tube_Size: values.Tube_Size,
        Schedule_Type: scheduleType,
      };
      await productionSchedulesService.put(productionScheduleID, body);  // Add await
      toast.success("Production schedule updated successfully.");

      setData((prevData) => {
        const index = prevData.findIndex((item) => item.Production_ScheduleID === productionScheduleID);
        if (index === -1) {
          return prevData;
        }
        const newData = [...prevData];
        newData[index] = {
          ...body,
          Production_ScheduleID: productionScheduleID,
        };
        return newData;
      });
    } catch (error) {
      console.error('Error updating production schedule:', error);
      toast.error("Failed to update production schedule.");
      throw error
    }
  };


  const handleDeleteData = async () => {
    try {
      await productionSchedulesService.delete(productionScheduleID);
      setData((prevData) => prevData.filter((item) => item.Production_ScheduleID !== productionScheduleID));
      setShowDeleteModal(false);
      onClose();
      toast.success("Production schedule deleted successfully.");
    } catch (error) {
      console.error('Error deleting production schedule:', error);
      toast.error("Failed to delete production schedule.");
    }
  }

  const dialogTitle =
    isDelete ? "Delete Production Schedule" :
      (scheduleType === 'DC' ? 'Daily Cleaning' :
        (scheduleType === 'PM' ? 'Preventative Maintenance' :
          title || "Production Planning"));

  return (
    <>
      <BaseModal open={open} onClose={handleModalClose} title={dialogTitle}>
        <Formik
          initialValues={formData}
          validationSchema={
            scheduleType === 'DC' ? validationDCSchema :
              (scheduleType === 'PM' ? validationPMSchema :
                validationSchema)
          }
          onSubmit={handleSubmit}
          enableReinitialize={true}

        >
          {({ isSubmitting, isValid, dirty, values, setFieldValue }) => (
            <Form>
              {!scheduleType && isNew ? (

                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                  <CustomButton boldText onClick={() => {
                    // setFieldValue('Production_Description', 'Main Production')
                    const desc = `${productionLine.Production_Line || ''} - ${dayjs(date).format('DD MMM YYYY')} - ${values.Production_Slot} - ${values.Recipe_Name}`;
                    setFieldValue('Production_Description', desc);
                    setScheduleType("MainLine")
                  }}>
                    Producing Main Product
                  </CustomButton>
                  <CustomButton boldText onClick={() => {
                    setFieldValue('Production_Description', 'Maintenace')
                    setFieldValue('Recipe_Name', 'PM')
                    setScheduleType("PM")
                  }}>Preventative Maintenance</CustomButton>
                  <CustomButton boldText onClick={() => {
                    setFieldValue('Production_Description', 'Daily Cleaning')
                    setFieldValue('Recipe_Name', 'DC')
                    setScheduleType("DC")
                  }}>Daily Cleaning</CustomButton>
                </Box>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomTextField
                      name="Production_Date"
                      label="Date"
                      type="date"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomSelectField
                      options={slotOptions}
                      name="Production_Slot"
                      label="Slot Number"
                      disabled={isDelete}
                    />
                  </Grid>

                  {scheduleType === 'MainLine' && (
                    <>
                      <Grid item xs={12}>
                        <CustomSelectField
                          options={warehouseOptions}
                          name="Warehouse_SKU"
                          label="Warehouse SKU"
                          disabled={isDelete}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSelectField
                          options={recipeOptions}
                          name="Recipe_Name"
                          label="Recipe Name"
                          onChange={(e) => {
                            // const value = `${dayjs(date).format('DD MMM YYYY')} - ${values.Production_Slot} - ${e.target.value}`;
                            const desc = `${productionLine ? productionLine.Production_Line : ''} - ${dayjs(date).format('DD MMM YYYY')} - ${values.Production_Slot} - ${e.target.value}`;
                            setFieldValue('Production_Description', desc);
                            setFieldValue('Recipe_Name', e.target.value);
                          }}
                          disabled={isDelete}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name="Production_Name"
                          label="Production Name"
                          disabled={false}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <CustomTextField
                          name="Production_Description"
                          label="Production Description"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSelectField
                          options={qty1200Options}
                          name="Production_1200lbs_Qty"
                          label="Qty of 1200#"
                          disabled={isDelete}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSelectField
                          options={tubeSizes}
                          name="Tube_Size"
                          label="Tube Size"
                          disabled={isDelete}
                        />
                      </Grid>
                    </>
                  )}

                  {scheduleType === 'PM' && (
                    <>
                      <Grid item xs={12}>
                        <CustomTextField
                          name="Production_Description"
                          label="Production Step"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name="Recipe_Name"
                          label="Maintenance Procedure"
                          disabled={true}
                        />
                      </Grid>
                    </>
                  )}

                  {scheduleType === 'DC' && (
                    <>
                      <Grid item xs={12}>
                        <CustomTextField
                          name="Production_Description"
                          label="Production Step"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name="Recipe_Name"
                          label="Cleaning Procedure"
                          disabled={true}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                      <Box>
                        <CustomButton sx={{ mr: 2 }} onClick={onClose}>
                          Cancel
                        </CustomButton>
                        {isDelete ? (
                          <CustomButton
                            boldText
                            onClick={() => setShowDeleteModal(true)}
                            sx={{
                              backgroundColor: theme.palette.error.main,
                              color: theme.palette.error.contrastText,
                            }}
                          >
                            Delete
                          </CustomButton>
                        ) : (
                          <CustomButton
                            type="submit"
                            disabled={!dirty || (isNew && !isValid)}
                            loading={isSubmitting || undefined}
                          >
                            {isNew ? "Create" : "Update"}
                          </CustomButton>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}


            </Form>
          )}
        </Formik>
      </BaseModal >

      {!isNew && showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteData}
        />
      )
      }
    </>
  );
};
