// components/modals/UserModal.js
import React, { useEffect, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import BaseModal from "./../baseModal";
import {
  inventoriesService,
  inventoryTypesService,
  productionRecipesService,
} from "../../../../services";
import CustomTextField from "../../../common/CustomFormFields/CustomTextField";
import CustomButton from "../../../common/CustomButton";
import CustomApiDropdown from "../../../common/CustomFormFields/CustomApiDropdown";
import DeleteModal from "../../../common/CustomModals/deleteModal";
import { qty1200lbsCalculator } from "../../../../utils/qtyCalculator";
import CustomApiDropdown2 from "../../../common/CustomFormFields/CustomApiDropdown2";
import { toast } from "react-toastify";

const initialFormData = {
  inventoryId: "",
  inventoryName: "",
  description: "",
  inventoryTypeID: "",
  inventoryType: "",
  unitOfMeasure: "lbs",
  recipeQty: 0,
  storageMethod: "",
  coolerSchedule: 0,
};

const validationSchema = Yup.object({
  // inventoryId: Yup.string().required("Inventory is required"),
  // inventoryTypeID: Yup.string().required("Inventory Type is required"),
  // quantityUsed: Yup.number().required("Recipe Quantity is required"),
});

export const RecipeManagementModal = ({
  open,
  onClose,
  isDelete = false,
  recipeId,
  basic = null,
  items = [],
  setItems,
}) => {
  const isNew = !recipeId;
  const [recipe, setRecipe] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedInventoryType, setSelectedInventoryType] = useState(undefined);

  const theme = useTheme();

  useEffect(() => {
    const fetchUserData = async () => {
      if (recipeId && open) {
        try {
          const response = await productionRecipesService.getById(recipeId);
          const data = response.data;
          setSelectedInventoryType(data.Inventory_TypeID);
          setRecipe(data);
          setFormData({
            inventoryTypeID: data["Inventory_TypeID"] || "",
            inventoryId: data["InventoryID"] || "",
            description: data["Inventory_Description"] || "",
            unitOfMeasure: data["Unit_Measure"] || "lbs",
            storageMethod: data["Storage_Method"] || "",
            coolerSchedule: data["Cooler_Schedule"] || 0,
            recipeQty: data["Recipe_Qty"] || 0,
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        setFormData(initialFormData);
      }
    };

    fetchUserData();
  }, [open, recipeId]);

  const handleInventoryTypeChange = async (value) => {
    setSelectedInventoryType(value);
    setFormData({ ...initialFormData, inventoryTypeID: value });
  };

  const handleInventoryChange = async (value) => {
    try {
      const response = await inventoriesService.getById(value);
      const data = response.data;

      let earliestChild = null;
      if (data.Inventory_Children && data.Inventory_Children.length > 0) {
        const sortedChildren = data.Inventory_Children.sort((a, b) => {
          return new Date(a.Date_Received) - new Date(b.Date_Received);
        });

        earliestChild = sortedChildren[0];
      }

      setFormData({
        inventoryId: data["InventoryID"] || "",
        description: data["Inventory_Description"] || "",
        inventoryTypeID: data["Inventory_Type-Inventory_TypeID"] || "",
        unitOfMeasure: earliestChild
          ? earliestChild.Answered_Questions.length > 0
            ? earliestChild.Answered_Questions.find(
              (item) =>
                item.Product_Question.Question === "container" &&
                item.Answer === "flat"
            )
              ? "flats"
              : "lbs"
            : "lbs"
          : "lbs",
        quantityUsed: 0,
        storageMethod: data["Inventory_Type-Storage_Method"] || "",
        coolerSchedule: data["Inventory_Type-Cooler_Schedule"] || 0,
        recipeQty: 0,
      });
    } catch (error) {
      console.error("Error fetching inventory details:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (isNew) {
        await postFormData(values);
      } else {
        await putFormData(values);
      }
      // navigate(0);
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const postFormData = async (values) => {
    try {
      const body = {
        Recipe_Name: basic.recipeName,
        Recipe_Description: basic.recipeDesc,
        InventoryID: values.inventoryId,
        Unit_Measure: values.unitOfMeasure,
        Recipe_Qty: values.recipeQty,
      };
      const result = await productionRecipesService.post(body);
      const tmp = [
        ...items,
        {
          ...result,
          "Inventory-Inventory_Type-Cooler_Schedule": `${result["Inventory-Inventory_Type-Cooler_Schedule"] || 0
            } Day(s)`,
        },
      ];
      setItems(
        tmp.map((item) => ({
          ...item,
          // Qty_1200lbs: qty1200lbsCalculator(tmp, item),
        }))
      );

      toast.success("Production recipe created successfully!");
    } catch (error) {
      console.error("Error posting form data:", error);
      toast.error("Failed to create production recipe");
    }
  };

  const putFormData = async (values) => {
    try {
      const body = {
        Recipe_Name: recipe.Recipe_Name,
        Recipe_Slug: recipe.Recipe_Slug,
        Recipe_Description: recipe.Recipe_Description,
        InventoryID: values.inventoryId,
        Unit_Measure: values.unitOfMeasure,
        Recipe_Qty: values.recipeQty,
      };
      const result = await productionRecipesService.putById(recipeId, body);
      const tmp = items.map((item) =>
        item.Production_RecipeID === recipeId
          ? {
            ...result,
            "Inventory-Inventory_Type-Cooler_Schedule": `${result["Inventory-Inventory_Type-Cooler_Schedule"] || 0
              } Day(s)`,
          }
          : item
      );
      setItems(
        tmp.map((item) => ({
          ...item,
          // Qty_1200lbs: qty1200lbsCalculator(tmp, item),
        }))
      );

      toast.success("Production recipe updated successfully!");
    } catch (error) {
      console.error("Error updating form data:", error);
      toast.error("Failed to update production recipe");
    }
  };

  const handleDeleteItem = async () => {
    await productionRecipesService.deleteById(recipeId);
    const tmp = items.filter((item) => item.Production_RecipeID !== recipeId);
    setItems(
      tmp.map((item) => ({
        ...item,
        // Qty_1200lbs: qty1200lbsCalculator(tmp, item),
      }))
    );
    setShowDeleteModal(false);
    onClose();
  };

  return (
    <>
      <BaseModal
        open={open}
        onClose={onClose}
        title={
          recipeId ? "Create New Recipe Inventory" : "Recipe Inventory Details"
        }
      >
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomApiDropdown
                    name={"inventoryTypeID"}
                    label={"Inventory Type"}
                    fetchOptions={inventoryTypesService.getAll}
                    valueKey={"Inventory_TypeID"}
                    labelKey={"Inventory_Type"}
                    showIdInLabel={false}
                    onSelectChange={handleInventoryTypeChange}
                    disabled={isDelete}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomApiDropdown2
                    name={"inventoryId"}
                    label={"Inventory Name"}
                    fetchOptions={(val) => {
                      const { keyword } = val || {};
                      return inventoriesService.getAll({
                        inventory_type: selectedInventoryType,
                        keyword,
                      });
                    }}
                    valueKey={"InventoryID"}
                    labelKey={"Name"}
                    showIdInLabel={false}
                    onSelectChange={handleInventoryChange}
                    disabled={isDelete || !selectedInventoryType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="description"
                    label="Inventory Description"
                    disabled
                    required={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="unitOfMeasure"
                    label="Unity of Measure"
                    disabled
                    required={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    type="number"
                    name="recipeQty"
                    label="Recipe Quantity"
                    disabled={isDelete}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="storageMethod"
                    label="Storage Method"
                    disabled
                    required={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="coolerSchedule"
                    label="Cooler Schedule"
                    disabled
                    required={false}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                    <Box>
                      <CustomButton
                        boldText
                        sx={{
                          mr: 2,
                        }}
                        onClick={onClose}
                      >
                        Cancel
                      </CustomButton>
                      {isDelete ? (
                        <CustomButton
                          boldText
                          onClick={() => setShowDeleteModal(true)}
                          sx={{
                            backgroundColor: theme.palette.error.main,
                            color: theme.palette.error.contrastText,
                          }}
                        >
                          Delete
                        </CustomButton>
                      ) : (
                        <CustomButton
                          boldText
                          type="submit"
                          disabled={!dirty || (isNew && !isValid)}
                        >
                          {isNew ? "Create" : "Update"}
                        </CustomButton>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </BaseModal>
      {!isNew && showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteItem}
        />
      )}
    </>
  );
};
