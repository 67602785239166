import apiClient from './api';
import { flattenObject } from './utils';

const ENDPOINTS = {
    BASE: '/productions',
    ID: (id) => `/productions/${id}`,
    RECIPES: (id) => `/productions/${id}/recipes`,
};

export const productionsService = {
    getAll: async (params = {}) => {
        const response = await apiClient.get(ENDPOINTS.BASE, { params });
        const responseData = response.data.data;
        const flattenedData = responseData.data.map((item) => flattenObject(item));
        const res = {
            ...response.data,
            data: {
                ...response.data.data,
                data: flattenedData,
            },
        };
        return res;
    },

    getById: async (id) => {
        if (!id) {
            return Promise.resolve({
                data: [],
                success: true,
                message: 'No ID provided, operation ignored',
            });
        }

        const response = await apiClient.get(ENDPOINTS.ID(id));
        const responseData = response.data.data;
        const flattenedObject = flattenObject(responseData);

        return {
            ...response.data,
            data: flattenedObject,
        };
    },

    getRecipesById: async (id) => {
        if (!id) {
            return Promise.resolve({
                data: [],
                success: true,
                message: 'No ID provided, operation ignored',
            });
        }
        const response = await apiClient.get(ENDPOINTS.RECIPES(id));
        return response.data;
    },

    post: async (params = {}) => {
        const response = await apiClient.post(ENDPOINTS.BASE, params);
        return response.data;
    },

    put: async (id, params = {}) => {
        const response = await apiClient.put(ENDPOINTS.ID(id), params);
        return response.data;
    },

    delete: async (id) => {
        const response = await apiClient.delete(ENDPOINTS.ID(id));
        return response.data;
    },
}; 