import apiClient from '../api';
import { flattenArrayField, flattenObject, normalizeParams } from "./utils";

const ENDPOINTS = {
  BASE: '/warehouseChild',
  ID: (id) => `/warehouseChild/id/${id}`,
  DETAIL: (id) => `/warehouseChild/${id}`,
};

export const warehouseChildService = {
  getAll: async (params = {}) => {
    const response = await apiClient.get(ENDPOINTS.BASE, { params });

    const responseData = response.data.data;
    const flattenedData = responseData.data.map(item => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: {
        ...response.data.data,
        data: flattenedData
      }
    };

    return modifiedResponse;
  },

  getByWarehouseID: async (warehouseID, params = {}) => {
    if (!warehouseID) {
      return;
    }

    const normalizedParams = normalizeParams({
      ...params,
      warehouseID: warehouseID,
    });

    return warehouseChildService.getAll(normalizedParams);
  },

  getById: async (warehs_childID) => {
    if (!warehs_childID) {
      return Promise.resolve({
        data: [],  // or {}
        success: true,
        message: "No ID provided, operation ignored"
      });
    }

    const response = await apiClient.get(ENDPOINTS.ID(warehs_childID));
    const responseData = response.data.data;
    const flattenedObject = flattenObject(responseData);

    const modifiedResponse = {
      ...response,
      data: {
        ...response.data,
        data: flattenedObject
      }
    };

    return modifiedResponse;

  },

  post: async (params = {}) => {
    const response = await apiClient.post(ENDPOINTS.BASE, params);
    return response.data;
  },

  put: async (id, params = {}) => {
    const response = await apiClient.put(ENDPOINTS.ID(id), params);
    return response.data;
  },

  delete: async (warehs_childID, params = {}) => {
    const response = await apiClient.delete(ENDPOINTS.ID(warehs_childID));
    return response.data;
  },

};