import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import Dashboard from "../../../components/common/Layout/Dashboard";
import { Container, Stack } from "@mui/material";
import ContentCard from "../../../components/common/ContentCard/ContentCard";
import CustomButton from "../../../components/common/CustomButton";

const SystemConfiguration = () => {
  return (
    <Dashboard transparent={true}>
      <Container
        maxWidth="300px"
        disableGutters
        sx={{
          ml: 0,
          mr: "auto",
          height: "200px",
          width: "700px",
        }}
      >
        <ContentCard title="Notification Settings" whiteBackground={true}>
          <Stack direction="row" flexWrap="wrap" rowGap={2} columnGap={2}>
            <CustomButton
              boldText={true}
              to={"/settings/notification-settings/notificationSettings"}
              sx={{
                padding: "10px 16px",
              }}
            >
              Notification Settings
            </CustomButton>
            <CustomButton
              boldText={true}
              to={"/settings/notification-settings/alertSettings"}
              sx={{
                padding: "10px 16px",
              }}
            >
              Warning Settings
            </CustomButton>
            <CustomButton
              boldText={true}
              to={"/settings/notification-settings/warningSettings"}
              sx={{
                padding: "10px 16px",
              }}
            >
              Alerts Settings
            </CustomButton>
          </Stack>
        </ContentCard>
      </Container>
    </Dashboard>
  );
};

export default SystemConfiguration;
