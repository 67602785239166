import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  Button,
  Switch,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ContentCard from "../../../../components/common/ContentCard/ContentCard";
import CustomButton from "../../../../components/common/CustomButton";
import CustomWideLayout from "../../../../components/common/Layout/CustomWideLayout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import CustomTextField from "../../../../components/common/CustomFormFields/CustomTextField";
import CustomCheckboxField from "../../../../components/common/CustomFormFields/CustomCheckboxField";

import CustomTable from "../../../../components/common/CustomTable";
import CustomRecurringScheduler from "../../../../components/common/CustomRecurringScheduler";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomSwitchField from "../../../../components/common/CustomFormFields/CustomSwitchField";
import { usePaginatedData, useTableModal, useTableSize } from "../../../../hooks";
import {
  unitMeasuresService,
  warehouseChildService,
  warehouseService,
} from "../../../../services";
import { maintenanceProceduresService } from "../../../../services/maintenanceProcedures";
import { InventoryModal, MaintenanceMaterialsModal } from "../../../../components/modals/TableModals";
import { cleaningProceduresService } from "../../../../services/cleaningProcedures";
import CustomApiDropdown from "../../../../components/common/CustomFormFields/CustomApiDropdown";
import { employeesService } from "../../../../services/employees";
import {
  CancelModal,
  ContinueModal,
} from "../../../../components/common/CustomModals";
import DeleteModal from "../../../../components/common/CustomModals/deleteModal";
import { toast } from "react-toastify";
import { CustomSelectField } from "../../../../components/common/CustomFormFields";
import { Warehouse } from "@mui/icons-material";
import DollarInput from "../../../../components/common/DollarInput";
import { set } from "date-fns";
import CustomPriceField from "../../../../components/common/CustomFormFields/CustomPriceField";
import { Label } from "recharts";
import { WarehouseChildModal } from "../../../../components/modals/TableModals/Inventory/warehouseChildModal";

const initialValues = {
  Product_Name: "",
  Product_Description: "",
  SKU_Number: "",
  Unit_MeasureID: "",
  Qty_InStock: 0,
  Unit_Price: 0,
  Stock_Value: 0,
  Qty_BkOrdered: 0,
};

const validationSchema = Yup.object({
  Product_Name: Yup.string().required("Product name is required"),
  Product_Description: Yup.string().required("Product description is required"),
  SKU_Number: Yup.string().required("SKU number is required"),
  Unit_MeasureID: Yup.number().required("Unit Measure is required"),
  Qty_InStock: Yup.number().required("Stock is required"),
  Unit_Price: Yup.number().required("Unit price is required"),
  Stock_Value: Yup.number().required("Total cost is required"),
  // Qty_BkOrdered: Yup.number().required("Qty bkOrdered is required"),
});

const MainForm = ({
  isNew,
  isDeleteMode,
  showDeleteModal,
  setShowDeleteModal,
  showContinueModal,
  setShowContinueModal,
  showCancelModal,
  setShowCancelModal,
  handleCancel,
  handleDeleteWarehouse,
  formikSubmitRef,
  formikResetRef,
  submitForm,
  resetForm,
  setIsFormDirty,
  isValid,
  isSubmitting,
  dirty,
  setFieldValue,
  totalInStock,
  formData,
  measureOptions,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();


  // Save Formik functions in refs for later use
  formikSubmitRef.current = submitForm;
  formikResetRef.current = resetForm;

  useEffect(() => {
    setIsFormDirty(dirty);
  }, [dirty, setIsFormDirty]);


  useEffect(() => {
    setFieldValue("Qty_InStock", totalInStock);
    setFieldValue("Stock_Value", totalInStock * formData.Unit_Price);
  }, [totalInStock, formData]);
  return (
    <Form
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: "350px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField
            name="Product_Name"
            label="Product Name"
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="Product_Description"
            label="Product_Description"
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="SKU_Number"
            label="SKU Number"
            disabled={!isNew || isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSelectField
            label="Product Type"
            name="Unit_MeasureID"
            options={measureOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="Qty_InStock"
            label="In Stock"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomPriceField
            name="Unit_Price"
            label="Unit Price"
            onChange={(e) => {
              setFieldValue("Unit_Price", parseFloat(e.target.value));
              setFieldValue("Stock_Value", parseFloat(e.target.value) * totalInStock);
            }}
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomPriceField
            name="Stock_Value"
            label="Total Cost"
            disabled={true}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            name="Qty_BkOrdered"
            label="Qty Backordered"
            disabled={isDeleteMode}
          />
        </Grid>

      </Grid>

      <Box sx={{ flexGrow: 1 }} />

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <CustomButton
            boldText
            sx={{ mr: 2 }}
            onClick={() => handleCancel(dirty)}
          >
            {dirty ? "Cancel" : "Go Back"}
          </CustomButton>

          {isDeleteMode ? (
            <CustomButton
              boldText
              onClick={() => setShowDeleteModal(true)}
              sx={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
              }}
            >
              Delete
            </CustomButton>
          ) : (
            <CustomButton
              boldText
              onClick={() => setShowContinueModal(true)}
              disabled={isSubmitting || !dirty || (isNew && !isValid)}
            >
              {isNew ? "Create" : "Update"}
            </CustomButton>
          )}
        </Box>

        <CancelModal
          open={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onConfirm={() => {
            setShowCancelModal(false);
            navigate(-1);
          }}
        />

        <ContinueModal
          open={showContinueModal}
          onClose={() => setShowContinueModal(false)}
          onConfirm={() => {
            setShowContinueModal(false);
            submitForm();
          }}
        />

        {isDeleteMode && showDeleteModal && (
          <DeleteModal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={handleDeleteWarehouse}
          />
        )}
      </Grid>
    </Form>
  );
};

const ManageWarehouseEdit = () => {
  // ID obtained from URL. If ID is found we POST, if not we PUT
  const { warehouseID } = useParams();

  // Extract state data, which only sometimes occur, for example from the equipment edit page
  const navigate = useNavigate()
  const location = useLocation();

  const { isDeleteMode } = location.state || {};

  const isNew = !warehouseID;

  const pageTitle = isNew
    ? "Create New Warehouse"
    : isDeleteMode
      ? "Delete Warehouse"
      : "Edit Warehouse";

  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(initialValues);
  const [inStock, setInStock] = useState(0.0);
  const { isOpen, isDelete, selectedId, openModal, closeModal } =
    useTableModal();

  // For rendering or hiding the modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // ─── NEW STATE & REFS FOR UNSAVED CHANGES ─────────────────────────────
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [measureOptions, setMeasureOptions] = useState([]);

  const formikSubmitRef = useRef(null);
  const formikResetRef = useRef(null);
  // ────────────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (isNew) {
      // if (SKU_Number)
      // setFormData({ ...initialValues, SKU_Number: SKU_Number });
      return;
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await warehouseService.getById(
          warehouseID
        );
        const data = response.data.data;
        setFormData({
          id: data.warehouseID || "",
          Product_Name: data.Product_Name || "",
          Product_Description: data.Product_Description || "",
          SKU_Number: data.Warehouse_SKU_Number || "",
          Unit_MeasureID: data['Unit_Measure-Unit_MeasureID'] || 1,
          Qty_InStock: data.Warehouse_Children.map((child) => child.Unit_Qty).reduce((a, b) => a + b, 0) || 0,
          Unit_Price: data.Unit_Price,
          Stock_Value: data.Warehouse_Children.map((child) => child.Unit_Qty * data.Unit_Price).reduce((a, b) => a + b, 0) || 0,
          Qty_BkOrdered: data.Qty_BkOrdered || 0,
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
        toast.error("Failed to fetch data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [warehouseID]);

  useEffect(() => {
    setInStock(formData.Qty_InStock);
  }, [formData]);

  const fetchUnitMeasure = async () => {
    try {
      const response = await unitMeasuresService.getAll();
      const data = response.data.data;
      return data.map((item) => {
        return {
          value: item.Unit_MeasureID,
          label: item.UM_Description,
        };
      });
    } catch (error) {
      console.error("Failed to fetch unit measure:", error);
      toast.error("Failed to fetch unit measure.");
    }
  };


  const handlePost = async (values) => {
    try {
      const body = {
        Product_Name: values.Product_Name,
        Product_Description: values.Product_Description,
        Warehouse_SKU_Number: values.SKU_Number,
        Unit_MeasureID: values.Unit_MeasureID,
        Qty_InStock: values.Qty_InStock,
        Unit_Price: new Intl.NumberFormat("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(values.Unit_Price),
        Stock_Value: values.Stock_Value,
        Qty_BkOrdered: values.Qty_BkOrdered,
      };
      const response = await warehouseService.post(body);
      const newId = response.data.WarehouseID;

      navigate(`/inventory/manage-warehouse/${newId}`, { replace: true });
      toast.success("Warehouse created successfully!");
    } catch (error) {
      console.error('Failed to create:', error);
      toast.error("Failed to create warehouse.");

    }
  };

  const handlePut = async (values) => {
    try {
      const body = {
        Product_Name: values.Product_Name,
        Product_Description: values.Product_Description,
        Warehouse_SKU_Number: values.SKU_Number,
        Unit_MeasureID: values.Unit_MeasureID,
        Qty_InStock: values.Qty_InStock,
        Unit_Price: new Intl.NumberFormat("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(values.Unit_Price),
        Stock_Value: values.Stock_Value,
        Qty_BkOrdered: values.Qty_BkOrdered,
      };
      const { data: updatedData } = await warehouseService.put(warehouseID, body);
      setFormData({
        WarehouseID: updatedData.WarehouseID,
        Product_Name: updatedData.Product_Name,
        Product_Description: updatedData.Product_Description,
        SKU_Number: updatedData.Warehouse_SKU_Number,
        Unit_MeasureID: updatedData.Unit_Measure.Unit_MeasureID,
        Qty_InStock: updatedData.Warehouse_Children.map((child) => child.Unit_Qty).reduce((a, b) => a + b, 0) || 0,
        Unit_Price: updatedData.Unit_Price,
        Stock_Value: updatedData.Warehouse_Children.map((child) => child.Unit_Qty * updatedData.Unit_Price).reduce((a, b) => a + b, 0) || 0,
        Qty_BkOrdered: updatedData.Qty_BkOrdered,
      });
      toast.success("Warehouse updated successfully!");
    } catch (error) {
      console.error('Failed to update:', error);
      toast.error("Failed to update warehouse.");
    }
  }
  const handleSubmit = async (values) => {
    try {
      if (isNew) {
        await handlePost(values);
      } else {
        await handlePut(values);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleCancel = (dirty) => {
    if (dirty) {
      setShowCancelModal(true)
    } else {
      navigate(-1)
    }
  }

  const handleDeleteWarehouse = async () => {
    try {
      await warehouseService.delete(warehouseID);
      setShowDeleteModal(false);
      navigate(-1);
      toast.success("Warehouse deleted successfully!");
      navigate(`/inventory/manage-warehouse/${warehouseID}`, { replace: true });
    } catch (error) {
      console.error("Failed to delete warehouse:", error);
      toast.error("Failed to delete warehouse.");
    }
  };

  // ─── Wrap navigation actions to check for unsaved changes ────────────────
  const handleNavigationAction = (action) => {
    if (isFormDirty) {
      setPendingNavigation(() => action);
      setShowUnsavedModal(true);
    } else {
      action();
    }
  };
  // ────────────────────────────────────────────────────────────────────────

  // ─── Updated Warehouse Child Actions ──────────────────────────────────
  const handleAddWarehouseChild = () => {
    handleNavigationAction(() => {
      handleAdd();
    });
  };

  const handleEditWarehouseChild = (row) => {
    handleNavigationAction(() => {
      handleEdit(row);
    });
  };

  const handleDeleteWarehouseChild = (row) => {
    handleNavigationAction(() => {
      handleDelete(row);
    });
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
  };

  const handleCancelModalConfirm = () => {
    setShowCancelModal(false);
    navigate(-1);
  };

  const handleContinueModalClose = () => {
    setShowContinueModal(false);
  };

  const handleContinueModalConfirm = async (submitForm) => {
    setShowContinueModal(false);
    submitForm();
  };

  // ────────────────────────────────────────────────────────────────────────

  const {
    data: data,
    loading,
    error,
    totalItems,
    currentPage,
    totalPages,
    setData,
    changePage,
    handleSearch,
  } = usePaginatedData(
    (params) => warehouseChildService.getByWarehouseID(warehouseID, params)
  );
  const getData = (data) => {
    return data.map((row) => {
      const displayUnit = measureOptions.find(option => option.value === formData.Unit_MeasureID);
      const label = displayUnit ? displayUnit.label : '';
      return {
        Warehs_ChildID: row.Warehs_ChildID,
        Product_Name: row['Warehouse-Product_Name'],
        SKU_Number: row.SKU_Number,
        Date: `${row.Date_Manufactured}`,
        Case_Size: `${row['Case_Size']} ${label}`,
        In_Stock: row.Unit_Qty,
        Case_Price: `$${formData.Unit_Price}`,
        Sub_Total: `$${row.Unit_Qty * formData.Unit_Price}`,
      };
    });
  }

  // ─── Functions for Unsaved Changes Modal Actions ───────────────────────
  const handleSaveAndNavigate = async () => {
    if (formikSubmitRef.current) {
      // Calling submitForm returns a promise
      await formikSubmitRef.current();
      if (pendingNavigation) {
        pendingNavigation();
        setPendingNavigation(null);
      }
      setShowUnsavedModal(false);
    }
  };

  const handleDiscardAndNavigate = () => {
    if (formikResetRef.current) {
      // Reset the form to the saved formData
      formikResetRef.current(formData);
    }
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
    setShowUnsavedModal(false);
  };

  const cancelNavigation = () => {
    setShowUnsavedModal(false);
    setPendingNavigation(null);
  };
  // ────────────────────────────────────────────────────────────────────────

  const handleEdit = (row) => {
    const warehsChildID = row.Warehs_ChildID;
    openModal(warehsChildID);
  };

  const handleDelete = (row) => {
    const warehsChildID = row.Warehs_ChildID;
    openModal(warehsChildID, true);
  };

  const handleAdd = () => {
    openModal();
  };

  const dataConfig = [
    {
      key: "Product_Name",
      header: "Product Name",
      visible: true,
    },
    {
      key: "SKU_Number",
      header: "SKU Number",
      visible: true,
    },
    {
      key: "Date",
      header: "Production Date",
      visible: true,
    },
    {
      key: "Case_Size",
      header: "Case Size",
      visible: true,
    },
    {
      key: "In_Stock",
      header: "In Stock",
      visible: true,
    },
    {
      key: "Case_Price",
      header: "Case Price",
      visible: true,
    },
    {
      key: "Sub_Total",
      header: "Sub-Total Price",
      visible: true,
    },
  ];


  useEffect(() => {
    fetchUnitMeasure().then((data) => {
      setMeasureOptions(data);
    });
  }, [])


  useEffect(() => {
    const totalInStock = data.map((row) => row.Unit_Qty).reduce((a, b) => a + b, 0);
    if (totalInStock === inStock) return;

    // setInStock(totalInStock);
    setFormData((prev) => ({
      ...prev,
      Qty_InStock: totalInStock,
      Stock_Value: totalInStock * prev.Unit_Price,
    }));
  }, [data]);


  return (
    <CustomWideLayout>
      {/* ─── Unsaved Changes Modal ───────────────────────────── */}
      <Dialog open={showUnsavedModal} onClose={cancelNavigation}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent sx={{ padding: "0 24px" }}>
          <Typography>
            You have unsaved changes. Would you like to save them before
            proceeding?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px 24px",
          }}
        >
          <CustomButton onClick={handleSaveAndNavigate}>
            Save Changes
          </CustomButton>
          <CustomButton onClick={handleDiscardAndNavigate}>
            Discard Changes
          </CustomButton>
          <CustomButton onClick={cancelNavigation}>Cancel</CustomButton>
        </DialogActions>
      </Dialog>
      {/* ───────────────────────────────────────────────────────── */}
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, isValid, dirty, submitForm, resetForm, setFieldValue }) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
                <ContentCard whiteBackground={true} title={pageTitle}>
                  {/* <Formik
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              > */}
                  <MainForm
                    isNew={isNew}
                    isDeleteMode={isDeleteMode}
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    showContinueModal={showContinueModal}
                    setShowContinueModal={setShowContinueModal}
                    showCancelModal={showCancelModal}
                    setShowCancelModal={setShowCancelModal}
                    handleCancel={handleCancel}
                    handleDeleteWarehouse={handleDeleteWarehouse}
                    formikSubmitRef={formikSubmitRef}
                    formikResetRef={formikResetRef}
                    submitForm={submitForm}
                    resetForm={resetForm}
                    setIsFormDirty={setIsFormDirty}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                    dirty={dirty}
                    totalInStock={inStock}
                    setFieldValue={setFieldValue}
                    formData={formData}
                    measureOptions={measureOptions}
                  />
                </ContentCard>
              </Grid>
            </Grid>

            {!isNew && !isDeleteMode && (
              <CustomTable
                titleText={formData.Product_Name}
                isNew={isNew}
                data={getData(data)}
                dataConfig={dataConfig}
                onEdit={handleEditWarehouseChild}
                onDelete={handleDeleteWarehouseChild}
                onAddText="Add Product"
                onAddClick={handleAddWarehouseChild}
                totalItems={totalItems}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={changePage}
                searchText={"Search Product"}
                showSearch={true}
                onSearch={handleSearch}
              />
            )}
            {isOpen && <WarehouseChildModal
              open={isOpen}
              onClose={closeModal}
              isDelete={isDelete}
              warehsChildID={selectedId}
              warehouse={formData}
              setData={setData}
            />}
          </>
        )}

      </Formik>

    </CustomWideLayout>
  );
};

export default ManageWarehouseEdit;
