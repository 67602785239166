import apiClient from '../api';
import { flattenObject, normalizeParams } from "./utils";


const ENDPOINTS = {
  BASE: '/productionSchedules',
  DETAIL: (id) => `/productionSchedules/id/${id}`,
  DATE: (date) => `/productionSchedules/date/${date}`,
  WEEK: `/productionSchedules/week`,
};

export const productionSchedulesService = {
  getAll: async (params = {}) => {
    const response = await apiClient.get(ENDPOINTS.BASE, { params });
    const modifiedResponse = {
      ...response.data,
      data: response.data.data,
    };
    return modifiedResponse;
  },

  getByDate: async (date) => {
    if (!date) {
      return Promise.resolve({
        data: [],  // or {}
        success: true,
        message: "No Date provided, operation ignored"
      });
    }

    const response = await apiClient.get(ENDPOINTS.DATE(date));
    const modifiedResponse = {
      ...response,
      data: {
        ...response.data.data,
        data: response.data.data.data,
      }
    };
    return modifiedResponse;
  },

  getByWeekly: async (today) => {
    if (!today) {
      return Promise.resolve({
        data: [],  // or {}
        success: true,
        message: "No Date provided, operation ignored"
      });
    }

    const response = await apiClient.get(ENDPOINTS.WEEK, { params: { today } });
    const modifiedResponse = {
      ...response,
      data: {
        ...response.data.data,
        data: response.data.data.data,
      }
    };
    return modifiedResponse;
  },

  getById: async (productionScheduleID) => {
    if (!productionScheduleID) {
      return Promise.resolve({
        data: [],  // or {}
        success: true,
        message: "No ID provided, operation ignored"
      });
    }

    const response = await apiClient.get(ENDPOINTS.DETAIL(productionScheduleID));
    const responseData = response.data.data;
    const flattenedObject = flattenObject(responseData);

    const modifiedResponse = {
      ...response,
      data: {
        ...response.data,
        data: flattenedObject
      }
    };

    return modifiedResponse;

  },

  post: async (params = {}) => {
    const response = await apiClient.post(ENDPOINTS.BASE, params);
    return response.data;
  },

  put: async (id, params = {}) => {
    const response = await apiClient.put(ENDPOINTS.DETAIL(id), params);
    return flattenObject(response.data);
  },

  deleteByDate: async (date, params = {}) => {
    const response = await apiClient.delete(ENDPOINTS.DATE(date));
    return response.data;
  },

  delete: async (productionScheduleID, params = {}) => {
    const response = await apiClient.delete(ENDPOINTS.DETAIL(productionScheduleID));
    return response.data;
  },

  updateSlotOrder: async (params = {}) => {
    const response = await apiClient.put(ENDPOINTS.BASE, params);
    const responseData = response.data.data;
    if (response.data.success) {
      return flattenObject(responseData);
    } else {
      return false;
    }
  }
};