import React from "react";
import BaseModal from "./baseModal";

const DeleteModal = ({ open, onClose, onConfirm, title, message, confirmText, cancelText, confirmColor, titleColor }) => {
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={title || "Delete Confirmation"}
      message={message || "Are you sure you want to delete this item?"}
      confirmText={confirmText || "Delete"}
      cancelText={cancelText || "Cancel"}
      confirmColor={confirmColor || "error"}
      titleColor={titleColor || "error"}
    />
  );
};

export default DeleteModal;
