import React from "react";
import { useField } from "formik";
import {
  TextField,
  MenuItem,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useApiDropdown from "../../../hooks/useApiDropdown";

const CustomDropdown = ({
                             label,
                             disabled = false,
                             required = true,
                             data,
                             valueKey = "id",
                             labelKey = "name",
                             showIdInLabel = false,
                             onSelectChange,
                             ...props
                           }) => {
  // const [field, meta] = useField(props);
  // const errorText = meta.error && meta.touched ? meta.error : "";
  const theme = useTheme();

  // const handleChange = (e) => {
  //   field.onChange(e);
  //   if (onSelectChange) {
  //     onSelectChange(e.target.value);
  //   }
  // };

  // const { options, isLoading, error } = useApiDropdown(
  //   fetchOptions,
  //   valueKey,
  //   labelKey,
  //   showIdInLabel
  // );

  return (

      <Grid flexGrow={1}>
        <TextField
          {...props}
          // onChange={handleChange}
          select
          // fullWidth
          // helperText={errorText || error}
          // error={!!errorText || !!error}
          // disabled={isLoading || disabled}
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "#F3F4F6",
              borderRadius: "0.75rem",
              fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E5E7EB",
              },
              "&:hover fieldset": {
                borderColor: "#D1D5DB",
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
              },
            },
            "& .MuiInputBase-input": {
              color: "#4D5658",
              fontSize: "0.8rem",
            },
            "& .MuiFormHelperText-root": {
              fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
              fontSize: "0.7rem",
            },
            // maxWidth: "150px",
            flexGrow: 1,
            // width:200

          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            },
          }}
          required={required}
        >
          {(
            data.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          )}
        </TextField>
      </Grid>
  );
};

export default CustomDropdown;
