import apiClient from '../api';
import { flattenObject } from "./utils";

const ENDPOINTS = {
  BASE: '/inventories',
  DETAIL: (id) => `/inventories/${id}`,
};

export const inventoriesService = {
  getAll: async (params = {}) => {
    const allItemsParams = { ...params, all: true };
    const response = await apiClient.get(ENDPOINTS.BASE, { params: allItemsParams });

    const responseData = response.data.data;
    const flattenedData = responseData.data.map(item => flattenObject(item));

    const modifiedResponse = {
      ...response.data,
      data: {
        ...response.data.data,
        data: flattenedData
      }
    };
    return modifiedResponse;
  },

  getById: async (id) => {
    if (!id) {
      return Promise.resolve({
        data: [],  // or {}
        success: true,
        message: "No ID provided, operation ignored"
      });
    }

    const response = await apiClient.get(ENDPOINTS.DETAIL(id));
    const responseData = response.data.data;
    const flattenedObject = flattenObject(responseData);

    const modifiedResponse = {
      ...response.data,
      data: flattenedObject
    };

    return modifiedResponse;
  },

  post: async (params = {}) => {
    const response = await apiClient.post(ENDPOINTS.BASE,  params );
    return response.data;
  },

  put: async (id, params = {}) => {
    const response = await apiClient.put(ENDPOINTS.DETAIL(id),  params );
    return response.data;
  },

  delete: async(id, params = {}) => {
    const response = await apiClient.delete(ENDPOINTS.DETAIL(id));
    return response.data;
  }
};