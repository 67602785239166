import moment from 'moment';

export const generateCalendarData = (schedules, startDate, endDate) => {
    console.log("👌👌👌schedules", schedules);

    let calendarData = [];

    schedules.forEach((schedule) => {
        switch (schedule.ScheduleType) {
            case 'one-time':
                calendarData.push(...getOneTimeDates(schedule, startDate, endDate));
                break;
            case 'hourly':
                calendarData.push(...getHourlyDates(schedule, startDate, endDate));
                break;
            case 'daily':
                calendarData.push(...getDailyDates(schedule, startDate, endDate));
                break;
            case 'weekly':
                calendarData.push(...getWeeklyDates(schedule, startDate, endDate));
                break;
            case 'monthly':
                calendarData.push(...getMonthlyDates(schedule, startDate, endDate));
                break;
            case 'yearly':
                calendarData.push(...getYearlyDates(schedule, startDate, endDate));
                break;
            default:
                break;
        }
    });
    console.log("😍😍😍calendarData", calendarData);
    return calendarData;
}

const getOneTimeDates = (schedule, startDate, endDate) => {
    let calendarData = [];
    const { Label, ScheduleID, ScheduleType, StartDate, StartTime, TimeOfDay, DayOfWeek, DayOfMonth, Month, RepeatInterval } = schedule;

    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate);
    const current = moment(StartDate, "MM-DD-YYYY");

    if (current >= start && current <= end) {
        calendarData.push({
            date: current.format('YYYY-MM-DD'),
            label: Label,
            completed: true,
        });
    }

    return calendarData;
}

const getHourlyDates = (schedule, startDate, endDate) => {
    let calendarData = [];
    const { Label, ScheduleID, ScheduleType, StartDate, StartTime, TimeOfDay, DayOfWeek, DayOfMonth, Month, RepeatInterval } = schedule;

    const start = moment(StartDate).set('hour', moment(StartTime, 'hh:mm A').format('HH')).set('minute', moment(StartTime, 'hh:mm A').format('mm'));
    // const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate);
    let current = moment(StartDate)
        .set('hour', moment(StartTime, 'hh:mm A').hour())
        .set('minute', moment(StartTime, 'hh:mm A').minute());


    while (current <= end) {
        if (current >= start) {

            const diffWithCurrent = current.diff(moment(StartDate).set('hour', moment(StartTime, 'hh:mm A').format('HH')).set('minute', moment(StartTime, 'hh:mm A').format('mm')), 'hours');

            // Check if the current date is a scheduled occurrence
            if (diffWithCurrent === 0) {
                calendarData.push({
                    date: current.format('YYYY-MM-DD'),
                    label: Label,
                    completed: true,
                });
            } else {
                // Check if the current date is not same with start DateTime
                if ((diffWithCurrent) % RepeatInterval === 0) {
                    calendarData.push({
                        date: current.format('YYYY-MM-DD'),
                        label: Label,
                        completed: true,
                    });
                }
            }
        }

        // Move to the next occurrence based on the schedule type
        current.add(RepeatInterval, 'hours');
    }

    console.log(" Hourly Dates:", calendarData);
    return calendarData;
}

const getDailyDates = (schedule, startDate, endDate) => {
    let calendarData = [];
    const { Label, ScheduleID, ScheduleType, StartDate, StartTime, TimeOfDay, DayOfWeek, DayOfMonth, Month, RepeatInterval } = schedule;

    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate);
    let current = moment(StartDate, "MM-DD-YYYY");

    while (current <= end) {
        if (current >= start) {

            const diffWithCurrent = current.diff(moment(StartDate));

            // Check if the current date is a scheduled occurrence
            if (diffWithCurrent === 0) {
                calendarData.push({
                    date: current.format('YYYY-MM-DD'),
                    label: Label,
                    completed: true,
                });
            } else {
                // Check if the current date is not same with start DateTime
                if ((diffWithCurrent) % RepeatInterval === 0) {
                    calendarData.push({
                        date: current.format('YYYY-MM-DD'),
                        label: Label,
                        completed: true,
                    });
                }
            }
        }

        // Move to the next occurrence based on the schedule type
        current.add(RepeatInterval, 'days');
    }
    return calendarData;
}

const getWeeklyDates = (schedule, startDate, endDate) => {
    let calendarData = [];
    const { Label, ScheduleID, ScheduleType, StartDate, StartTime, TimeOfDay, DayOfWeek, DayOfMonth, Month, RepeatInterval } = schedule;

    // Sort days of the week (0 = Sunday, 1 = Monday, etc.)
    DayOfWeek.sort((a, b) => a - b);

    // Define start, end, and strict schedule start moments
    const start = moment(startDate, "YYYY-MM-DD");
    const scheduleStart = moment(StartDate, "MM-DD-YYYY");
    const end = moment(endDate, "YYYY-MM-DD");

    // Align to the first occurrence week
    let current = scheduleStart.clone().startOf('week');

    // Loop through weeks until the end date
    while (current <= end) {
        // Loop through the days of the week in the schedule
        DayOfWeek.forEach((day) => {
            const scheduledDate = current.clone().day(day);

            // Only add dates within range and after the initial StartDate
            if (scheduledDate.isBetween(start, end, null, '[]') && scheduledDate.isSameOrAfter(scheduleStart)) {
                calendarData.push({
                    date: scheduledDate.format('YYYY-MM-DD'),
                    label: Label,
                    completed: true,
                });
            }
        });

        // Move to the next occurrence based on the repeat interval
        current.add(RepeatInterval, 'weeks');
    }

    return calendarData;
};

const getMonthlyDates = (schedule, startDate, endDate) => {
    let calendarData = [];
    const { Label, ScheduleID, ScheduleType, StartDate, StartTime, TimeOfDay, DayOfWeek, DayOfMonth, Month, RepeatInterval } = schedule;
    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate);
    let current = moment(StartDate, "MM-DD-YYYY");

    while (current <= end) {
        if (current >= start) {
            // Check if the current date is a scheduled occurrence
            if (current.date() === DayOfMonth) {
                calendarData.push({
                    date: current.format('YYYY-MM-DD'),
                    label: Label,
                    completed: true,
                });
            }
        }

        // Move to the next occurrence based on the schedule type
        current.add(RepeatInterval, 'month');
    }
    return calendarData;
}

const getYearlyDates = (schedule, startDate, endDate) => {
    let calendarData = [];
    const { Label, ScheduleID, ScheduleType, StartDate, StartTime, TimeOfDay, DayOfWeek, DayOfMonth, Month, RepeatInterval } = schedule;
    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate);
    let current = moment(StartDate, "MM-DD-YYYY");

    while (current <= end) {
        if (current >= start) {
            // Check if the current date is a scheduled occurrence
            console.log(current.month(), current.date());
            if (current.month() === Month && current.date() === DayOfMonth) {
                calendarData.push({
                    date: current.format('YYYY-MM-DD'),
                    label: Label,
                    completed: true,
                });
            }
        }

        // Move to the next occurrence based on the schedule type
        current.add(RepeatInterval, 'year');
    }
    return calendarData;
}