import { useState, useEffect, useCallback } from "react";

/**
 * @param {Function} fetchFunction   e.g. equipmentTypesService.getAll({ keyword })
 * @param {string} keyword           The current search text or keyword.
 * @param {string} valueKey          The key in the data object used for the "value" of each option.
 * @param {string} labelKey          The key in the data object used for the "label" of each option.
 * @param {boolean} showIdInLabel    Whether to show the id before the label in the dropdown.
 */
const useApiDropdown2 = (
  fetchFunction,
  keyword,
  valueKey = "id",
  labelKey = "name",
  showIdInLabel = false
) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Wrap our fetch call in a useCallback so we can re-call it if needed
  const loadOptions = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      // e.g. fetchFunction({ keyword: 'pump' })
      const actualResponse = await fetchFunction({ keyword });
      // Suppose actualResponse = { data: [ {Equipment_TypeID, Equipment_Description}, ... ] }
      const dataArray = actualResponse.data.data || [];

      const transformedOptions = dataArray.map((item) => ({
        [valueKey]: item[valueKey],
        [labelKey]: showIdInLabel
          ? `${item[valueKey]}: ${item[labelKey]}`
          : item[labelKey],
      }));
      setOptions(transformedOptions);
    } catch (err) {
      setError(err.message || "Failed to load options");
    } finally {
      setIsLoading(false);
    }
  }, [fetchFunction, keyword, valueKey, labelKey, showIdInLabel]);

  useEffect(() => {
    if (!keyword) {
      // If no keyword, clear the dropdown
      setOptions([]);
      return;
    }
    loadOptions();
  }, [keyword, loadOptions]);

  return {
    options,
    setOptions, // Expose setOptions so we can manually insert new items later
    isLoading,
    error,
    refetch: loadOptions, // Optionally expose refetch if you want to trigger a refresh
  };
};

export default useApiDropdown2;
