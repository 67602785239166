import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import CustomWideLayout from "../../../components/common/Layout/CustomWideLayout";
import CustomTable from "../../../components/common/CustomTable";
import { usePaginatedData } from "../../../hooks";
import { cleaningJobsService, maintenanceJobsService, productionCoolerService, productionRecipesService } from "../../../services";
import { toast } from 'react-toastify';
import { qty1200lbsCalculator } from '../../../utils/qtyCalculator';
import moment from "moment/moment";

const InventoryForProduction = () => {
  const navigate = useNavigate()

  const [items, setItems] = useState([]);
  const [totalQty, setTotalQty] = useState(0);

  /*************************** PRODUCTION RECIPE ITEMS ************************* */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = moment().format("YYYY-MM-DD");
        const response = await productionRecipesService.getByDate(today);
        const data = response.data;
        console.log("👍👍👍 Production Data:", data);
        if (data.length > 0) {
          setItems(
            data.map((item) => ({
              ...item,
              "Inventory-Inventory_Type-Cooler_Schedule": `${item["Inventory-Inventory_Type-Cooler_Schedule"] || 0
                } Day(s)`,
              Qty_1200lbs: qty1200lbsCalculator(data, item) * item["Production_1200lbs_Qty"],
              Recipe_Qty: item["Recipe_Qty"] * item["Production_1200lbs_Qty"]
            }))
          );
          // setFormData({
          //   recipeName: data[0].Recipe_Name,
          //   recipeDesc: data[0].Recipe_Description,
          //   items: data.length,
          //   weight: data.reduce(
          //     (sum, item) =>
          //       item.Unit_Measure === "lbs" ? sum + item.Recipe_Qty : sum,
          //     0
          //   ),
          // });
          setTotalQty(
            data.reduce(
              (sum, item) =>
                item.Unit_Measure === "lbs" ? sum + item.Recipe_Qty * item["Production_1200lbs_Qty"] : sum,
              0
            ),
          );
        } else {
          navigate(-1);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        // toast.error("Failed to fetch data");
      }
    };

    fetchData();
  }, [navigate]);


  const dataConfig = [
    {
      key: 'Recipe_Name',
      header: 'Recipe Name',
      visible: true,
    },
    {
      key: 'Inventory-Inventory_Name',
      header: 'Inventory Name',
      visible: true,
    },
    {
      key: 'Inventory-Inventory_Description',
      header: 'Inventory Description',
      visible: true,
    },
    {
      key: 'Equipment',
      header: 'Equipment',
      visible: true,
    },
    {
      key: 'Recipe_Qty',
      header: 'Production Quantity',
      visible: true,
    },
    {
      key: 'Unit_Cost',
      header: 'Unit Cost',
      visible: true,
    },

  ];
  ///////////////////////// END PRODUCTION RECIPE ITEMS /////////////////////*/


  /************************** PRODUCTION COOLER ******************* */
  const {
    data: dataCooler,
    loading: loadingCooler,
    error: errorCooler,
    totalItems: totalItemsCooler,
    currentPage: currentPageCooler,
    totalPages: totalPagesCooler,
    changePage: changePageCooler,
    handleSearch: handleSearchCooler,
  } = usePaginatedData(productionCoolerService.getAll);

  console.log("🚀🚀🚀 Data", dataCooler);
  // Handlers
  const handleEditCooler = (row) => {
    const productionID = row['Production-ProductionID'];
    navigate(`/production/inventory-for-production/edit/${productionID}`);
  };
  // const handleDeleteCooler = async (row) => {
  //   try {
  //     await maintenanceJobsService.delete(row.Maintenance_JobID);
  //     navigate(0);
  //   } catch (error) {
  //     console.error('Failed to delete:', error);
  //   }
  // };

  const handleAddCooler = (row) => {
    navigate(`/production/inventory-for-production/new`);
  }

  const coolerTable = dataCooler.map((item) => ({
    ...item,
    Production_Name: item['Production-Production_Name'],
    Production_Date: moment(item['Production-Production_Date']).format("DD/MM/YYYY"),
    Recipe_Name: item['Production_Recipe-Recipe_Name'],
    // Warehouse_SKU: item['Warehouse_SKU'],
    Quantity: item['Total_Qty_1200lbs'] * item['Production-ProductionSchedule-Production_1200lbs_Qty'],
    Production_Price: `$${item.Total_Unit_Price}`,
  }));

  const dataConfigCooler = [
    {
      key: 'Production_Name',
      header: 'Production Name',
      visible: true,
    },
    {
      key: 'Production_Date',
      header: 'Production Date',
      visible: true,
    },
    {
      key: 'Recipe_Name',
      header: 'Recipe_Name',
      visible: true,
    },
    // {
    //   key: 'Warehouse_SKU',
    //   header: 'Warehouse SKU',
    //   visible: true,
    // },
    {
      key: 'Quantity',
      header: 'Quantity',
      visible: true,
    },
    {
      key: 'Production_Price',
      header: 'Production_Price',
      visible: true,
    },
  ];


  return (
    <>
      <CustomWideLayout>
        <CustomTable
          titleText={`In Production - ${totalQty}#`}
          data={items}
          dataConfig={dataConfig}
          // onEdit={handleEdit}
          // onDelete={handleDelete}
          // onAddText="Add New Maintenance Schedule"
          // onAddClick={handleAdd}
          isShowActions={false}
          totalItems={items.length}
          currentPage={1}
          totalPages={1}
          showSearch={false}
          page
        />

        <CustomTable
          titleText={"In Production Cooler"}

          data={coolerTable}
          dataConfig={dataConfigCooler}

          onEdit={handleEditCooler}
          // onDelete={handleDeleteCooler}
          onAddText="Add New Cooler"
          onAddClick={handleAddCooler}

          totalItems={totalItemsCooler}
          currentPage={currentPageCooler}
          totalPages={totalPagesCooler}
          onPageChange={changePageCooler}

          searchText={"Item Search"}
          onSearch={handleSearchCooler}
        />
      </CustomWideLayout>
    </>
  );
};

export default InventoryForProduction;