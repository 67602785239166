import React from "react";
import { useField } from "formik";
import { TextField, Grid, Typography, InputAdornment } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NumericFormat } from "react-number-format";

const CustomPriceField = ({ name, label, required = true, ...props }) => {
  const [field] = useField(name);
  const theme = useTheme();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={4}>
        <Typography>
          {label}
          {required && <span className="text-red-700">*</span>}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <NumericFormat
          {...field}
          {...props}
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator
          customInput={TextField}
          fullWidth
          required={required}
          placeholder="0.00"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "#F3F4F6",
              borderRadius: "0.75rem",
              fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E5E7EB",
              },
              "&:hover fieldset": {
                borderColor: "#D1D5DB",
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
              },
            },
            "& .MuiInputBase-input": {
              color: "#4D5658",
              fontSize: "0.8rem",
            },
            "& .MuiFormHelperText-root": {
              fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
              fontSize: "0.7rem",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomPriceField;
