import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import CustomWideLayout from "../../../components/common/Layout/CustomWideLayout";
import CustomTable from "../../../components/common/CustomTable";
import { usePaginatedData } from "../../../hooks";
import { warehouseService } from "../../../services";

const ManageWarehouse = () => {
  const navigate = useNavigate()

  const {
    data: data,
    loading,
    error,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
    testSearch
  } = usePaginatedData(warehouseService.getAll);

  // Handlers
  const handleEdit = (row) => {
    navigate(
      `/inventory/manage-warehouse/${row.WarehouseID}`
    );
  };

  const handleDelete = async (row) => {
    navigate(
      `/inventory/manage-warehouse/${row.WarehouseID}`, {
      state: {
        isDeleteMode: true,
      },
    });
  };

  const handleAdd = (row) => {
    navigate(
      `/inventory/manage-warehouse/new`
    );
  }

  const dataConfig = [
    // {
    //   key: 'WarehouseID',
    //   header: 'ID',
    //   visible: true,
    // },
    {
      key: 'Product_Name',
      header: 'Product Name',
      visible: true,
    },
    {
      key: 'Product_Description',
      header: 'Product Description',
      visible: true,
    },
    {
      key: 'Warehouse_SKU_Number',
      header: 'SKU Number',
      visible: true,
    },
    {
      key: 'Unit',
      header: 'Case Size',
      visible: true,
    },
    {
      key: 'Qty_InStock',
      header: 'In Stock',
      visible: true,
    },
    {
      key: 'Unit_Price',
      header: 'Unit Cost',
      visible: true,
    },
    {
      key: 'Stock_Value',
      header: 'Sub-Total Cost',
      visible: true,
    },
  ];
  console.log("😍😍😍data", data);
  const tableData = data.map((row) => {
    return {
      WarehouseID: row.WarehouseID,
      Product_Name: row.Product_Name,
      Product_Description: row.Product_Description,
      Warehouse_SKU_Number: row.Warehouse_SKU_Number,
      Unit: row["Unit_Measure-UM_Description"],
      Qty_InStock: row.Warehouse_Children.map((child) => child.Unit_Qty).reduce((a, b) => a + b, 0),
      Unit_Price: `$${row.Unit_Price}`,
      Stock_Value: `$${row.Warehouse_Children.map((child) => child.Unit_Qty * child.Unit_Price).reduce((a, b) => a + b, 0)}`,
    };
  });

  return (
    <>
      <CustomWideLayout>
        <CustomTable
          titleText={"Manage Warehouse"}
          data={tableData}
          dataConfig={dataConfig}

          onEdit={handleEdit}
          onDelete={handleDelete}
          onAddText="Add Warehouse"
          onAddClick={handleAdd}

          totalItems={totalItems}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={changePage}

          searchText={"Warehouse Search"}
          onSearch={handleSearch}
        />
      </CustomWideLayout>
    </>
  );
};

export default ManageWarehouse;