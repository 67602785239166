export const qty1200lbsCalculator = (items, item) => {
  if (!item || !Array.isArray(items)) return 0;

  const total = items.reduce(
    (acc, { Unit_Measure, Recipe_Qty = 0 }) =>
      acc + (Unit_Measure === "lbs" ? Recipe_Qty : 0),
    0
  );

  if (total === 0) return 0;

  return ((item.Recipe_Qty * 1200) / total).toFixed(0);
};
