import { useState } from "react";

const useTableModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const openModal = (id = null, deleteOption = false) => {
    setSelectedId(id);
    setIsOpen(true);
    setIsDelete(deleteOption);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedId(null);
  };

  return {
    isOpen,
    isDelete,
    selectedId,
    openModal,
    closeModal,
  };
};

export default useTableModal;
