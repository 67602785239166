import React, { useState, useEffect, useRef } from 'react';
import {
  Container, Typography, Paper, Grid, Box, Button, Switch, useTheme, Dialog,
  DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import ContentCard from "../../../../../components/common/ContentCard/ContentCard";
import CustomButton from "../../../../../components/common/CustomButton";
import CustomWideLayout from "../../../../../components/common/Layout/CustomWideLayout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from '../../../../../components/common/CustomFormFields/CustomTextField'
import CustomTable from "../../../../../components/common/CustomTable";
import CustomRecurringScheduler from "../../../../../components/common/CustomRecurringScheduler";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { equipmentService, productionLinesService, productionStepsService } from "../../../../../services";
import CustomApiDropdown from "../../../../../components/common/CustomFormFields/CustomApiDropdown";
import { CancelModal, ContinueModal } from "../../../../../components/common/CustomModals";
import { usePaginatedData } from "../../../../../hooks";
import { Description } from '@mui/icons-material';
import CustomDragDropTable from '../../../../../components/common/CustomDragDropTable';
import { toast } from "react-toastify";
import DeleteModal from '../../../../../components/common/CustomModals/deleteModal';


const initialData = {
  Production_LineID: '',
  Production_Line: '',
  Production_Description: '',
  Production_Steps: 0,
  Open_Jobs: 0,
};

const validationSchema = Yup.object({
  Production_Line: Yup.string().required('Production Line is required'),
  Production_Description: Yup.string().required('Description is required'),
});

const MainForm = ({
  isNew,
  isDeleteMode,
  showDeleteModal,
  setShowDeleteModal,
  showContinueModal,
  setShowContinueModal,
  showCancelModal,
  setShowCancelModal,
  handleCancel,
  handleDeleteProductionLine,
  formikSubmitRef,
  formikResetRef,
  submitForm,
  resetForm,
  setIsFormDirty,
  isValid,
  isSubmitting,
  dirty,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Save Formik functions in refs for later use
  formikSubmitRef.current = submitForm;
  formikResetRef.current = resetForm;

  useEffect(() => {
    setIsFormDirty(dirty);
  }, [dirty, setIsFormDirty]);

  return (
    <Form
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: "350px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField
            name="Production_Line"
            label="Production Line"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="Production_Description"
            label="Production_Description"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="Production_Steps"
            label="Step Quantity"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="Open_Jobs"
            label="Open Jobs"
            disabled={true}
          />
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1 }} />

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <CustomButton
            boldText
            sx={{ mr: 2 }}
            onClick={() => handleCancel(dirty)}
          >
            {dirty ? "Cancel" : "Go Back"}
          </CustomButton>

          {isDeleteMode ? (
            <CustomButton
              boldText
              onClick={() => setShowDeleteModal(true)}
              sx={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
              }}
            >
              Delete
            </CustomButton>
          ) : (
            <CustomButton
              boldText
              onClick={() => setShowContinueModal(true)}
              disabled={isSubmitting || !dirty || (isNew && !isValid)}
            >
              {isNew ? "Create" : "Update"}
            </CustomButton>
          )}
        </Box>

        <CancelModal
          open={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onConfirm={() => {
            setShowCancelModal(false);
            navigate(-1);
          }}
        />

        <ContinueModal
          open={showContinueModal}
          onClose={() => setShowContinueModal(false)}
          onConfirm={() => {
            setShowContinueModal(false);
            submitForm();
          }}
        />

        {isDeleteMode && showDeleteModal && (
          <DeleteModal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={handleDeleteProductionLine}
          />
        )}
      </Grid>
    </Form>
  );
};

const ProductionLineEdit = () => {
  // ID obtained from URL. If ID is found we POST, if not we PUT
  const { productionLineID } = useParams();

  // Extract state data, which only sometimes occur, for example from the equipment edit page
  const navigate = useNavigate()
  const location = useLocation();

  const { isDeleteMode } = location.state || {};

  const isNew = !productionLineID;

  const pageTitle = isNew
    ? "Create New Production Line"
    : isDeleteMode
      ? "Delete Production Line"
      : "Edit Production Line";

  // Form data for either POSTing or PUTing
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(initialData)

  // For rendering or hiding the modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // ─── NEW STATE & REFS FOR UNSAVED CHANGES ─────────────────────────────
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const formikSubmitRef = useRef(null);
  const formikResetRef = useRef(null);
  // ────────────────────────────────────────────────────────────────────────


  // Fetch data and populate formData with response data
  useEffect(() => {
    if (isNew) {
      return
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await productionLinesService.getById(productionLineID);
        const data = response.data
        setFormData({
          Production_LineID: data.Production_LineID,
          Production_Line: data.Production_Line,
          Production_Description: data.Production_Description,
          Production_Steps: data.Production_Steps.length,
          Open_Jobs: data.Production_Steps.length,
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
        toast.error("Failed to fetch data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [productionLineID, isNew]);

  const handlePost = async (values) => {
    try {
      const body = {
        "Production_Line": values.Production_Line,
        "Production_Description": values.Production_Description
      };
      const response = await productionLinesService.post(body);
      const newId = response.data.Production_LineID;

      navigate(`/production/product-assembly/production-line/${newId}`, { replace: true });
      toast.success("Production Line created successfully!");
    } catch (error) {
      console.error('Failed to create:', error);
      toast.error("Failed to create production line.");

    }
  };

  const handlePut = async (values) => {
    try {
      const body = {
        "Production_LineID": values.Production_LineID,
        "Production_Line": values.Production_Line,
        "Production_Description": values.Production_Description,
      };
      const { data: updatedData } = await productionLinesService.putById(productionLineID, body);
      console.log(updatedData);
      setFormData({
        Production_LineID: updatedData.Production_LineID,
        Production_Line: updatedData.Production_Line,
        Production_Description: updatedData.Production_Description,
      });
      toast.success("Production Line updated successfully!");
    } catch (error) {
      console.error('Failed to update:', error);
      toast.error("Failed to update production line.");

    }
  }
  const handleSubmit = async (values) => {
    try {
      if (isNew) {
        await handlePost(values);
      } else {
        await handlePut(values);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleCancel = (dirty) => {
    if (dirty) {
      setShowCancelModal(true)
    } else {
      navigate(-1)
    }
  }

  const handleDeleteProductionLine = async () => {
    try {
      await productionLinesService.deleteById(productionLineID);
      setShowDeleteModal(false);
      navigate(-1);
      toast.success("Production Line deleted successfully!");
    } catch (error) {
      console.error("Failed to delete production line:", error);
      toast.error("Failed to delete production line.");
    }
  };

  // ─── Wrap navigation actions to check for unsaved changes ────────────────
  const handleNavigationAction = (action) => {
    if (isFormDirty) {
      setPendingNavigation(() => action);
      setShowUnsavedModal(true);
    } else {
      action();
    }
  };
  // ────────────────────────────────────────────────────────────────────────

  // ─── Updated Production Step Actions ──────────────────────────────────
  const handleAddProductionStep = () => {
    handleNavigationAction(() => {
      navigate(`/production/product-assembly/production-line/${productionLineID}/product-step/new`, {
        state: { Production_Line: formData.Production_Line },
      });
    });
  };

  const handleEditProductionStep = (row) => {
    handleNavigationAction(() => {
      navigate(`/production/product-assembly/production-line/${productionLineID}/product-step/${row.id}/`);
    });
  };

  const handleDeleteProductionStep = (row) => {
    handleNavigationAction(() => {
      navigate(
        `/production/product-assembly/production-line/${productionLineID}/product-step/${row.id}/`,
        {
          state: {
            isDeleteMode: true,
          },
        }
      );
    });
  };

  const handleProductionStepOrderChange = async (items) => {
    console.log("🚀🚀🚀 ReOrder List:", items);
    const bodyData = items.map((item, index) => {
      return {
        Production_StepID: item.id,
        Production_Step: index,
      };
    });

    const body = {
      data: bodyData,
    };

    try {
      await productionStepsService.updateOrder(body);
      changePage(0);
      toast.success("Production Steps reordered successfully!");
    } catch (error) {
      console.error("Failed to reorder production steps:", error);
      toast.error("Failed to reorder production steps.");
      throw error;
    }
  }
  // ────────────────────────────────────────────────────────────────────────

  const {
    data: data,
    loading,
    error,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
    testSearch
  } = usePaginatedData(
    (params) => productionStepsService.getByProductionLineId(productionLineID, { all: true })
  );

  const getData = (data) => {
    return data.map((row) => {
      return {
        id: row.Production_StepID,
        Production_Line: row['Production_Line-Production_Line'],
        Step_Process: row.Step_Process,
        Equipment_BarCode: row['Equipment-Equipment_BarCode'],
        Operation_Status: row['Equipment-Operational_Status'],
        Production_Step: row.Production_Step + 1,
      };
    });
  }

  // ─── Functions for Unsaved Changes Modal Actions ───────────────────────
  const handleSaveAndNavigate = async () => {
    if (formikSubmitRef.current) {
      // Calling submitForm returns a promise
      await formikSubmitRef.current();
      if (pendingNavigation) {
        pendingNavigation();
        setPendingNavigation(null);
      }
      setShowUnsavedModal(false);
    }
  };

  const handleDiscardAndNavigate = () => {
    if (formikResetRef.current) {
      // Reset the form to the saved formData
      formikResetRef.current(formData);
    }
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
    setShowUnsavedModal(false);
  };

  const cancelNavigation = () => {
    setShowUnsavedModal(false);
    setPendingNavigation(null);
  };
  // ────────────────────────────────────────────────────────────────────────


  const dataConfig = [
    {
      key: 'id',
      header: 'Step ID',
      visible: false,
    },
    {
      key: 'Production_Step',
      header: 'Step Order',
      visible: true,
    },
    {
      key: 'Production_Line',
      header: 'Step',
      visible: true,
    },
    {
      key: 'Step_Process',
      header: 'Step Process',
      visible: true,
    },
    {
      key: 'Equipment_BarCode',
      header: 'Equipment Barcode',
      visible: true,
    },
    {
      key: 'Operation_Status',
      header: 'Operational Status',
      visible: true,
    },
  ];

  return (
    <CustomWideLayout>
      {/* ─── Unsaved Changes Modal ───────────────────────────── */}
      <Dialog open={showUnsavedModal} onClose={cancelNavigation}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent sx={{ padding: "0 24px" }}>
          <Typography>
            You have unsaved changes. Would you like to save them before
            proceeding?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px 24px",
          }}
        >
          <CustomButton onClick={handleSaveAndNavigate}>
            Save Changes
          </CustomButton>
          <CustomButton onClick={handleDiscardAndNavigate}>
            Discard Changes
          </CustomButton>
          <CustomButton onClick={cancelNavigation}>Cancel</CustomButton>
        </DialogActions>
      </Dialog>
      {/* ───────────────────────────────────────────────────────── */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
          <ContentCard
            whiteBackground={true}
            title={pageTitle}
          >
            <Formik
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting, isValid, dirty, submitForm, resetForm }) => (
                <MainForm
                  isNew={isNew}
                  isDeleteMode={isDeleteMode}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  showContinueModal={showContinueModal}
                  setShowContinueModal={setShowContinueModal}
                  showCancelModal={showCancelModal}
                  setShowCancelModal={setShowCancelModal}
                  handleCancel={handleCancel}
                  handleDeleteProductionLine={handleDeleteProductionLine}
                  formikSubmitRef={formikSubmitRef}
                  formikResetRef={formikResetRef}
                  submitForm={submitForm}
                  resetForm={resetForm}
                  setIsFormDirty={setIsFormDirty}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  dirty={dirty}
                />
              )}
            </Formik>
          </ContentCard>
        </Grid>
      </Grid>

      {!isNew && !isDeleteMode && (
        <CustomDragDropTable
          titleText={"Production Steps"}
          isNew={isNew}
          data={getData(data)}
          dataConfig={dataConfig}
          onEdit={handleEditProductionStep}
          onDelete={handleDeleteProductionStep}
          onOrderChange={handleProductionStepOrderChange}
          orderKey={"Production_Step"}
          onAddText="Add Steps"
          onAddClick={handleAddProductionStep}
          showPagination={false}

          // totalItems={totalItems}
          // currentPage={currentPage}
          // totalPages={totalPages}
          // onPageChange={changePage}

          searchText={"Search Production Steps"}
          showSearch={false}
          onSearch={handleSearch}
        />
      )}

    </CustomWideLayout>
  );
};

export default ProductionLineEdit;