import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";

import ContentCard from "../../../../../components/common/ContentCard/ContentCard";
import CustomButton from "../../../../../components/common/CustomButton";
import CustomWideLayout from "../../../../../components/common/Layout/CustomWideLayout";
import CustomTextField from "../../../../../components/common/CustomFormFields/CustomTextField";
import CustomSelectField from "../../../../../components/common/CustomFormFields/CustomSelectField";
import CustomButtonField from "../../../../../components/common/CustomFormFields/CustomButtonField";
import CustomTable from "../../../../../components/common/CustomTable";
import {
  CancelModal,
  ContinueModal,
} from "../../../../../components/common/CustomModals";
import {
  cleaningJobsService,
  equipmentService,
  maintenanceJobsService,
} from "../../../../../services";
import { usePaginatedData } from "../../../../../hooks";
import { equipmentTypesService } from "../../../../../services/equipmentTypes";
import CustomApiDropdown2 from "../../../../../components/common/CustomFormFields/CustomApiDropdown2";
import DeleteModal from "../../../../../components/common/CustomModals/deleteModal";
import { generateBarcode } from "../../../../../utils/randomStringGenerator";
import { breakLabelText } from "../../../../../utils/breakLabelText";
import BarcodePreviewModal from "../../../../../components/modals/BarcodePreviewModal";

const initialValues = {
  name: "",
  description: "",
  equipment_barcode: generateBarcode(8),
  manufacturer: "",
  model_number: "",
  serial_number: "",
  purchase_date: "",
  warranty_date: "",
  next_cleaning_date: "",
  next_maint_date: "",
  last_maint_date: "",
  equipment_type: "",
  operational_status: "",
  cycle_count: 0,
  cycle_multiplier: 0,
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().optional(),
  manufacturer: Yup.string().optional(),
  model_number: Yup.string().required("Model Number is required"),
  serial_number: Yup.string().required("Serial Number is required"),
  purchase_date: Yup.string().optional(),
  warranty_date: Yup.string().optional(),
  last_maint_date: Yup.string().required("Last Maintenance Date is required"),
  equipment_type: Yup.string().required("Equipment Type is required"),
  operational_status: Yup.string().required("Operational Status is required"),
  cycle_count: Yup.number().optional(),
  cycle_multiplier: Yup.number().optional(),
});

const MainForm = ({
  isDeleteMode,
  isNew,
  formikSubmitRef,
  selectedTable,
  setSelectedTable,
  formData,
  data,
  dataMaintenance,
  showCancelModal,
  setShowCancelModal,
  showContinueModal,
  setShowContinueModal,
  showDeleteModal,
  setShowDeleteModal,
  formikResetRef,
  setOpenBarcodeModal,
  handleDeleteEquipment,
  handleCancel,
  setIsFormDirty,
  isSubmitting,
  isValid,
  dirty,
  submitForm,
  resetForm,
}) => {
  const theme = useTheme();
  const { palette } = useTheme();
  const navigate = useNavigate();

  // Save Formik functions in refs for later use
  formikSubmitRef.current = submitForm;
  formikResetRef.current = resetForm;

  useEffect(() => {
    setIsFormDirty(dirty);
  }, [dirty, setIsFormDirty]);

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField name="name" label="Name" disabled={isDeleteMode} />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="description"
            label="Description"
            disabled={isDeleteMode}
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="equipment_barcode"
            label="Equipment Barcode"
            disabled={true}
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="manufacturer"
            label="Manufacturer"
            disabled={isDeleteMode}
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="model_number"
            label="Model Number"
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="serial_number"
            label="Serial Number"
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="purchase_date"
            label="Purchase Date"
            type="date"
            disabled={isDeleteMode}
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="warranty_date"
            label="Warranty Exp Date"
            type="date"
            disabled={isDeleteMode}
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButtonField
            name="next_cleaning-date"
            label="Next Cleaning Date"
            onClick={() => setSelectedTable(1)}
            buttonText={
              !isDeleteMode && selectedTable === 1
                ? "Selected"
                : `${data ? data.length : 0} Cleaning`
            }
            buttonColor={
              !isDeleteMode && selectedTable === 1
                ? palette.septenary.secondary
                : palette.primary.main
            }
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButtonField
            name="next_maint_date"
            label="Next Maint Date"
            onClick={() => setSelectedTable(2)}
            buttonText={
              !isDeleteMode && selectedTable === 2
                ? "Selected"
                : `${dataMaintenance ? dataMaintenance.length : 0} Maintenance`
            }
            buttonColor={
              !isDeleteMode && selectedTable === 2
                ? palette.septenary.secondary
                : palette.primary.main
            }
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="last_maint_date"
            label="Last Maint Date"
            type="date"
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="cycle_count"
            label="Cycle Count"
            disabled
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="cycle_multiplier"
            label="Cycle Multiplier"
            disabled={isDeleteMode}
            required={false}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomApiDropdown2
            name={"equipment_type"}
            label={"Equipment Type"}
            fetchOptions={equipmentTypesService.getAll}
            valueKey={"Equipment_TypeID"}
            labelKey={"Equipment_Description"}
            showIdInLabel={false}
            onCreateNewItem={async (inputValue) => {
              return await equipmentTypesService.createNew({
                Equipment_Description: inputValue,
              });
            }}
            formData={formData}
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSelectField
            name="operational_status"
            label="Operational Status"
            options={[
              { value: "operational", label: "Operational" },
              { value: "3months", label: "Good" },
              { value: "6months", label: "Bad" },
              { value: "1year", label: "Critical" },
            ]}
            disabled={isDeleteMode}
          />
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: isDeleteMode ? "flex-end" : "space-between",
              mt: 2,
              gap: 1,
            }}
          >
            {!isDeleteMode && (
              <CustomButton boldText onClick={() => setOpenBarcodeModal(true)}>
                Print Barcode
              </CustomButton>
            )}
            <Box
              sx={{
                display: "flex",
                gap: 1,
                "@media (max-width: 576px)": {
                  flexDirection: "column",
                },
              }}
            >
              <CustomButton boldText onClick={() => handleCancel(dirty)}>
                {dirty ? "Cancel" : "Go Back"}
              </CustomButton>
              {isDeleteMode ? (
                <CustomButton
                  boldText
                  onClick={() => setShowDeleteModal(true)}
                  sx={{
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                  }}
                >
                  Delete
                </CustomButton>
              ) : (
                <CustomButton
                  boldText
                  onClick={() => setShowContinueModal(true)}
                  disabled={isSubmitting || !dirty || (isNew && !isValid)}
                >
                  {isNew ? "Create" : "Update"}
                </CustomButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <CancelModal
        open={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={() => {
          setShowCancelModal(false);
          navigate(0);
        }}
      />

      <ContinueModal
        open={showContinueModal}
        onClose={() => setShowContinueModal(false)}
        onConfirm={() => {
          setShowContinueModal(false);
          submitForm();
        }}
      />

      {isDeleteMode && showDeleteModal && (
        <DeleteModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteEquipment}
        />
      )}
    </Form>
  );
};

const EquipmentManagementEdit = () => {
  const { equipmentID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isDeleteMode } = location.state || {};

  const isNew = !equipmentID;

  const pageTitle = isNew
    ? "Create New Equipment"
    : isDeleteMode
      ? "Delete Equipment"
      : "Edit Equipment";

  const [formData, setFormData] = useState(initialValues);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState(1);
  const [openBarcodeModal, setOpenBarcodeModal] = useState(false);

  // ─── NEW STATE & REFS FOR UNSAVED CHANGES ─────────────────────────────
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const formikSubmitRef = useRef(null);
  const formikResetRef = useRef(null);
  // ────────────────────────────────────────────────────────────────────────


  useEffect(() => {
    if (isNew) return;

    const fetchData = async () => {
      try {
        const response = await equipmentService.getById(equipmentID);
        const data = response.data;
        setFormData({
          id: data.EquipmentID,
          name: data.Name,
          description: data.Description,
          equipment_barcode: data.Equipment_BarCode,
          manufacturer: data.Manufacturer,
          model_number: data.Model_Number,
          serial_number: data.Serial_Number,
          purchase_date: data.Purchase_Date
            ? data.Purchase_Date.split("T")[0]
            : "",
          warranty_date: data.Warranty_Date
            ? data.Warranty_Date.split("T")[0]
            : "",
          next_cleaning_date: "awaiting API implementation",
          next_maint_date: "awaiting API implementation",
          last_maint_date: data.Last_Maint_Date.split("T")[0],
          equipment_type: data.Equipment_TypeID,
          operational_status: data.Operational_Status.toString().toLowerCase(),
          cycle_count: data.Cycle_Count,
          cycle_multiplier: data.Cycle_Multiplier,
        });
      } catch (error) {
        console.error("Failed to fetch data:", error);
        toast.error("Failed to fetch data");
      }
    };

    fetchData();
  }, [equipmentID, isNew]);

  const handlePost = async (values) => {
    try {
      console.log(values);
      const body = {
        Name: values.name,
        Description: values.description,
        Equipment_BarCode: values.equipment_barcode,
        Manufacturer: values.manufacturer,
        Model_Number: values.model_number,
        Serial_Number: values.serial_number,
        Purchase_Date:
          values.purchase_date === "" ? null : values.purchase_date,
        Warranty_Date:
          values.warranty_date === "" ? null : values.warranty_date,
        Last_Maint_Date: "2023-06-01",
        Operational_Status: values.operational_status,
        Cycle_Count: values.cycle_count,
        Cycle_Multiplier: values.cycle_multiplier,
        Equipment_TypeID: values.equipment_type,
      };
      const response = await equipmentService.post(body);
      const newId = response.data.EquipmentID;
      navigate(`/production/equipment/equipment-management/${newId}`, { replace: true });
      toast.success("Equipment created successfully!");
    } catch (error) {
      console.error("Failed to create:", error);
      toast.error("Failed to create equipment.");
    }
  };

  const handlePut = async (values) => {
    try {
      const body = {
        Name: values.name,
        Description: values.description,
        Equipment_BarCode: values.equipment_barcode,
        Manufacturer: values.manufacturer,
        Model_Number: values.model_number,
        Serial_Number: values.serial_number,
        Purchase_Date:
          values.purchase_date === "" ? null : values.purchase_date,
        Warranty_Date:
          values.warranty_date === "" ? null : values.warranty_date,
        Last_Maint_Date: values.last_maint_date,
        Operational_Status: values.operational_status,
        Cycle_Count: values.cycle_count,
        Cycle_Multiplier: values.cycle_multiplier,
        Equipment_TypeID: values.equipment_type,
      };
      const { data: updatedData } = await equipmentService.put(
        equipmentID,
        body
      );

      setFormData({
        name: updatedData.Name,
        description: updatedData.Description,
        equipment_barcode: updatedData.Equipment_BarCode,
        manufacturer: updatedData.Manufacturer,
        model_number: updatedData.Model_Number,
        serial_number: updatedData.Serial_Number,
        purchase_date: updatedData.Purchase_Date
          ? updatedData.Purchase_Date.split("T")[0]
          : "",
        warranty_date: updatedData.Warranty_Date
          ? updatedData.Warranty_Date.split("T")[0]
          : "",
        next_cleaning_date: "awaiting API implementation",
        next_maint_date: "awaiting API implementation",
        last_maint_date: updatedData.Last_Maint_Date.split("T")[0],
        equipment_type: updatedData.Equipment_TypeID,
        operational_status:
          updatedData.Operational_Status.toString().toLowerCase(),
        cycle_count: updatedData.Cycle_Count,
        cycle_multiplier: updatedData.Cycle_Multiplier,
      });
      toast.success("Equipment updated successfully!");
    } catch (error) {
      console.error("Failed to update:", error);
      toast.error("Failed to update equipment.");
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (isNew) {
        await handlePost(values);
      } else {
        await handlePut(values);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form.");
    }
  };

  const handleCancel = (dirty) => {
    if (dirty) {
      setShowCancelModal(true);
    } else {
      navigate(-1);
    }
  };

  const handleDeleteEquipment = async () => {
    try {
      await equipmentService.delete(equipmentID);
      setShowDeleteModal(false);
      navigate(-1);
      toast.success("Equipment deleted successfully!");
    } catch (error) {
      console.error("Failed to delete equipment:", error);
      toast.error("Failed to delete equipment.");
    }
  };

  // ─── Wrap navigation actions to check for unsaved changes ────────────────
  const handleNavigationAction = (action) => {
    if (isFormDirty) {
      setPendingNavigation(() => action);
      setShowUnsavedModal(true);
    } else {
      action();
    }
  };
  // ────────────────────────────────────────────────────────────────────────

  // ─── Updated Cleaning Schedule Actions ──────────────────────────────────
  const handleAddCleaning = () => {
    handleNavigationAction(() => {
      navigate(`/production/equipment/cleaning-schedules/new`, {
        state: { equipmentID },
      });
    });
  };

  const handleEditCleaning = (row) => {
    handleNavigationAction(() => {
      navigate(
        `/production/equipment/cleaning-schedules/${row.Cleaning_JobID}`,
        {
          state: { equipmentID },
        }
      );
    });
  };

  const handleDeleteCleaning = (row) => {
    handleNavigationAction(() => {
      navigate(
        `/production/equipment/cleaning-schedules/${row.Cleaning_JobID}`,
        {
          state: { isDeleteMode: true },
        }
      );
    });
  };

  const {
    data,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  } = usePaginatedData((params) =>
    cleaningJobsService.getByEquipmentId(equipmentID, params)
  );

  const dataConfig = [
    {
      key: "Cleaning_Name",
      header: breakLabelText("Cleaning Name"),
      visible: true,
    },
    {
      key: "Cleaning_Description",
      header: breakLabelText("Cleaning Description"),
      visible: true,
    },
    {
      key: "Equipment_Name",
      header: breakLabelText("Equipment Name"),
      visible: true,
    },
    {
      key: "Next_procedure_date",
      header: breakLabelText("Next Cleaning Date"),
      visible: true,
    },
    {
      key: "Next_procedure_time",
      header: breakLabelText("Next Cleaning Time"),
      visible: true,
    },
    {
      key: "Next_procedure_employee-Full_Name",
      header: breakLabelText("Employee"),
      visible: true,
    },
  ];

  const {
    data: dataMaintenance,
    totalItems: totalItemsMaintenance,
    currentPage: currentPageMaintenance,
    totalPages: totalPagesMaintenance,
    changePage: changePageMaintenance,
    handleSearch: handleSearchMaintenance,
  } = usePaginatedData((params) =>
    maintenanceJobsService.getByEquipmentId(equipmentID, params)
  );

  // ─── Updated Maintenance Schedule Actions ─────────────────────────────
  const handleAddMaintenanceAction = () => {
    handleNavigationAction(() => {
      navigate(`/production/equipment/maintenance-schedules/new`, {
        state: { equipmentID },
      });
    });
  };

  const handleEditMaintenanceAction = (row) => {
    handleNavigationAction(() => {
      navigate(
        `/production/equipment/maintenance-schedules/${row.Maintenance_JobID}`,
        {
          state: { equipmentID },
        }
      );
    });
  };

  const handleDeleteMaintenanceAction = (row) => {
    handleNavigationAction(() => {
      navigate(
        `/production/equipment/maintenance-schedules/${row.Maintenance_JobID}`,
        { state: { isDeleteMode: true } }
      );
    });
  };

  // ─── Functions for Unsaved Changes Modal Actions ───────────────────────
  const handleSaveAndNavigate = async () => {
    if (formikSubmitRef.current) {
      // Calling submitForm returns a promise
      await formikSubmitRef.current();
      if (pendingNavigation) {
        pendingNavigation();
        setPendingNavigation(null);
      }
      setShowUnsavedModal(false);
    }
  };

  const handleDiscardAndNavigate = () => {
    if (formikResetRef.current) {
      // Reset the form to the saved formData
      formikResetRef.current(formData);
    }
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
    setShowUnsavedModal(false);
  };

  const cancelNavigation = () => {
    setShowUnsavedModal(false);
    setPendingNavigation(null);
  };
  // ────────────────────────────────────────────────────────────────────────

  const dataConfigMaintenance = [
    {
      key: "Maint_Name",
      header: breakLabelText("Maintenance Name"),
      visible: true,
    },
    {
      key: "Maint_Description",
      header: breakLabelText("Maintenance Description"),
      visible: true,
    },
    {
      key: "Equipment_Name",
      header: breakLabelText("Equipment Name"),
      visible: true,
    },
    {
      key: "Next_procedure_date",
      header: breakLabelText("Next Maint Date"),
      visible: true,
    },
    {
      key: "Next_procedure_time",
      header: breakLabelText("Next Maint Time"),
      visible: true,
    },
    {
      key: "Next_procedure_employee-Full_Name",
      header: breakLabelText("Employee"),
      visible: true,
    },
  ];

  return (
    <CustomWideLayout>
      <BarcodePreviewModal name={formData.name} barcode={formData.equipment_barcode} open={openBarcodeModal} setOpen={setOpenBarcodeModal} />

      {/* ─── Unsaved Changes Modal ───────────────────────────── */}
      <Dialog open={showUnsavedModal} onClose={cancelNavigation}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent sx={{ padding: "0 24px" }}>
          <Typography>
            You have unsaved changes. Would you like to save them before
            proceeding?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px 24px",
          }}
        >
          <CustomButton onClick={handleSaveAndNavigate}>
            Save Changes
          </CustomButton>
          <CustomButton onClick={handleDiscardAndNavigate}>
            Discard Changes
          </CustomButton>
          <CustomButton onClick={cancelNavigation}>Cancel</CustomButton>
        </DialogActions>
      </Dialog>
      {/* ───────────────────────────────────────────────────────── */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
          <ContentCard whiteBackground={true} title={pageTitle}>
            <Formik
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting, isValid, dirty, submitForm, resetForm }) => (
                <MainForm
                  isDeleteMode={isDeleteMode}
                  isNew={isNew}
                  data={data}
                  dataMaintenance={dataMaintenance}
                  formData={formData}
                  formikSubmitRef={formikSubmitRef}
                  formikResetRef={formikResetRef}
                  selectedTable={selectedTable}
                  setSelectedTable={setSelectedTable}
                  showCancelModal={showCancelModal}
                  setShowCancelModal={setShowCancelModal}
                  showContinueModal={showContinueModal}
                  setShowContinueModal={setShowContinueModal}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  setOpenBarcodeModal={setOpenBarcodeModal}
                  handleDeleteEquipment={handleDeleteEquipment}
                  handleCancel={handleCancel}
                  setIsFormDirty={setIsFormDirty}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  dirty={dirty}
                  submitForm={submitForm}
                  resetForm={resetForm}
                />
              )}
            </Formik>
          </ContentCard>
        </Grid>
      </Grid>

      {!isNew && !isDeleteMode && (
        <>
          {selectedTable === 1 ? (
            <CustomTable
              titleText={"Cleaning Schedules"}
              isNew={isNew}
              data={data}
              dataConfig={dataConfig}
              onEdit={handleEditCleaning}
              onDelete={handleDeleteCleaning}
              onAddText="Add Cleaning Schedule"
              onAddClick={handleAddCleaning}
              totalItems={totalItems}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={changePage}
              searchText={"Search Cleaning Schedules"}
              onSearch={handleSearch}
            />
          ) : (
            <CustomTable
              titleText={"Maintenance Schedules"}
              isNew={isNew}
              data={dataMaintenance}
              dataConfig={dataConfigMaintenance}
              onEdit={handleEditMaintenanceAction}
              onDelete={handleDeleteMaintenanceAction}
              onAddText="Add Maintenance Schedule"
              onAddClick={handleAddMaintenanceAction}
              totalItems={totalItemsMaintenance}
              currentPage={currentPageMaintenance}
              totalPages={totalPagesMaintenance}
              onPageChange={changePageMaintenance}
              searchText={"Search Maintenance Schedules"}
              onSearch={handleSearchMaintenance}
            />
          )}
        </>
      )}
    </CustomWideLayout>
  );
};

export default EquipmentManagementEdit;
