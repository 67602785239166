import { useNavigate } from "react-router-dom";

import CustomWideLayout from "../../../../components/common/Layout/CustomWideLayout";
import CustomTable from "../../../../components/common/CustomTable";
import { usePaginatedData, useTableSize } from "../../../../hooks";
import { cleaningJobsService } from "../../../../services";
import { breakLabelText } from "../../../../utils/breakLabelText";

const CleaningJobs = () => {
  const navigate = useNavigate();
  const pageSize = useTableSize(true);

  const {
    data,
    totalItems,
    currentPage,
    totalPages,
    changePage,
    handleSearch,
  } = usePaginatedData(cleaningJobsService.getAll, { size: pageSize });

  const handleEdit = (row) => {
    navigate(`/production/equipment/cleaning-schedules/${row.Cleaning_JobID}`);
  };

  const handleDelete = async (row) => {
    navigate(`/production/equipment/cleaning-schedules/${row.Cleaning_JobID}`, {
      state: {
        isDeleteMode: true,
      },
    });
  };

  const handleAdd = (row) => {
    navigate(`/production/equipment/cleaning-schedules/new`);
  };

  const dataConfig = [
    {
      key: "Cleaning_Name",
      header: breakLabelText("Cleaning Name"),
      visible: true,
    },
    {
      key: "Cleaning_Description",
      header: breakLabelText("Cleaning Description"),
      visible: true,
    },
    {
      key: "Equipment_Name",
      header: breakLabelText("Equipment Name"),
      visible: true,
    },
    {
      key: "Next_procedure_date",
      header: breakLabelText("Next Cleaning Date"),
      visible: true,
    },
    {
      key: "Next_procedure_time",
      header: breakLabelText("Next Cleaning Time"),
      visible: true,
    },
    {
      key: "Next_procedure_employee-Full_Name",
      header: breakLabelText("Employee"),
      visible: true,
    },
  ];

  return (
    <CustomWideLayout>
      <CustomTable
        titleText={"Manage Cleaning Schedules"}
        data={data}
        dataConfig={dataConfig}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onAddText="Add New Cleaning Schedule"
        onAddClick={handleAdd}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={changePage}
        searchText={"Search Cleaning Schedules"}
        onSearch={handleSearch}
        pageSize={pageSize}
        backButton={true}
        backButtonText="Go Back"
      />
    </CustomWideLayout>
  );
};

export default CleaningJobs;
