import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ContentCard from "../../../../../components/common/ContentCard/ContentCard";
import CustomButton from "../../../../../components/common/CustomButton";
import CustomWideLayout from "../../../../../components/common/Layout/CustomWideLayout";
import CustomTextField from "../../../../../components/common/CustomFormFields/CustomTextField";
import CustomTable from "../../../../../components/common/CustomTable";

import { useTableModal } from "../../../../../hooks";
import { productionRecipesService } from "../../../../../services";
import { RecipeManagementModal } from "../../../../../components/modals/TableModals";
import {
  CancelModal,
  ContinueModal,
} from "../../../../../components/common/CustomModals";
import { toast } from "react-toastify";
import DeleteModal from "../../../../../components/common/CustomModals/deleteModal";
import { qty1200lbsCalculator } from "../../../../../utils/qtyCalculator";

const initialValues = {
  recipeName: null,
  recipeDesc: null,
  items: 0,
  weight: 0,
};

const validationSchema = Yup.object({
  recipeName: Yup.string().required("Name is required"),
  recipeDesc: Yup.string().required("Description is required"),
});

const MainForm = ({
  isNew,
  isDeleteMode,
  handleCancel,
  showCancelModal,
  handleCancelModalClose,
  handleCancelModalConfirm,
  showContinueModal,
  setShowContinueModal,
  handleContinueModalConfirm,
  handleContinueModalClose,
  showDeleteModal,
  setShowDeleteModal,
  handleDeleteRecipe,
  formikSubmitRef,
  formikResetRef,
  submitForm,
  resetForm,
  setIsFormDirty,
  isValid,
  isSubmitting,
  dirty,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Save Formik functions in refs for later use
  formikSubmitRef.current = submitForm;
  formikResetRef.current = resetForm;

  useEffect(() => {
    setIsFormDirty(dirty);
  }, [dirty, setIsFormDirty]);

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField
            name="recipeName"
            label="Recipe Name"
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="recipeDesc"
            label="Description"
            disabled={isDeleteMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="items"
            label="Recipe Items"
            required={false}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            name="weight"
            label="Recipe Weight"
            required={false}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <CustomButton
              boldText
              sx={{ mr: 2 }}
              onClick={() => handleCancel(dirty)}
            >
              {dirty ? "Cancel" : "Go Back"}
            </CustomButton>

            {isDeleteMode ? (
              <CustomButton
                boldText
                onClick={() => setShowDeleteModal(true)}
                sx={{
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.error.contrastText,
                }}
              >
                Delete
              </CustomButton>
            ) : (
              <CustomButton
                boldText
                onClick={() => setShowContinueModal(true)}
                disabled={isSubmitting || !dirty || (isNew && !isValid)}
              >
                {isNew ? "Next" : "Update"}
              </CustomButton>
            )}
          </Box>

          <CancelModal
            open={showCancelModal}
            onClose={handleCancelModalClose}
            onConfirm={handleCancelModalConfirm}
          />

          <ContinueModal
            open={showContinueModal}
            onClose={handleContinueModalClose}
            onConfirm={() => handleContinueModalConfirm(submitForm)}
          />

          {isDeleteMode && showDeleteModal && (
            <DeleteModal
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onConfirm={handleDeleteRecipe}
            />
          )}
        </Grid>
      </Grid>
    </Form>
  );
};

const RecipeManagementEdit = () => {
  const { recipeSlug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { isDeleteMode } = location.state || {};
  console.log("Delete Mode", isDeleteMode);
  const isNew = !recipeSlug;

  const pageTitle = isNew
    ? "Create New Recipe"
    : isDeleteMode
      ? "Delete Recipe"
      : "Edit Recipe";

  const [formData, setFormData] = useState(initialValues);
  const [items, setItems] = useState([]);

  const { isOpen, isDelete, selectedId, openModal, closeModal } =
    useTableModal();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // ─── NEW STATE & REFS FOR UNSAVED CHANGES ─────────────────────────────
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const formikSubmitRef = useRef(null);
  const formikResetRef = useRef(null);
  // ────────────────────────────────────────────────────────────────────────

  const handlePost = async (values) => {
    try {
      setFormData({
        recipeName: values.recipeName,
        recipeDesc: values.recipeDesc,
      });

      openModal();
    } catch (error) {
      console.error("Failed to create:", error);
      toast.error("Failed to create recipe.");
    }
  };

  const handlePut = async (values) => {
    try {
      const body = {
        Recipe_Name: values.recipeName,
        Recipe_Description: values.recipeDesc,
      };
      const { data: updatedData } = await productionRecipesService.putBySlug(
        recipeSlug,
        body
      );

      setFormData({
        recipeName: updatedData.Recipe_Name,
        recipeDesc: updatedData.Recipe_Description,
      });
      toast.success("Recipe updated successfully!");
    } catch (error) {
      console.error("Failed to create:", error);
      toast.error("Failed to update recipe.");
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (isNew) {
        await handlePost(values);
      } else {
        await handlePut(values);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form.");
    }
  };

  const handleCancel = (dirty) => {
    if (dirty) {
      setShowCancelModal(true);
    } else {
      navigate(-1);
    }
  };

  const handleDeleteRecipe = async () => {
    try {
      await productionRecipesService.deleteBySlug(recipeSlug);
      setShowDeleteModal(false);
      navigate(-1);
      toast.success("Production recipe deleted successfully!");
    } catch (error) {
      console.error("Failed to delete production recipe:", error);
      toast.error("Failed to delete production recipe.");
    }
  };

  // ─── Wrap navigation actions to check for unsaved changes ────────────────
  const handleNavigationAction = (action) => {
    if (isFormDirty) {
      setPendingNavigation(() => action);
      setShowUnsavedModal(true);
    } else {
      action();
    }
  };
  // ────────────────────────────────────────────────────────────────────────

  // ─── Updated Recipe Inventory Actions ──────────────────────────────────
  const handleAddRecipeInventory = () => {
    handleNavigationAction(() => {
      openModal();
    });
  };

  const handleEditRecipeInventory = (row) => {
    handleNavigationAction(() => {
      const recipeId = row.Production_RecipeID;
      openModal(recipeId);
    });
  };

  const handleDeleteRecipeInventory = (row) => {
    handleNavigationAction(() => {
      const recipeId = row.Production_RecipeID;
      openModal(recipeId, true);
    });
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
  };

  const handleCancelModalConfirm = () => {
    setShowCancelModal(false);
    navigate(0);
  };

  const handleContinueModalClose = () => {
    setShowContinueModal(false);
  };

  const handleContinueModalConfirm = (submitForm) => {
    setShowContinueModal(false);
    submitForm();
  };

  // ─── Functions for Unsaved Changes Modal Actions ───────────────────────
  const handleSaveAndNavigate = async () => {
    if (formikSubmitRef.current) {
      // Calling submitForm returns a promise
      await formikSubmitRef.current();
      if (pendingNavigation) {
        pendingNavigation();
        setPendingNavigation(null);
      }
      setShowUnsavedModal(false);
    }
  };

  const handleDiscardAndNavigate = () => {
    if (formikResetRef.current) {
      // Reset the form to the saved formData
      formikResetRef.current(formData);
    }
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
    setShowUnsavedModal(false);
  };

  const cancelNavigation = () => {
    setShowUnsavedModal(false);
    setPendingNavigation(null);
  };
  // ────────────────────────────────────────────────────────────────────────

  const dataConfig = [
    {
      key: "Inventory-Inventory_Name",
      header: "Inventory Name",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Description",
      header: "Inventory Description",
      visible: true,
    },
    {
      key: "Unit_Measure",
      header: "Unit of Measure",
      visible: true,
    },
    {
      key: "Recipe_Qty",
      header: "Recipe Quantity",
      visible: true,
    },
    {
      key: "Qty_1200lbs",
      header: "Quantity 1200 lbs",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Type-Inventory_Type",
      header: "Inventory Type",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Type-Storage_Method",
      header: "Storage Method",
      visible: true,
    },
    {
      key: "Inventory-Inventory_Type-Cooler_Schedule",
      header: "Cooler Schedule",
      visible: true,
    },
  ];

  useEffect(() => {
    if (isNew) {
      setFormData({ ...initialValues });
      return;
    }

    const fetchData = async () => {
      try {
        const response = await productionRecipesService.getBySlug(recipeSlug);
        const data = response.data;
        if (data.length > 0) {
          setItems(
            data.map((item) => ({
              ...item,
              "Inventory-Inventory_Type-Cooler_Schedule": `${item["Inventory-Inventory_Type-Cooler_Schedule"] || 0
                } Day(s)`,
            }))
          );
          setFormData({
            recipeName: data[0].Recipe_Name,
            recipeDesc: data[0].Recipe_Description,
            items: data.length,
            weight: data.reduce(
              (sum, item) =>
                item.Unit_Measure === "lbs" ? sum + item.Recipe_Qty : sum,
              0
            ),
          });
        } else {
          navigate(-1);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        toast.error("Failed to fetch data");
      }
    };

    fetchData();
  }, [isNew, navigate, recipeSlug]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      items: items.length,
      weight: items.reduce(
        (acc, { Unit_Measure, Recipe_Qty = 0 }) =>
          acc + (Unit_Measure === "lbs" ? Recipe_Qty : 0),
        0
      ),
    }));
  }, [items]);

  return (
    <CustomWideLayout>
      {/* ─── Unsaved Changes Modal ───────────────────────────── */}
      <Dialog open={showUnsavedModal} onClose={cancelNavigation}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent sx={{ padding: "0 24px" }}>
          <Typography>
            You have unsaved changes. Would you like to save them before
            proceeding?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px 24px",
          }}
        >
          <CustomButton onClick={handleSaveAndNavigate}>
            Save Changes
          </CustomButton>
          <CustomButton onClick={handleDiscardAndNavigate}>
            Discard Changes
          </CustomButton>
          <CustomButton onClick={cancelNavigation}>Cancel</CustomButton>
        </DialogActions>
      </Dialog>
      {/* ───────────────────────────────────────────────────────── */}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
          <ContentCard whiteBackground={true} title={pageTitle}>
            <Formik
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting, isValid, dirty, submitForm, resetForm }) => (
                <MainForm
                  isNew={isNew}
                  isDeleteMode={isDeleteMode}
                  handleCancel={handleCancel}
                  showCancelModal={showCancelModal}
                  handleCancelModalClose={handleCancelModalClose}
                  handleCancelModalConfirm={handleCancelModalConfirm}
                  showContinueModal={showContinueModal}
                  setShowContinueModal={setShowContinueModal}
                  handleContinueModalConfirm={handleContinueModalConfirm}
                  handleContinueModalClose={handleContinueModalClose}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  handleDeleteRecipe={handleDeleteRecipe}
                  formikSubmitRef={formikSubmitRef}
                  formikResetRef={formikResetRef}
                  submitForm={submitForm}
                  resetForm={resetForm}
                  setIsFormDirty={setIsFormDirty}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  dirty={dirty}
                />
              )}
            </Formik>
          </ContentCard>
        </Grid>
      </Grid>

      {formData && (
        <CustomTable
          titleText={formData.recipeName}
          isNew={isNew}
          data={items}
          dataConfig={dataConfig}
          onEdit={handleEditRecipeInventory}
          onDelete={handleDeleteRecipeInventory}
          onAddText="Add an Item"
          onAddClick={handleAddRecipeInventory}
          totalItems={items.length}
          currentPage={1}
          totalPages={1}
          searchText={"Search Items"}
          showSearch={false}
        />
      )}
      {isOpen && (
        <RecipeManagementModal
          open={isOpen}
          onClose={closeModal}
          isDelete={isDelete}
          recipeId={selectedId}
          basic={formData}
          items={items}
          setItems={setItems}
        />
      )}
    </CustomWideLayout>
  );
};

export default RecipeManagementEdit;
